import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as endpoints from "../networkUtility/endpoint";
import { toggleLoader } from "./loaderSlice";
import { toast } from "react-toastify";
import * as localStorageActionType from "../localStorage/ActionTypes";
import { getLocalStorage } from "../localStorage/GetLocalStorage";
import { handle500Status } from "../utility/Utility";

interface initialStateProp {
  allBuses: any;
  bus: any;
  isSuccess: boolean;
}

const initialState: initialStateProp = {
  allBuses: [],
  bus: {},
  isSuccess: false,
};

interface allBusesProp {
  allBuses: any;
}
interface busDetailProp {
  bus: any;
}

interface busStateProp {
  isSuccess: boolean;
}

interface addBusPayload {
  bus_number: string;
  bus_name: string;
  bus_make: string;
  bus_model: string;
  fitness_start_date: string;
  fitness_end_date: string;
  mother_permit_start_date: string;
  mother_permit_expiry_date: string;
  mother_permit_id_number: string;
  mother_permit_authorization_start_date: string;
  mother_permit_authorization_end_date: string;
  mother_permit_authorization_id_number: string;
  mother_road_tax_start_date: string;
  mother_road_tax_expiry_date: string;
  mother_road_tax_id_number: string;
  mother_road_tax_authorization_start_date: string;
  mother_road_tax_authorization_end_date: string;
  mother_road_tax_authorization_id_number: string;
  all_india_tourist_permit_start_date: string;
  all_india_tourist_permit_expiry_date: string;
  all_india_tourist_permit_id_number: string;
  all_india_tourist_permit_authorization_start_date: string;
  all_india_tourist_permit_authorization_end_date: string;
  all_india_tourist_permit_authorization_id_number: string;
  insurance_provider: string;
  insurance_policy_number: string;
  insurance_start_date: string;
  insurance_end_date: string;
  insurance_coverage_type: string;
  emission_policy_number: string;
  emission_start_date: string;
  emission_end_date: string;
}

interface updateBusPayload {
  id: any;
  bus_number: string;
  bus_name: string;
  bus_make: string;
  bus_model: string;
  fitness_start_date: string;
  fitness_end_date: string;
  mother_permit_start_date: string;
  mother_permit_expiry_date: string;
  mother_permit_id_number: string;
  mother_permit_authorization_start_date: string;
  mother_permit_authorization_end_date: string;
  mother_permit_authorization_id_number: string;
  mother_road_tax_start_date: string;
  mother_road_tax_expiry_date: string;
  mother_road_tax_id_number: string;
  mother_road_tax_authorization_start_date: string;
  mother_road_tax_authorization_end_date: string;
  mother_road_tax_authorization_id_number: string;
  all_india_tourist_permit_start_date: string;
  all_india_tourist_permit_expiry_date: string;
  all_india_tourist_permit_id_number: string;
  all_india_tourist_permit_authorization_start_date: string;
  all_india_tourist_permit_authorization_end_date: string;
  all_india_tourist_permit_authorization_id_number: string;
  insurance_provider: string;
  insurance_policy_number: string;
  insurance_start_date: string;
  insurance_end_date: string;
  insurance_coverage_type: string;
  emission_policy_number: string;
  emission_start_date: string;
  emission_end_date: string;
}

export const getAllBuses = createAsyncThunk(
  "get all users",
  async (payload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(endpoints.allBuses, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });

      dispatch(toggleLoader({ isLoading: false }));

      if (response.status === 200) {
        // toast.success("All Buses Fetch successfully!.");
        const data = await response.json();
        dispatch(setAllBuses({ allBuses: data }));
        console.log(data);
      } else if (response.status === 500) {
        handle500Status();
      } else {
        // Handle non-200 status codes here
        toast.error("Sorry, Something went wrong!.");

        console.error(`Error: ${response.status}`);
      }
    } catch (error) {
      // Handle fetch or JSON parsing errors here
      console.error(error);
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const addNewBus = createAsyncThunk(
  "add new bus",
  async (payload: addBusPayload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const formData = new FormData();
      // formData.append("busId", payload.busId);
      formData.append("bus_name", payload.bus_name);
      formData.append("bus_number", payload.bus_number);
      formData.append("bus_make", payload.bus_make);
      formData.append("bus_model", payload.bus_model);

      formData.append("fitness_start_date", payload.fitness_start_date);
      formData.append("fitness_end_date", payload.fitness_end_date);
      formData.append(
        "mother_permit_start_date",
        payload.mother_permit_start_date
      );
      formData.append(
        "mother_permit_expiry_date",
        payload.mother_permit_expiry_date
      );
      formData.append(
        "mother_permit_id_number",
        payload.mother_permit_id_number
      );
      formData.append(
        "mother_permit_authorization_start_date",
        payload.mother_permit_authorization_start_date
      );
      formData.append(
        "mother_permit_authorization_end_date",
        payload.mother_permit_authorization_end_date
      );
      formData.append(
        "mother_permit_authorization_id_number",
        payload.mother_permit_authorization_id_number
      );
      formData.append(
        "mother_road_tax_start_date",
        payload.mother_road_tax_start_date
      );
      formData.append(
        "mother_road_tax_expiry_date",
        payload.mother_road_tax_expiry_date
      );
      formData.append(
        "mother_road_tax_id_number",
        payload.mother_road_tax_id_number
      );
      formData.append(
        "mother_road_tax_authorization_start_date",
        payload.mother_road_tax_authorization_start_date
      );
      formData.append(
        "mother_road_tax_authorization_end_date",
        payload.mother_road_tax_authorization_end_date
      );
      formData.append(
        "mother_road_tax_authorization_id_number",
        payload.mother_road_tax_authorization_id_number
      );
      formData.append(
        "all_india_tourist_permit_start_date",
        payload.all_india_tourist_permit_start_date
      );
      formData.append(
        "all_india_tourist_permit_expiry_date",
        payload.all_india_tourist_permit_expiry_date
      );
      formData.append(
        "all_india_tourist_permit_id_number",
        payload.all_india_tourist_permit_id_number
      );
      formData.append(
        "all_india_tourist_permit_authorization_start_date",
        payload.all_india_tourist_permit_authorization_start_date
      );
      formData.append(
        "all_india_tourist_permit_authorization_end_date",
        payload.all_india_tourist_permit_authorization_end_date
      );
      formData.append(
        "all_india_tourist_permit_authorization_id_number",
        payload.all_india_tourist_permit_authorization_id_number
      );
      formData.append("insurance_provider", payload.insurance_provider);
      formData.append(
        "insurance_policy_number",
        payload.insurance_policy_number
      );
      formData.append("insurance_start_date", payload.insurance_start_date);
      formData.append("insurance_end_date", payload.insurance_end_date);
      formData.append(
        "insurance_coverage_type",
        payload.insurance_coverage_type
      );
      formData.append(
        "emission_policy_number",
        payload.insurance_policy_number
      );
      formData.append("emission_start_date", payload.insurance_start_date);
      formData.append("emission_end_date", payload.insurance_end_date);

      const response = await fetch(endpoints.addBus, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
        body: formData,
      });
      if (response.status === 200) {
        const data = await response.json();
        toast.success("Bus Added Successfully!.");
        dispatch(
          setBusState({
            isSuccess: true,
          })
        );
        console.log(data);

        dispatch(getAllBuses());
      } else {
        toast.error("Sorry!,Something went wrong!.");
      }
    } catch (error) {
      console.error("Error adding new bus:", error);
      toast.success("Sorry!,Something went wrong!.");
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const updateBusHandler = createAsyncThunk(
  "update bus",
  async (payload: updateBusPayload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const formData = new FormData();
      formData.append("bus_name", payload.bus_name);
      formData.append("bus_number", payload.bus_number);
      formData.append("bus_make", payload.bus_make);
      formData.append("bus_model", payload.bus_model);

      formData.append("fitness_start_date", payload.fitness_start_date);
      formData.append("fitness_end_date", payload.fitness_end_date);
      formData.append(
        "mother_permit_start_date",
        payload.mother_permit_start_date
      );
      formData.append(
        "mother_permit_expiry_date",
        payload.mother_permit_expiry_date
      );
      formData.append(
        "mother_permit_id_number",
        payload.mother_permit_id_number
      );
      formData.append(
        "mother_permit_authorization_start_date",
        payload.mother_permit_authorization_start_date
      );
      formData.append(
        "mother_permit_authorization_end_date",
        payload.mother_permit_authorization_end_date
      );
      formData.append(
        "mother_permit_authorization_id_number",
        payload.mother_permit_authorization_id_number
      );
      formData.append(
        "mother_road_tax_start_date",
        payload.mother_road_tax_start_date
      );
      formData.append(
        "mother_road_tax_expiry_date",
        payload.mother_road_tax_expiry_date
      );
      formData.append(
        "mother_road_tax_id_number",
        payload.mother_road_tax_id_number
      );
      formData.append(
        "mother_road_tax_authorization_start_date",
        payload.mother_road_tax_authorization_start_date
      );
      formData.append(
        "mother_road_tax_authorization_end_date",
        payload.mother_road_tax_authorization_end_date
      );
      formData.append(
        "mother_road_tax_authorization_id_number",
        payload.mother_road_tax_authorization_id_number
      );
      formData.append(
        "all_india_tourist_permit_start_date",
        payload.all_india_tourist_permit_start_date
      );
      formData.append(
        "all_india_tourist_permit_expiry_date",
        payload.all_india_tourist_permit_expiry_date
      );
      formData.append(
        "all_india_tourist_permit_id_number",
        payload.all_india_tourist_permit_id_number
      );
      formData.append(
        "all_india_tourist_permit_authorization_start_date",
        payload.all_india_tourist_permit_authorization_start_date
      );
      formData.append(
        "all_india_tourist_permit_authorization_end_date",
        payload.all_india_tourist_permit_authorization_end_date
      );
      formData.append(
        "all_india_tourist_permit_authorization_id_number",
        payload.all_india_tourist_permit_authorization_id_number
      );
      formData.append("insurance_provider", payload.insurance_provider);
      formData.append(
        "insurance_policy_number",
        payload.insurance_policy_number
      );
      formData.append("insurance_start_date", payload.insurance_start_date);
      formData.append("insurance_end_date", payload.insurance_end_date);
      formData.append(
        "insurance_coverage_type",
        payload.insurance_coverage_type
      );
      formData.append(
        "emission_policy_number",
        payload.insurance_policy_number
      );
      formData.append("emission_start_date", payload.insurance_start_date);
      formData.append("emission_end_date", payload.insurance_end_date);

      const response = await fetch(`${endpoints.editBus}${payload.id}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
        body: formData,
      });
      const data = await response.json();
      if (response.status === 200) {
        toast.success(data.message);
        dispatch(
          setBusState({
            isSuccess: true,
          })
        );
        // console.log(data);

        dispatch(getAllBuses());
      } else {
        toast.error(data.error);
      }
    } catch (error) {
      console.error("Error adding new bus:", error);
      toast.success("Sorry!,Something went wrong!.");
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const getBusDetail = createAsyncThunk(
  "get bus details",
  async (payload: any, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(`${endpoints.busDetail}${payload}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });

      const data = await response.json();
      if (response.status === 200) {
        dispatch(
          setBusDetails({
            bus: data,
          })
        );
        toast.success("Bus Details Fetch successfully!.");
        console.log(data);
      } else {
        toast.error(data.error);
      }

      if (data.code === 404) {
        toast.error(data.error);
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const deleteBusHandler = createAsyncThunk(
  "delete bus from db",
  async (payload: any, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(`${endpoints.deleteBus}${payload}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });
      const data = await response.json();
      if (response.status === 200) {
        toast.success(data.message);
        console.log(data);
        dispatch(getAllBuses());
      } else {
        toast.error(data.error);
      }
    } catch (error) {
      console.error(error);
      toast.error("Sorry!, Something went wrong.");
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

const busSlice = createSlice({
  name: "bus slice",
  initialState: initialState,
  reducers: {
    setAllBuses: (state, action: PayloadAction<allBusesProp>) => {
      return {
        ...state,
        allBuses: action.payload.allBuses,
      };
    },
    setBusDetails: (state, action: PayloadAction<busDetailProp>) => {
      return {
        ...state,
        bus: action.payload.bus,
      };
    },
    setBusState: (state, action: PayloadAction<busStateProp>) => {
      return {
        ...state,
        isSuccess: action.payload.isSuccess,
      };
    },
    resetBusState: (state) => {
      return {
        ...state,
        isSuccess: false,
      };
    },
  },
});

export const { setAllBuses, setBusDetails, setBusState, resetBusState } =
  busSlice.actions;
export default busSlice.reducer;
