import { styled } from "styled-components";
import bg from "../../../assets/ksmbgnew.jpg";
import * as UIConstants from "../../../constants/UIConstants";

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-top: 10px;
`;
export const ListWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-top: 10px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  ${UIConstants.customView} {
    flex-wrap: wrap;
  }
`;
export const Content = styled.div`
  width: 100%;
  text-align: center;
  border-radius: 5px;
  background-image: url(${bg});
  padding: 20px;
  color: #ffffff;
`;
export const ContentName = styled.div``;
export const ContentValue = styled.div`
  font-weight: bold;
`;
