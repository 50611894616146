import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeHeading } from "../../../features/topbarHeadingSlice";
import { useParams } from "react-router-dom";
import { Wrapper } from "./StylesServiceDetail";
import { Col, Row } from "reactstrap";

import { AppDispatch, RootState } from "../../../app/Store";
import { getServiceDetail } from "../../../features/serviceSlice";

import { Helmet } from "react-helmet";
import { formatDateTime } from "../../../utility/Utility";
import ServiceStopTable from "../../Tables/ServiceStop/ServiceStopTable";

const ServiceDetail = () => {
  const dispatch: AppDispatch = useDispatch();
  const params = useParams();
  const { service } = useSelector((state: RootState) => state.services);

  useEffect(() => {
    dispatch(
      changeHeading({
        heading: `Service - ${params.id} `,
        isBackBtn: true,
        breadcrumbItems: [
          { title: "Ksm", link: "#" },
          { title: "Services", link: "#" },
          { title: `${params.id}`, link: "#" },
        ],
      })
    );
    dispatch(getServiceDetail(params.id));
  }, []);

  useEffect(()=>{
    if (Object.keys(service).length === 0) {
    dispatch(getServiceDetail(params.id));
      
  }
  },[service])

  console.log(service)

  const Dtcolumns = [
    {
      label: "ID",
      field: "index",
      sort: "asc",
      width: 150,
    },
    {
      label: "Stop Name",
      field: "stop_name",
      sort: "asc",
      width: 150,
    },
    
   
        
  ];

  return (
    <Wrapper>
      <div className="container mt-4">
        <Helmet>
          <title>{`Service | Details of ${service?.serviceName}`}</title>
          <meta
            name="description"
            content={`Details of ${service?.serviceName}`}
          />
        </Helmet>

        <div className="card">
          <div className="card-header">
            <h2 className="card-title">{service?.serviceName}</h2>
          </div>
          <div className="card-body">
            <div className="container">
              <h4 className="font-size-16">
                <strong>Service Details</strong>
              </h4>
              <hr />
              <Row>
                <Col className="md-6">
                  <div>Service Id</div>
                  <div>
                    <strong>{service?.serviceId}</strong>
                  </div>
                </Col>
                <Col className="md-6">
                  <div>Service Name</div>
                  <div>
                    <strong>{service?.serviceName}</strong>
                  </div>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col className="md-6">
                  <div>Route Id</div>
                  <div>
                    <strong>{service?.routeId}</strong>
                  </div>
                </Col>
                <Col className="md-6">
                  <div>Bus Number</div>
                  <div>
                    <strong>{service?.busNumber}</strong>
                  </div>
                </Col>
              </Row>
              <hr />
              
            </div>
          </div>

        </div>
    <ServiceStopTable columns={Dtcolumns} rows={service?.announcementPoints} service_id={service?.serviceId}/>
      </div>

    </Wrapper>
  );
};

export default ServiceDetail;
