// Import necessary React and Redux dependencies
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/Store";
import { changeHeading } from "../../../features/topbarHeadingSlice";
import { addNewUser, getAllUsersRoles, getUserDetails, updateUser } from "../../../features/userSlice";
import { useNavigate, useParams } from "react-router-dom";
import { routerPath } from "../../../routes/routes";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

interface SelectProp {
  role_name: string;
  id:any;
}

interface User {
  role_id: any;
  username: any;
  email: any;
  user_id: any;
  empid: any;
  firstname: any;
  lastname: any;
  phone?: any; // Optional field
  home_address?: any; // Optional field
  office_address?: any; // Optional field
  profile_id: any;
}

// Define the AddUser component
const UpdateUser: React.FC = () => {
  document.title="Update User | Ksm Admin Dashboard."

  // Initialize useDispatch and useNavigate hooks
  const dispatch: AppDispatch = useDispatch();
  const params = useParams();
  console.log(params)
  const navigate = useNavigate();
  const {allUserRole,isSuccess,userDetail}=useSelector((state:RootState)=>state.user);
  console.log(userDetail,allUserRole)

  const [formData, setFormData] = useState<User>({
    role_id: '',
  username: '',
  email: '',
  user_id: '',
  empid: '',
  firstname: '',
  lastname: '',
  phone: '', 
  home_address: '', 
  office_address: '', 
  profile_id: '',
  });

  useEffect(()=>{
const {
  role_id,
  username,
  email,
  user_id,
  empid,
  firstname,
  lastname,
  phone, 
  home_address, 
  office_address, 
  profile_id,
}=userDetail;
setFormData({
  role_id,
  username,
  email,
  user_id,
  empid,
  firstname,
  lastname,
  phone, 
  home_address, 
  office_address, 
  profile_id,

});
dispatch(getAllUsersRoles());

  },[userDetail])

  // useEffect to dispatch the changeHeading action when the component mounts
  useEffect(() => {
    dispatch(
      changeHeading({
        heading: "Update User",
        isBackBtn: true,
        breadcrumbItems: [
          { title: "Ksm", link: "#" },
          { title: "Users", link: "#" },
          { title: `${params.id}`, link: "#" },
        ],
      })
    );
    // dispatch(getAllUsersRoles());
    if(params.id!==undefined){
      dispatch(getUserDetails(params.id));
    }


  }, [params.id]);


  useEffect(() => {
    if (isSuccess === true) {
      navigate(routerPath.users);
    }
  }, [isSuccess]);
  // Function to handle form submission
  const handleSubmit = (event: any, errors: any, values: any) => {
    // 'values' object contains the form field values
    if (errors.length === 0) {
      const formattedData = {
        ...values,
        id:params.id,
        empid:userDetail?.empid        
      };
      // Dispatch the addNewUser action with the form values
      dispatch(updateUser(formattedData));
      console.log(formattedData)

      // Example: Navigate to a different page after successful form submission
      // navigate(routerPath.users);
    }
  };

  // Return the JSX structure for the AddUser component
  return (
    <>
      <Card>
        <CardBody>
          <h4 className="card-title">Update User</h4>
          <AvForm className="needs-validation" onSubmit={handleSubmit}>
          <Row>
          <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="validationCustom05">Username</Label>
                  <AvField
                    name="username"
                    placeholder="Username"
                    type="text"
                    errorMessage=" Please provide a valid username."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom05"
                    value={formData.username}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="validationCustom031">User Id</Label>
                  <AvField
                    name="user_id"
                    placeholder="user_id"
                    type="text"
                    errorMessage=" Please provide a valid user_id."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom031"
                    value={formData.user_id}
                  />
                </div>
              </Col>   

              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="validationCustom04">Email</Label>
                  <AvField
                    name="email"
                    placeholder="Email"
                    type="email"
                    errorMessage="Please provide a valid Email."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom04"
                    value={formData.email}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="id">Employee Id</Label>
                  <AvField
                    name="employeeId"
                    placeholder="Employee Id"
                    type="text"
                    errorMessage=" Please enter employee id."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="id"
                    value={formData.empid}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="validationCustom01">First name</Label>
                  <AvField
                    name="firstname"
                    placeholder="First name"
                    type="text"
                    errorMessage="Enter First Name"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom01"
                    value={formData.firstname}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="validationCustom02">Last name</Label>
                  <AvField
                    name="lastname"
                    placeholder="Last name"
                    type="text"
                    errorMessage="Enter Last name"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom02"
                    value={formData.lastname}
                  />
                </div>
              </Col>
            
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="validationCustom03">Phone</Label>
                  <AvField
                    name="phone"
                    placeholder="phone"
                    type="number"
                    errorMessage=" Please provide a valid phone number."
                    className="form-control"
                    // validate={{ required: { value: true } }}
                    id="validationCustom03"
                    value={formData.phone}
                  />
                </div>
              </Col>   
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="userrole">Role</Label>
                  <AvField
                    name="role_id"
                    type="select"
                    errorMessage="Please select user role."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="userrole"
                    value={formData.role_id}
                  >
                    <option value="">Select User Role</option>
                    {allUserRole.map((useRoleObj: SelectProp) => (
                      <option
                        key={useRoleObj.role_name}
                        value={useRoleObj.id}
                      >
                        {useRoleObj.role_name}
                      </option>
                    ))}
                  </AvField>
                </div>
              </Col>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="validationCusto3">Home Address</Label>
                  <AvField
                    name="home_address"
                    placeholder="home_address"
                    type="textarea"
                    errorMessage=" Please provide a valid home_address."
                    className="form-control"
                    // validate={{ required: { value: true } }}
                    id="validationCusto3"
                    value={formData.home_address}
                  />
                </div>
              </Col> 
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="validationCusto38">Office Address</Label>
                  <AvField
                    name="office_address"
                    placeholder="office_address"
                    type="textarea"
                    errorMessage=" Please provide a valid office_address."
                    className="form-control"
                    // validate={{ required: { value: true } }}
                    id="validationCusto38"
                    value={formData.office_address}
                  />
                </div>
              </Col> 
       
            

            </Row>
           

            <div className="d-flex justify-content-end">
            <Button color="primary" type="submit">
              Submit
            </Button>
            </div>
          </AvForm>
        </CardBody>
      </Card>
    </>
  );
};

// Export the AddUser component

export default UpdateUser;
