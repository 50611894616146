import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as endpoints from "../networkUtility/endpoint";
import { toggleLoader } from "./loaderSlice";
import { toast } from "react-toastify";
import * as localStorageActionType from "../localStorage/ActionTypes";
import { getLocalStorage } from "../localStorage/GetLocalStorage";
import { handle500Status } from "../utility/Utility";
import { resetUploasState } from "./uploaderSlice";

interface initialStateProp {
  allServices: any;
  service: any;
  isSuccess: boolean;
}

const initialState: initialStateProp = {
  allServices: [],
  service: {},
  isSuccess: false,
};

interface allServicesProp {
  allServices: any;
}
interface ServiceProp {
  service: any;
}

interface addServicePayload {
  serviceNumber: string;
  serviceName: string;
  busId: string;
  routeId: any;
}
interface addStopPayload {
  service_id: any;
  stopName: any;
  stopTime: any;
  audio: any;
}
interface addStopToNextPayload {
  id: any;
  index: number;
  service_id: any;
  current_stop_audio: string;
  next_stop_audio: string;
  stop_name: string;
  stop_time: string;
}
interface editStopPayload {
  service_id: any;
  stopName: string;
  stopTime: string;
  audio: any;
  index: any;
}
interface Announcement {
  stopName: string;
  stopTime: string;
  tempAudioClip: File | null;
  audioClip: any;
}

interface serviceFormData {
  busNumber: string;
  serviceId: string;
  serviceName: string;
  routeId: string;
  // startDate: string;
  // startTime: string;
  announcementPoints: Announcement[];
  // createdBy: string;
  recurring: "Y" | "N";
}

interface updateServiceFormData {
  id: any;
  busNumber: string;
  serviceId: string;
  serviceName: string;
  routeId: string;
  // startDate: string;
  // startTime: string;
  announcementPoints: Announcement[];
  // createdBy: string;
  recurring: "Y" | "N";
}

interface deleteStopPayload {
  serviceId: any;
  index: any;
  stopName: string;
}

interface serviceStateProp {
  isSuccess: boolean;
}

export const getAllServices = createAsyncThunk(
  "get all services", // Adjusted action name for clarity
  async (payload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(endpoints.allServices, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
        // body: JSON.stringify(payload),
      });

      dispatch(toggleLoader({ isLoading: false }));

      // Check if the response status is 200
      if (response.status === 200) {
        // toast.success("Services Data Fetch successfully!.");
        const data = await response.json();
        dispatch(
          setAllServices({
            allServices: data,
          })
        );
        console.log(data);
      } else if (response.status === 500) {
        // handle500Status();
        toast.error("Sorry, Something went wrong!.");
      } else {
        // Handle non-200 status codes here
        toast.error("Sorry, Something went wrong!.");
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error) {
      // Handle fetch or JSON parsing errors here
      console.error(error);
      toast.error("An error occurred. Please try again."); // You can customize the error message
    } finally {
      console.log("Finally");
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const getServiceDetail = createAsyncThunk(
  "get service details",
  async (payload: any, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));
      dispatch(resetServiceState());

      const response = await fetch(`${endpoints.serviceDetails}${payload}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });

      dispatch(toggleLoader({ isLoading: false }));

      if (response.status === 200) {
        // toast.success("Service Details Fetch Successfully!.");
        const data = await response.json();
        console.log(data);
        dispatch(
          setServices({
            service: data,
          })
        );
        // You can dispatch an action or perform other operations based on the data
      } else {
        // Handle non-200 status codes here
        toast.error("Sorry, Something went wrong!.");

        console.error(`Error: ${response.status}`);
      }
    } catch (error) {
      // Handle fetch or JSON parsing errors here
      console.error(error);
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const deleteServiceHandler = createAsyncThunk(
  "delete service from db",
  async (payload: any, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(`${endpoints.deleteService}${payload}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });
      const data = await response.json();
      if (response.status === 200) {
        toast.success(data.message);
        dispatch(getAllServices());
        console.log(data);
      }
    } catch (error) {
      console.error(error);
      toast.error("Sorry!, Something went wrong.");
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const addNewStop = createAsyncThunk(
  "add new bus",
  async (payload: addStopPayload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const formData = new FormData();
      formData.append("new_stop", payload.stopName);
      formData.append("stop_time", payload.stopTime);
      formData.append("announcement", payload.audio);

      const response = await fetch(
        `${endpoints.addServiceStop}${payload.service_id}/stops/add`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${getLocalStorage(
              localStorageActionType.GET_ACCESS_TOKEN
            )}`,
          },
          body: formData,
        }
      );
      if (response.status === 200) {
        const data = await response.json();
        toast.success("Stop Added Successfully!.");
        console.log(data);
        dispatch(getServiceDetail(payload.service_id));
        dispatch(setSericeState({ isSuccess: true }));
        dispatch(
          resetUploasState({
            audioUrl: null,
          })
        );
      } else {
        toast.error("Sorry!,Something went wrong!.");
      }
    } catch (error) {
      console.error("Error adding new bus:", error);
      toast.success("Sorry!,Something went wrong!.");
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const addStopToNextIndex = createAsyncThunk(
  "add stop",
  async (payload: addStopToNextPayload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      // const formData = new FormData();
      // formData.append("new_stop", payload.stopName);
      // formData.append("stop_time", payload.stopTime);
      // formData.append("announcement", payload.audio);
      const bodyObj = {
        current_stop_audio: payload.current_stop_audio,
        next_stop_audio: payload.next_stop_audio,
        stop_name: payload.stop_name,
        stop_time: payload.stop_time,
      };

      const response = await fetch(
        `${endpoints.addServiceStopToNextIndex}/${payload.service_id}/${payload.index}`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${getLocalStorage(
              localStorageActionType.GET_ACCESS_TOKEN
            )}`,
          },
          body: JSON.stringify(bodyObj),
        }
      );
      const data = await response.json();
      if (response.status === 200) {
        toast.success(`Stop Added position ${payload.index + 1}`);
        console.log(data);
        dispatch(getServiceDetail(payload.id));
        // dispatch(setSericeState({ isSuccess: true }));
        // dispatch(
        //   resetUploasState({
        //     audioUrl: null,
        //   })
        // );
      } else {
        toast.error(data.error);
      }
    } catch (error) {
      console.error("Error adding new stop:", error);
      toast.success("Sorry!,Something went wrong!.");
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const editStop = createAsyncThunk(
  "edit stop",
  async (payload: editStopPayload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const formData = new FormData();
      formData.append("new_stop", payload.stopName);
      formData.append("stop_time", payload.stopTime);
      // formData.append("announcement", payload.announcement);
      formData.append("announcement", payload.audio);

      const response = await fetch(
        `${endpoints.editServiceStop}${payload.service_id}/stops/edit/${payload.index}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${getLocalStorage(
              localStorageActionType.GET_ACCESS_TOKEN
            )}`,
          },
          body: formData,
        }
      );
      if (response.status === 200) {
        const data = await response.json();
        toast.success("Stop Updated Successfully!.");
        console.log(data);
        dispatch(getServiceDetail(payload.service_id));
        dispatch(setSericeState({ isSuccess: true }));
        dispatch(
          resetUploasState({
            audioUrl: null,
          })
        );
      } else {
        toast.error("Sorry!,Something went wrong!.");
      }
    } catch (error) {
      console.error("Error adding new bus:", error);
      toast.success("Sorry!,Something went wrong!.");
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const deleteStopHandler = createAsyncThunk(
  "delete stopm from list",
  async (payload: deleteStopPayload, { dispatch }) => {
    try {
      // return;
      dispatch(toggleLoader({ isLoading: true }));
      const formData = new FormData();
      formData.append("stop_name", payload.stopName);

      const response = await fetch(
        `${endpoints.deleteServiceStop}${payload.serviceId}/stops/delete/${payload.index}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${getLocalStorage(
              localStorageActionType.GET_ACCESS_TOKEN
            )}`,
          },
          body: formData,
        }
      );
      const data = await response.json();
      if (response.status === 200) {
        toast.success(data.message);
        console.log(data);
        dispatch(getServiceDetail(payload.serviceId));
      } else if (response.status === 400) {
        toast.error(data.error);
      } else {
        toast.success(data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error("Sorry!, Something went wrong.");
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const addNewService = createAsyncThunk(
  "add new Service",
  async (payload: serviceFormData, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(`${endpoints.addService}`, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",

          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
        body: JSON.stringify(payload),
      });
      // console.log(formData);
      const data = await response.json();
      if (response.status === 200) {
        dispatch(setSericeState({ isSuccess: true }));

        toast.success(data.message);
        console.log(data);
        dispatch(getAllServices());
      } else {
        toast.success(data.error);
      }
    } catch (error) {
      console.error("Error adding new Service:", error);
      toast.success("Sorry!,Something went wrong!.");
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const updateService = createAsyncThunk(
  "update Service",
  async (payload: updateServiceFormData, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(
        `${endpoints.editService}${payload.id}`, // add update service api
        {
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",

            Authorization: `Bearer ${getLocalStorage(
              localStorageActionType.GET_ACCESS_TOKEN
            )}`,
          },
          body: JSON.stringify(payload),
        }
      );
      const data = await response.json();
      // console.log(formData);
      if (response.status === 200) {
        dispatch(setSericeState({ isSuccess: true }));
        toast.success(data.message);
        console.log(data);
        // dispatch(getServiceDetail(payload.id));
        dispatch(getAllServices());
      } else {
        toast.success(data.error);
      }
    } catch (error) {
      console.error("Error adding new Service:", error);
      toast.success("Sorry!,Something went wrong!.");
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

const servicesSlice = createSlice({
  name: "route slice",
  initialState: initialState,
  reducers: {
    setAllServices: (state, action: PayloadAction<allServicesProp>) => {
      return {
        ...state,
        allServices: action.payload.allServices,
      };
    },
    setServices: (state, action: PayloadAction<ServiceProp>) => {
      return {
        ...state,
        service: action.payload.service,
      };
    },
    setSericeState: (state, action: PayloadAction<serviceStateProp>) => {
      return {
        ...state,
        isSuccess: action.payload.isSuccess,
      };
    },
    resetServiceState: (state) => {
      return {
        ...state,
        isSuccess: false,
      };
    },
  },
});

export const {
  setAllServices,
  setServices,
  setSericeState,
  resetServiceState,
} = servicesSlice.actions;
export default servicesSlice.reducer;
