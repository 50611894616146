import styled from "styled-components";
import * as UIConstants from "../../constants/UIConstants";

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

export const SectionWrapper = styled.div`
  display: block;
  justify-content: flex-start;
  align-items: stretch;
  gap: 20px;
  margin: 10px 0 10px 0;
  ${UIConstants.mobileView} {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
`;
export const SectionWrapper2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 15px;
  margin: 10px 0 10px 0;
  ${UIConstants.mobileView} {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
`;

export const Section = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  gap: 15px;
  margin: 10px 0 10px 0;
  ${UIConstants.mobileView} {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
`;

export const SectioTwo = styled.div`
  /* width: 100%; */
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  gap: 15px;
  margin: 10px 0 10px 0;
  ${UIConstants.mobileView} {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
`;
