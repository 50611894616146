import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as endpoints from "../networkUtility/endpoint";
import { toggleLoader } from "./loaderSlice";
import { toast } from "react-toastify";
import * as localStorageActionType from "../localStorage/ActionTypes";
import { getLocalStorage } from "../localStorage/GetLocalStorage";
import { handle500Status } from "../utility/Utility";

interface initialStateProp {
  audioUrl: string | any;
  secondAudioUrl: string | any;
  imageUrl: string | any;
}

const initialState: initialStateProp = {
  audioUrl: null,
  secondAudioUrl: null,
  imageUrl: null,
};

interface audioUrlProp {
  audioUrl: string | null;
}
interface secondAudioUrlProp {
  secondAudioUrl: string | null;
}
interface imageUrlProp {
  imageUrl: string | null;
}

interface audioPayload {
  files: File[];
}

interface imagePayload {
  files: File[];
}

const uploaderSlice = createSlice({
  name: "uploader slice",
  initialState: initialState,
  reducers: {
    setAudioUrl: (state, action: PayloadAction<audioUrlProp>) => {
      return {
        ...state,
        audioUrl: action.payload.audioUrl,
      };
    },
    setSecondAudioUrl: (state, action: PayloadAction<secondAudioUrlProp>) => {
      return {
        ...state,
        secondAudioUrl: action.payload.secondAudioUrl,
      };
    },
    setImgUrl: (state, action: PayloadAction<imageUrlProp>) => {
      return {
        ...state,
        imageUrl: action.payload.imageUrl,
      };
    },
    resetUploasState: (state, action: PayloadAction<audioUrlProp>) => {
      return {
        ...state,
        audioUrl: null,
        secondAudioUrl: null,
        imageUrl: null,
      };
    },
  },
});

export const audioUploader = createAsyncThunk(
  "upload audio",
  async (payload: audioPayload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const formData = new FormData();
      payload.files.forEach((file, index) => {
        formData.append(`audioFile`, file);
      });

      const response = await fetch(endpoints.audioUpload, {
        headers: {
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
        method: "POST",
        body: formData,
      });
      dispatch(toggleLoader({ isLoading: false }));

      if (response.ok) {
        const responseData = await response.json();
        console.log("Upload successful. Response:", responseData);
        toast.success("Upload successful.");
        dispatch(
          setAudioUrl({
            audioUrl: responseData?.audioUrl,
          })
        );
      } else {
        console.error("Error uploading audio:", response.statusText);
      }
    } catch (error) {
      console.error("Error uploading audio:", error);
    }
  }
);

export const secondAudioUploader = createAsyncThunk(
  "second upload audio",
  async (payload: audioPayload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const formData = new FormData();
      payload.files.forEach((file, index) => {
        formData.append(`audioFile`, file);
      });

      const response = await fetch(endpoints.audioUpload, {
        headers: {
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
        method: "POST",
        body: formData,
      });
      dispatch(toggleLoader({ isLoading: false }));

      if (response.ok) {
        const responseData = await response.json();
        console.log("Upload successful. Response:", responseData);
        toast.success("Upload successful.");
        dispatch(
          setSecondAudioUrl({
            secondAudioUrl: responseData?.audioUrl,
          })
        );
      } else {
        console.error("Error uploading audio:", response.statusText);
      }
    } catch (error) {
      console.error("Error uploading audio:", error);
    }
  }
);

export const ImageUploader = createAsyncThunk(
  "image upload ",
  async (payload: imagePayload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const formData = new FormData();
      payload.files.forEach((file, index) => {
        formData.append(`userAvatar`, file);
      });

      const response = await fetch(endpoints.avatarUpload, {
        headers: {
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
        method: "POST",
        body: formData,
      });
      dispatch(toggleLoader({ isLoading: false }));

      if (response.ok) {
        const responseData = await response.json();
        console.log("Upload successful. Response:", responseData);
        toast.success("Upload successful.");
        dispatch(
          setImgUrl({
            imageUrl: responseData?.userAvatarUrl,
          })
        );
      } else {
        console.error("Error uploading audio:", response.statusText);
      }
    } catch (error) {
      console.error("Error uploading audio:", error);
    }
  }
);

export const { setAudioUrl, resetUploasState, setSecondAudioUrl, setImgUrl } =
  uploaderSlice.actions;
export default uploaderSlice.reducer;
