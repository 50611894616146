import React, { useEffect } from "react";
import { AppDispatch, RootState } from "../../../app/Store";
import { useDispatch, useSelector } from "react-redux";
import { changeHeading } from "../../../features/topbarHeadingSlice";
import { getAllUsers, getAllUsersRoles, resetUserState } from "../../../features/userSlice";

import ButtonComponent from "../../../components/UI/Buttons/Button";
import {
  AppButtonType,
  ButtonSizeVariant,
  ButtonType,
  ButtonVariant,
} from "../../../utility/InterFacesAndEnum";
import { useNavigate } from "react-router-dom";
import { routerPath } from "../../../routes/routes";
import UserTable from "../../Tables/User/UserTable";
import { Wrapper } from "../UsersList/StyledUserList";
import UserRoleTable from "../../Tables/UserRole/UserRoleTable";


const userRoles:any[]=[
  // {id:1,user_role_name:"admin",status:"Active"},
  // {id:2,user_role_name:"user",status:"inactive"},
  // {id:3,user_role_name:"admin",status:"Active"},
  // {id:4,user_role_name:"user",status:"inactive"},
  // {id:5,user_role_name:"admin",status:"Active"},
  // {id:6,user_role_name:"user",status:"Active"}
]

const UserRoleList: React.FC = () => {
  document.title = "User Role | KSM - Admin Dashboard.";

  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { allUserRole } = useSelector((state: RootState) => state.user);
  console.log(allUserRole)
  useEffect(() => {
    dispatch(
      changeHeading({
        heading: "User Role",
        isBackBtn: false,
        breadcrumbItems: [
          { title: "Ksm", link: "#" },
          { title: "Users Role", link: "#" },
        ],
      })
    );
    dispatch(getAllUsersRoles());
    dispatch(resetUserState());

  }, []);

  const Dtcolumns = [
    {
      label: "Id",
      field: "id",
      sort: "asc",
      width: 150,
    },
    {
      label: "User role name",
      field: "role_name",
      sort: "asc",
      width: 150,
    },
   
    
  ];

  const navigateToaddUser = () => {
    navigate(routerPath.addUser);
  };

  return (
    <Wrapper>
      <UserRoleTable columns={Dtcolumns} rows={allUserRole} />
    </Wrapper>
  );
};

export default UserRoleList;
