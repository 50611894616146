import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/Store";
import { changeHeading } from "../../../features/topbarHeadingSlice";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

import { Wrapper } from "../../Services/AddService/StyledAddService";

import { useNavigate } from "react-router-dom";
import { getAllServices } from "../../../features/serviceSlice";
import { addNewtrip } from "../../../features/tripSlice";
import { routerPath } from "../../../routes/routes";

interface SelectProp {
  service_name: string;
  service_id: string;

}

interface ServiceData {
  id: any;
  isRecurring: any;
  bus_number: string;
  route_number: string;
  service_id: string;
  service_name: string;
  service_status: string;
}

const AddTrip = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { allServices } = useSelector((state: RootState) => state.services);

  const [selectedService, setSelectedService] = useState<ServiceData | null>(
    null
  );

  const handleServiceSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedServiceName = event.target.value;
    const stopObj = allServices.find(
      (service: ServiceData) => service.service_id === selectedServiceName
    );
    if (stopObj) {
      setSelectedService(stopObj);
    }
    console.log(selectedServiceName)
  };
  const {isSuccess}=useSelector((state:RootState)=>state.trips);

  useEffect(() => {
    dispatch(
      changeHeading({
        heading: "Add Trip",
        isBackBtn: true,
        breadcrumbItems: [
          { title: "Ksm", link: "#" },
          { title: "Trip", link: "#" },
          { title: "Add Trip", link: "#" },
        ],
      })
    );
    dispatch(getAllServices());
  }, []);

    useEffect(() => {
      if (isSuccess === true) {
        navigate(routerPath.trip);
      }
    }, [isSuccess]);

  const handleSubmit = (event: any, errors: any, values: any) => {
    if (errors.length === 0) {
      const formattedData = {
        ...values,
        bus_number: selectedService?.bus_number,
        route_number: selectedService?.route_number,
        service_id: selectedService?.service_id,
      };
      // Dispatch the addNewUser action with the form values
      dispatch(addNewtrip(formattedData));
      console.log(formattedData);
    }
  };


  return (
    <Wrapper>
      <Card>
        <CardBody>
          <h4 className="card-title">Add Trip</h4>
          <AvForm className="needs-validation" onSubmit={handleSubmit}>
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="busNumber">Service</Label>
                  <AvField
                    name="service_id"
                    type="select"
                    errorMessage="Please select service."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="busNumber"
                    onChange={handleServiceSelect}
                  >
                    <option value="">Select Service</option>
                    {allServices.map((serviceObj: SelectProp) => (
                      <option
                        key={serviceObj.service_name}
                        value={serviceObj.service_id}
                      >
                        {serviceObj.service_name}
                      </option>
                    ))}
                  </AvField>
                </div>
              </Col>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="BusNumber">Bus Number</Label>
                  <AvField
                    name="bus_number"
                    placeholder="Bus Number"
                    type="text"
                    errorMessage="Please select service bus number will populate automatically."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="BusNumber"
                    value={selectedService?.bus_number}
                    disabled
                  />
                </div>
              </Col>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="routeNumber">Route Number</Label>
                  <AvField
                    name="route_number"
                    placeholder="Route Number"
                    type="text"
                    errorMessage="Please select service route number will populate automatically."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="routeNumber"
                    value={selectedService?.route_number}
                    disabled
                  />
                </div>
              </Col>

              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="startDate1">Start Date</Label>
                  <AvField
                    name="start_date"
                    placeholder="Start Date"
                    type="date"
                    errorMessage="Please provide start date."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="startDate1"
                  />
                </div>
              </Col>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="endDate1">End Date</Label>
                  <AvField
                    name="end_date"
                    placeholder="End Date"
                    type="date"
                    errorMessage="Please provide end date."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="endDate1"
                  />
                </div>
              </Col>
            </Row>
            <div className="d-flex justify-content-end">
              <Button color="primary" type="submit">
                Submit
              </Button>
            </div>
          </AvForm>
        </CardBody>
      </Card>
    </Wrapper>
  );
};

export default AddTrip;
