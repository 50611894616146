// Import necessary React components
import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import { useNavigate, useParams } from "react-router-dom";

// Import styles if needed
import "../datatables.scss";
import { routerPath } from "../../../routes/routes";
import ActionModal from "../../../components/Modal/ActionModal/ActionModal";
import { AppDispatch, RootState } from "../../../app/Store";
import { useDispatch, useSelector } from "react-redux";
import { transformTimeWithAMPM } from "../../../utility/Utility";
import { addStopToNextIndex, deleteStopHandler } from "../../../features/serviceSlice";
import { nanoid } from "@reduxjs/toolkit";
import { PiNotePencilBold } from "react-icons/pi";
import { FaRegTrashAlt } from "react-icons/fa";
import AddIcon from '@mui/icons-material/Add';
import Add from "@mui/icons-material/Add";
import AddServceStopModal from "../../../components/Modal/AddServceStopModal/AddServceStopModal";
import { getAllStops } from "../../../features/stopSlice";

// Import necessary interfaces
interface RowData {
  stop_name: string;
  time: string;
  current_stop_audio: string;
  next_stop_audio: string;

}

interface ColumnData {
  label: any;
  field: string;
  sort?: string;
  width?: number;
}

interface TableProp {
  columns: ColumnData[];
  rows: RowData[] | [];
  service_id:any;
}
interface tempStateData {
  stopName: string;
  index: number | null;
}

// Define the StopTable component
const ServiceStopTable: React.FC<TableProp> = ({ columns, rows, service_id }) => {
  // Initialize the navigate function from react-router-dom
  const params = useParams();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [openDeleteModel, setOpenDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [name, setName] = useState("");
  const [tempState, setTempState] = useState<tempStateData>({
    stopName: "",
    index: null,
  });
  const [openAddStopModal, setopenAddStopModal] = useState(false);
  const { allStops } = useSelector((state: RootState) => state.stops);


useEffect(()=>{
  dispatch(getAllStops());

},[])
  // Add a new column for the "View" button and audio player
  const newColumns = [
    ...columns,
    {
      label: "Time",
      field: "stop_time",
      sort: "asc",
      width: 150,
    },
    {
      label: "Current Stop Audio",
      field: "current_stop_audio",
      width: 150,
    },
    {
      label: "Next Stop Audio",
      field: "next_stop_audio",
      width: 150,
    },
    {
      label: <div className="text-center">Actions</div>,
      field: "actions",
      width: 200,
    },
  ];

  const handleDeleteStopModal = (stopName: string, index: number) => {
    setOpenDeleteModal(true);
    // console.log(index);
    // setName(stopName);
    setTempState({
      stopName: stopName,
      index: index,
    });
  };

  const confirmDelete = () => {
    setOpenDeleteModal(false);
    // console.log(tempState);
    // dispatch(deleteStopHandler({...tempState,serviceId:service_id}))
    setTempState({
      stopName: '',
      index: null,
    });
  };
  const handleAddStopModal = ( index: number) => {
    setopenAddStopModal(true);
    console.log(index);
    setTempState({
      stopName: '',
      index: index,
    });
    // setName(stopName);
  };
  const confirmAddStop=(stopObj:any)=>{
      const formattedData = {
        ...stopObj,
        index:tempState.index,
        service_id: service_id,
        id:params.id      
      };
    console.log(formattedData)
    dispatch(addStopToNextIndex(formattedData))
    setopenAddStopModal(false)
    setTempState({
      stopName: '',
      index: null,
    });

  }

  // Configure data for MDBDataTable with a custom "View" button and audio player
  const data = {
    columns: newColumns,
    rows: rows
      ? rows.map((rowData, index) => ({
          ...rowData,
          time: (
            <div>
              {rowData.time}
            </div>
          ),
          
          current_stop_audio: (
            <div>
              <audio key={nanoid(4)} controls>
                <source src={rowData.current_stop_audio} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            </div>
          ),
          next_stop_audio: (
            <div>
              <audio key={nanoid(4)} controls>
                <source src={rowData.next_stop_audio} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            </div>
          ),
          actions: (
            <div className="d-flex justify-content-around gap-2 align-items-center">
              <Button
                type="button"
                color="info"
                className="waves-effect waves-light"
                onClick={() =>handleAddStopModal(index)}
              >
            <Add style={{ fontSize: "18px" }}/>
                
              </Button>
              <Button
                type="button"
                color="danger"
                className="waves-effect waves-light"
                onClick={() => handleDeleteStopModal(rowData.stop_name, index)}
              >
            <FaRegTrashAlt style={{ fontSize: "18px" }}/>
                
              </Button>
            </div>
          ),
        }))
      : [],
  };

  // Render the component structure
  return (
    <React.Fragment>
      <ActionModal
        openModal={openDeleteModel}
        setOpenModal={setOpenDeleteModal}
        confirm={confirmDelete}
        title={"Delete"}
        content={"Stop"}
      />
      <AddServceStopModal
        openModal={openAddStopModal}
        setOpenModal={setopenAddStopModal}
        confirm={confirmAddStop}
        index={tempState.index}
        stops={allStops}
      />

      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="d-flex justify-content-between">
                <span>Stops List</span>
                {/* <Button
                  type="button"
                  color="primary"
                  className="waves-effect waves-light"
                  // onClick={() => navigate(routerPath.addServiceStop(params.id))}
                  onClick={() =>{} }

                >
                  Add Stop
                </Button>{" "} */}
              </CardTitle>
              {/* <MDBDataTable responsive bordered noBottomColumns data={data}  reorderableRows /> */}
              <MDBDataTable responsive bordered noBottomColumns data={data} reorderableRows />

            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

// Export the StopTable component
export default ServiceStopTable;
