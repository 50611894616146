import { allUser } from "./../networkUtility/endpoint";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as endpoints from "../networkUtility/endpoint";
import { toggleLoader } from "./loaderSlice";
import { toast } from "react-toastify";
import { setLocalStorage } from "../localStorage/SetLocalStorage";
import * as localStorageActionType from "../localStorage/ActionTypes";
import { getLocalStorage } from "../localStorage/GetLocalStorage";
import { handle500Status } from "../utility/Utility";

interface initialStateProp {
  allUsers: any;
  userDetail: any;
  loggedInUser: any;
  allUserRole: any;
  userRoleDetail: any;
  isSuccess: boolean;
}

const initialState: initialStateProp = {
  allUsers: [],
  allUserRole: [],
  userRoleDetail: {},
  userDetail: {},
  loggedInUser: {},
  isSuccess: false,
};

interface allUserProp {
  allUsers: any;
}
interface userDetailProp {
  userDetails: any;
}

interface userRoleDetailProp {
  userRoleDetails: any;
}
interface loggedInUserProp {
  loggedInUser: any;
}

interface userPayload {
  userId: any;
}

interface userFormPayload {
  username: string;
  email: string;
  firstname: string;
  lastname: string;
  employeeId: string;
  designation: string;
  supervisor: string;
  password: string;
  confirm_password: string;
  role: string;
}

interface updateUserPayload {
  email: any;
  empid: any;
  employeeId: any;
  firstname: any;
  home_address: any;
  id: any;
  lastname: any;
  office_address: any;
  phone: any;
  role_id: any;
  user_id: any;
  username: any;
}

interface userRoleFormPayload {
  permissions: any;
  role_name: string;
  status: string;
}

interface editRoleFormPayload {
  id: any;
  permissions: any;
  role_name: string;
  status: string;
}

interface allUserRoleProp {
  allUserRoles: any;
}

interface userStateProp {
  isSuccess: boolean;
}

export const getAllUsers = createAsyncThunk(
  "get all users",
  async (payload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(endpoints.allUser, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });

      if (!response.ok) {
        // Handle non-successful responses (e.g., 4xx, 5xx)
        throw new Error(`Failed to fetch users. Status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data);
      if (response.status === 200) {
        // toast.success("All Users Fetch successfully!.");
        dispatch(setAllUsers({ allUsers: data }));
      } else if (response.status === 500) {
        handle500Status();
      } else {
        // Handle non-200 status codes here
        toast.error("Sorry, Something went wrong!.");

        console.error(`Error: ${response.status}`);
      }
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
      console.log("Finally");
    }
  }
);

export const deleteUser = createAsyncThunk(
  "delete bus from db",
  async (payload: any, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(`${endpoints.deleteUser}${payload}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });
      const data = await response.json();
      if (response.status === 200) {
        toast.success(data.message);
        console.log(data);
        dispatch(getAllUsers());
      } else {
        toast.error(data.error);
      }
    } catch (error) {
      console.error(error);
      toast.error("Sorry!, Something went wrong.");
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const getUserDetails = createAsyncThunk(
  "get  user details",
  async (payload: any, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(`${endpoints.user}${payload}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });

      const data = await response.json();
      console.log(data);
      console.log(response);
      if (response.status === 500) {
        // setLocalStorage(localStorageActionType.CLEAR_LOGIN_USER_DETAIL, "");
        // window.location.reload();
        // handle500Status();
      }
      if (response.status === 200) {
        // toast.success(data.messag);
        dispatch(
          setUserDetails({
            userDetails: data,
          })
        );
      } else {
        // toast.error("Sorry!, Something went wrong.");
      }
    } catch (error) {
      console.error("Error getting logged-in user:", error);
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
      console.log("Finally");
    }
  }
);

export const getLoggedInUser = createAsyncThunk(
  "get logged in user details",
  async (payload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(endpoints.loggedInUser, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });

      const data = await response.json();
      console.log(data);
      console.log(response);
      if (response.status === 500) {
        // setLocalStorage(localStorageActionType.CLEAR_LOGIN_USER_DETAIL, "");
        // window.location.reload();
        handle500Status();
      }
      if (response.status === 200) {
        toast.success(data.message);
        dispatch(
          setLoggedInUser({
            loggedInUser: data.profile,
          })
        );
      } else {
        // toast.error("Sorry!, Something went wrong.");
      }
    } catch (error) {
      console.error("Error getting logged-in user:", error);
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
      console.log("Finally");
    }
  }
);

export const addNewUser = createAsyncThunk(
  "add user slice",
  async (payload: userFormPayload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const formData = new FormData();
      formData.append("username", payload.username);
      formData.append("email", payload.email);
      formData.append("firstname", payload.firstname);
      formData.append("lastname", payload.lastname);
      formData.append("employeeId", payload.employeeId);
      formData.append("designation", payload.designation);
      formData.append("supervisor", payload.supervisor);
      formData.append("password", payload.password);
      formData.append("confirm_password", payload.confirm_password);
      formData.append("roleName", payload.role);

      const response = await fetch(endpoints.addUser, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (response.status === 200) {
        toast.success("User Added Successfully!.");
        console.log(data);
        dispatch(getAllUsers());
      } else if (response.status === 500) {
        handle500Status();
      } else {
        toast.success("Sorry!,Something went wrong!.");
      }
      console.log(data);
    } catch (error) {
      console.error("Error:", error);
      // You can handle the error here or re-throw it if needed
      throw error;
    } finally {
      console.log("finally");
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const updateUser = createAsyncThunk(
  "update user slice",
  async (payload: updateUserPayload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(`${endpoints.updateUser}${payload.id}`, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (response.status === 200) {
        toast.success("User Update Successfully!.");
        console.log(data);
        dispatch(getAllUsers());
        dispatch(setUserState({ isSuccess: true }));
      } else if (response.status === 500) {
        handle500Status();
      } else {
        toast.success("Sorry!,Something went wrong!.");
      }
      console.log(data);
    } catch (error) {
      console.error("Error:", error);
      // You can handle the error here or re-throw it if needed
      throw error;
    } finally {
      console.log("finally");
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const getAllUsersRoles = createAsyncThunk(
  "get all users roles",
  async (payload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(endpoints.allUserRoles, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });

      if (!response.ok) {
        // Handle non-successful responses (e.g., 4xx, 5xx)
        throw new Error(`Failed to fetch users. Status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data);
      if (response.status === 200) {
        // toast.success("All Users Fetch successfully!.");
        dispatch(setAllUserRoles({ allUserRoles: data }));
      } else if (response.status === 500) {
        handle500Status();
      } else {
        // Handle non-200 status codes here
        toast.error("Sorry, Something went wrong!.");

        console.error(`Error: ${response.status}`);
      }
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
      console.log("Finally");
    }
  }
);

export const addNewUserRole = createAsyncThunk(
  "add user role slice",
  async (payload: userRoleFormPayload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      // const formData = new FormData();
      // formData.append("role_name", payload.role_name);
      // formData.append("status", payload.status);
      // formData.append("permissions", JSON.stringify(payload.permissions));

      const response = await fetch(endpoints.addUserRoles, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (response.status === 200) {
        dispatch(setUserState({ isSuccess: true }));
        toast.success("Role Added Successfully!.");
        console.log(data);
        dispatch(getAllUsersRoles());
      } else if (response.status === 500) {
        handle500Status();
      } else {
        toast.success("Sorry!,Something went wrong!.");
      }
      console.log(data);
    } catch (error) {
      console.error("Error:", error);
      // You can handle the error here or re-throw it if needed
      throw error;
    } finally {
      console.log("finally");
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const getUserRoleDetail = createAsyncThunk(
  "get user details",
  async (payload: any, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(`${endpoints.viewUserRoles}${payload}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });

      const data = await response.json();
      if (response.status === 200) {
        dispatch(
          setUserRoleDetails({
            userRoleDetails: data,
          })
        );
        toast.success("Data Fetch successfully!.");
        console.log(data);
      }

      if (data.code === 404) {
        toast.error(data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const deleteUserRole = createAsyncThunk(
  "delete bus from db",
  async (payload: any, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(`${endpoints.deleteUserRole}${payload}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });
      const data = await response.json();
      if (response.status === 200) {
        toast.success(data.message);
        console.log(data);
        dispatch(getAllUsersRoles());
      } else {
        toast.error(data.error);
      }
    } catch (error) {
      console.error(error);
      toast.error("Sorry!, Something went wrong.");
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const updateUserRole = createAsyncThunk(
  "add user role slice",
  async (payload: editRoleFormPayload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      // const formData = new FormData();
      // formData.append("role_name", payload.role_name);
      // formData.append("status", payload.status);
      // formData.append("permissions", JSON.stringify(payload.permissions));

      const response = await fetch(`${endpoints.editUserRole}${payload.id}`, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (response.status === 200) {
        dispatch(setUserState({ isSuccess: true }));
        toast.success("update role Successfully!.");
        console.log(data);
        dispatch(getAllUsersRoles());
      } else if (response.status === 500) {
        handle500Status();
      } else {
        toast.success("Sorry!,Something went wrong!.");
      }
      console.log(data);
    } catch (error) {
      console.error("Error:", error);
      // You can handle the error here or re-throw it if needed
      throw error;
    } finally {
      console.log("finally");
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

const userSlice = createSlice({
  name: "user slice",
  initialState: initialState,
  reducers: {
    setAllUsers: (state, action: PayloadAction<allUserProp>) => {
      return {
        ...state,
        allUsers: action.payload.allUsers,
      };
    },
    setUserDetails: (state, action: PayloadAction<userDetailProp>) => {
      return {
        ...state,
        userDetail: action.payload.userDetails,
      };
    },
    setLoggedInUser: (state, action: PayloadAction<loggedInUserProp>) => {
      return {
        ...state,
        loggedInUser: action.payload.loggedInUser,
      };
    },
    setAllUserRoles: (state, action: PayloadAction<allUserRoleProp>) => {
      return {
        ...state,
        allUserRole: action.payload.allUserRoles,
      };
    },
    setUserRoleDetails: (state, action: PayloadAction<userRoleDetailProp>) => {
      return {
        ...state,
        userRoleDetail: action.payload.userRoleDetails,
      };
    },
    setUserState: (state, action: PayloadAction<userStateProp>) => {
      return {
        ...state,
        isSuccess: action.payload.isSuccess,
      };
    },
    resetUserState: (state) => {
      return {
        ...state,
        isSuccess: false,
      };
    },
  },
});

export const {
  setAllUsers,
  setLoggedInUser,
  setUserDetails,
  setAllUserRoles,
  setUserState,
  resetUserState,
  setUserRoleDetails,
} = userSlice.actions;
export default userSlice.reducer;
