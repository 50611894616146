import styled from "styled-components";
import { FiMoon } from "react-icons/fi";
import { FiBell } from "react-icons/fi";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { GoSun } from "react-icons/go";
import * as UIConstants from "../../constants/UIConstants";
import bg from "../../../assets/ksmbgnew.jpg";

interface WrapperProp {
  nightMode: boolean;
}

export const CountryImage = styled.img`
  height: 50px;
  width: 50px;
  object-fit: contain;
  /* border-radius: 50%; */
  /* cursor: pointer; */
`;

export const ActionBtn = styled.div``;

export const MoonIcon = styled(FiMoon)<WrapperProp>`
  height: 25px;
  width: 25px;
  cursor: pointer;
  color: ${(props) => (props.nightMode ? "white" : "black")};
`;
export const Back = styled(ArrowBackIcon)`
  height: 25px;
  width: 25px;
  cursor: pointer;
  margin-right: 10px;
`;

export const SunIcon = styled(GoSun)<WrapperProp>`
  height: 25px;
  width: 25px;
  cursor: pointer;
  color: ${(props) => (props.nightMode ? "white" : "black")};
`;

export const NotificationWrapper = styled.div`
  margin-top: 15px;
  position: relative;
  display: flex;
`;
export const Count = styled.div`
  text-align: center;
  color: #ffffff;
  border: 3px solid #ffffff;
  left: 14px;
  top: -18px;
  position: absolute;
  height: 20px;
  padding: 0px 8px 2px 8px;
  border-radius: 50%;
  background-color: rgba(191, 173, 117, 255);
`;
export const BellIcon = styled(NotificationsNoneIcon)<WrapperProp>`
  height: 35px;
  width: 35px;
  cursor: pointer;
  margin-right: 5px;
  margin-left: 5px;
  color: ${(props) => (props.nightMode ? "white" : "black")};
`;

export const Wrapper = styled.div<WrapperProp>`
  width: 100%;
  max-height: 10vh;
  box-sizing: border-box;
  background-color: ${(prop) => (prop.nightMode ? "#003032" : "#ffffff")};
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  /* height: 60px; */
  /* padding: 5px 0 10px 0; */
  /* box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.04); */
  border-bottom: 1px solid #f6f6f6;
`;

export const Heading = styled.div`
  margin-top: 10px;
  margin-left: 15px;

  font-weight: 500;
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
  /* text-align: center; */
  font-size: 20px;
  color: #000000;
  ${UIConstants.mobileView} {
    margin-left: 70px;
  }
`;
export const HeadingContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const DropdownContainer = styled.div`
  position: absolute;
  top: 40px;
  right: 10px;
  width: 120px;
  z-index: 1;
  /* box-shadow: 0px 4px 44px rgb(0 0 0 / 12%); */
  background-color: #ffffff;
  border-radius: 5px;
  display: none;
`;
export const UserName = styled.div`
  text-transform: capitalize;
`;

export const ProfielWapper = styled.div`
  position: relative;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  transition: 0.4s;

  &:hover {
    ${DropdownContainer} {
      display: block;
    }
  }
  ${UIConstants.mobileView} {
    /* margin-right: -10px; */
  }
`;

export const ProfileImage = styled.img`
  height: 50px;
  width: 50px;
  object-fit: contain;
  border-radius: 100%;
  cursor: pointer;
`;

export const DropdownOption = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 12px 16px;
  border-bottom: 1px solid #f7f7f7;
  color: #000000;
  text-align: left;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background-color: #f5f5f5;
    border-radius: 5px;
  }
`;

export const ProfileIcon = styled(AccountCircleIcon)`
  /* height: 15px; */
  color: #000000;
`;
export const PowerIcon = styled(PowerSettingsNewIcon)`
  /* height: 15px; */
  color: #000000;
`;
