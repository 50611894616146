import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../app/Store";
import { changeHeading } from "../../../../features/topbarHeadingSlice";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { routerPath } from "../../../../routes/routes";
import { addNewRoute, getRouteDetail, updateRoute } from "../../../../features/routeSlice";

interface data {
  routeid:any, origin:any, destination:any, status:any
}

const UpdateRoute: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const params=useParams();
  const {isSuccess}=useSelector((state:RootState)=>state.route);
  const {route}= useSelector((state:RootState)=>state.route);
  const [formData, setFormData] = useState<data>({
    routeid: "",
    origin: "",
    destination: "",
    status: "",
  });

  useEffect(() => {
    dispatch(
      changeHeading({
        heading: "Update Route",
        isBackBtn: true,
        breadcrumbItems: [
          { title: "Ksm", link: "#" },
          { title: "Routes", link: "#" },
          { title: "Update Route", link: "#" },
        ],
      })
    );
    console.log(route)
    dispatch(getRouteDetail(params.id));
  }, []);

  useEffect(()=>{
    const { routeid, origin, destination, status } = route;
    setFormData({
      routeid,
      origin,
      destination,
      status,
    });
  },[route])

  useEffect(()=>{
    if(isSuccess===true){
        navigate(routerPath.route);
    }
  },[isSuccess])

  const handleSubmit = (event: any, errors: any, values: any) => {
    if (errors.length === 0) {
      // Dispatch the addNewUser action with the form values
      dispatch(updateRoute(values));
      console.log(values);
    }
  };

 

  return (
    <>
      <Card>
        <CardBody>
          <h4 className="card-title">Update Route</h4>
          <AvForm className="needs-validation" onSubmit={handleSubmit}>
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="routeid">Route Id</Label>
                  <AvField
                    name="routeid"
                    
                    placeholder="Route Id"
                    type="text"
                    errorMessage="Please enter route id."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="routeid"
                    value={formData.routeid}
                    disabled
                  />
                </div>
              </Col>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="origin">Origin</Label>
                  <AvField
                    name="origin"
                    placeholder="Origin"
                    type="text"
                    errorMessage="Please provide a valid origin."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="origin"
                    value={formData.origin}

                  />
                </div>
              </Col>
            
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="destination">Destination</Label>
                  <AvField
                    name="destination"
                    placeholder="Destination"
                    type="text"
                    errorMessage="Please provide a valid destination."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="destination"
                    value={formData.destination}

                  />
                </div>
              </Col>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="status1">Status</Label>
                  <AvField
                    name="status"
                    type="select"
                    errorMessage="Please provide a valid destination."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="status1"
                    value={formData.status==='inactive'? 0:1}

                  >
                  
                    <option value="">Select Status</option>
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </AvField>
                </div>
              </Col>
            </Row>

            <div className="d-flex justify-content-end">
              <Button color="primary" type="submit">
                Submit
              </Button>
            </div>
          </AvForm>
        </CardBody>
      </Card>
    </>
  );
};

// Export the AddRoute component
export default UpdateRoute;
