import React from "react";
import { Row, Col } from "reactstrap";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../app/Store";




const Breadcrumb: React.FC = () => {
  const {heading,isBackBtn,breadcrumbItems} = useSelector((state: RootState) => state.headingSlice);

  const itemLength = (breadcrumbItems || []).length || 1;

  return (
    <Row>
      <Col sm={6}>
        <div className="page-title-box">
          <h4>{heading}</h4>
          <ol className="breadcrumb m-0">
            {(breadcrumbItems || []).map((item, key) =>
              key + 1 === itemLength ? (
                <li key={key} className="breadcrumb-item active">
                  {item.title}
                </li>
              ) : (
                <li key={key} className="breadcrumb-item">
                  <Link to="#">{item.title}</Link>
                </li>
              )
            )}
          </ol>
        </div>
      </Col>
    </Row>
  );
};



export default Breadcrumb;
