import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  SectioTwo,
  Section,
  SectionWrapper,
  SectionWrapper2,
  Wrapper,
} from "./StyledDashboard";
import { Row, Col } from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import { changeHeading } from "../../features/topbarHeadingSlice";

import { AppDispatch, RootState } from "../../app/Store";
import { getAllUsers, getLoggedInUser } from "../../features/userSlice";
import { getAllBuses } from "../../features/busSlice";
import { getAllRoutes } from "../../features/routeSlice";
import Miniwidget from "../../components/Dashboard/Miniwidget/Miniwidget";
import ExampleGraph from "../../components/Dashboard/Graph/DashboardGraph";
import MonthlyEarnings from "../../components/Dashboard/Graph/Monthly-earnings";

const reports = [
  {
    title: "Buses",
    iconClass: "cube-outline",
    total: "1,587",
    average: "+11%",
    badgecolor: "#bf9000",
  },
  {
    title: "Revenue",
    iconClass: "buffer",
    total: "$46,782",
    average: "-29%",
    badgecolor: "#2c5773",
  },
  {
    title: "Staff",
    iconClass: "tag-text-outline",
    total: "150",
    average: "0%",
    badgecolor: "#bf9000",
  },
  {
    title: "Ticket Sold",
    iconClass: "briefcase-check",
    total: "1890",
    average: "+89%",
    badgecolor: "#2c5773",
  },
];

const Dashboard: React.FC = () => {
  document.title = "Dashboard | KSM - Admin Dashboard.";

  const dispatch: AppDispatch = useDispatch();
  const buses: [] = useSelector((state: RootState) => state.dashBoard.busData);
  const routes: [] = useSelector(
    (state: RootState) => state.dashBoard.routeData
  );
  const users: [] = useSelector(
    (state: RootState) => state.dashBoard.usersData
  );
  const services: [] = useSelector(
    (state: RootState) => state.dashBoard.servicesData
  );

  console.log(services);

  useEffect(() => {
    dispatch(
      changeHeading({
        heading: "Dashboard",
        isBackBtn: false,
        breadcrumbItems: [
          { title: "Ksm", link: "#" },
          { title: "Dashboard", link: "#" },
        ],
      })
    );
  }, []);

  return (
    <Wrapper>
      <Miniwidget reports={reports} />
      <Row>
        <Col xl="8">
          {/* Monthly Earnings */}
          <ExampleGraph />
        </Col>

        <Col xl="4">
          {/* Email sent */}
          <MonthlyEarnings />
        </Col>
      </Row>
      {/* <h5> i am Dashboard Page!!</h5> */}
    </Wrapper>
  );
};

export default Dashboard;
