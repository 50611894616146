import { FC } from "react";
import { Navigate, Routes, Route } from "react-router-dom";

import * as localStorageActionType from "../localStorage/ActionTypes";
import { getLocalStorage } from "../localStorage/GetLocalStorage";
import Dashboard from "../containers/Dashboard/Dashboard";
import Login from "../containers/Auth/Login/Login";
import ServicesList from "../containers/Services/ServicesList/ServicesList";
import StopsList from "../containers/Schedules/StopsList/StopsList";
import RouteList from "../containers/Schedules/RouteList/RouteList";
import Buses from "../containers/Schedules/Buses/Buses";
import ServiceDetail from "../containers/Services/ServiceDetail/ServiceDetail";
import Profile from "../containers/Profile/Profile";
import BusDetails from "../containers/Schedules/Buses/BusDetails/BusDetails";
import AddService from "../containers/Services/AddService/AddService";
import UserList from "../containers/Users/UsersList/UserList";
import Register from "../containers/Auth/Register/Register";
import AddUser from "../containers/Users/AddUser/AddUser";
import UpdateService from "../containers/Services/UpdateService/UpdateService";
import Page404 from "../containers/NotFound/Page404";
import UserRoleList from "../containers/Users/UserRoleList/UserRoleList";
import AddBus from "../containers/Schedules/Buses/AddBus/AddBus";
import AddUserRole from "../containers/Users/AddUser/AddUserRole";
import AddRoute from "../containers/Schedules/RouteList/AddRoute/AddRoute";
import AddServiceStop from "../containers/Services/AddStop/AddServiceStop";
import UpdateServiceStop from "../containers/Services/UpdateStop/UpdateServiceStop";
import AddStop from "../containers/Schedules/StopsList/AddStop/AddStop";
import UpdateUserRole from "../containers/Users/Update User/UpdateUserRole";
import UserRoleDetails from "../containers/Users/UserDetails/UserRoleDetails";
import TripList from "../containers/Trip/TripList";

import ViewRoute from "../containers/Schedules/RouteList/ViewRoute/ViewRoute";
import UpdateRoute from "../containers/Schedules/RouteList/UpdateRoute/UpdateRoute";
import UpdateBus from "../containers/Schedules/Buses/UpdateBus/UpdateBus";
import ViewStop from "../containers/Schedules/StopsList/ViewStop/ViewStop";
import UpdateStop from "../containers/Schedules/StopsList/UpdateStop/UpdateStop";
import DriverList from "../containers/EmployeeList/Driver/DriverList";
import StaffList from "../containers/EmployeeList/Staff/StaffList";
import CleanerList from "../containers/EmployeeList/Cleaner/CleanerList";
import ComplianceList from "../containers/Compliance/ComplianceList";
import AddDriver from "../containers/EmployeeList/Driver/AddDriver";
import AddCleaner from "../containers/EmployeeList/Cleaner/AddCleaner";
import Construction from "../containers/NotFound/Construction";
import ViewUser from "../containers/Users/UserDetails/UserDetails";
import UpdateUser from "../containers/Users/Update User/UpdateUser";
import AddTrip from "../containers/Trip/AddTrip/AddTrip";
import UpdateTrip from "../containers/Trip/UpdateTrip/UpdateTrip";
import ViewTrip from "../containers/Trip/ViewTrip/ViewTrip";
import UpdateDriver from "../containers/EmployeeList/Driver/UpdateDriver";
import ViewDriver from "../containers/EmployeeList/Driver/ViewDriver";
import ViewCleaner from "../containers/EmployeeList/Cleaner/ViewCleaner";
import UpdateCleaner from "../containers/EmployeeList/Cleaner/UpdateCleaner";



// import of views

let accessToken = getLocalStorage(localStorageActionType.GET_ACCESS_TOKEN);
// console.log(accessToken)

let rootView = accessToken ? <Dashboard /> : <Login />;

//testing
// let rootView =  <Dashboard /> ;

const routeToBusDetail = (id:number | null) => {
  if (id === null) {
      return "/buses/detail/:busId"
  } else {
      return `/buses/detail/${id}`
  }
}

const routeToUpdateBus = (id:number | null) => {
  if (id === null) {
      return "/buses/update/:busId"
  } else {
      return `/buses/update/${id}`
  }
}

const routeToServiceDetail = (id:any | null) => {
  if (id === null) {
      return "/services/:id"
  } else {
      return `/services/${id}`
  }
}
const routeToAddServiceStop = (id:any | null) => {
  if (id === null) {
      return "/services/add-stop/:id"
  } else {
      return `/services/add-stop/${id}`
  }
}
const routeToUpdateServiceStop = (id:any | null) => {
  if (id === null) {
      return "/services/update-stop/:id"
  } else {
      return `/services/update-stop/${id}`
  }
}

const routeToUpdateService = (id:any | null) => {
  if (id === null) {
      return "/services/update/:id"
  } else {
      return `/services/update/${id}`
  }
}
const routeToViewRoute = (id:any | null) => {
  if (id === null) {
      return "/route/view/:id"
  } else {
      return `/route/view/${id}`
  }
}
const routeToUpdateRoute = (id:any | null) => {
  if (id === null) {
      return "/route/update/:id"
  } else {
      return `/route/update/${id}`
  }
}

const routeToViewStop = (id:any | null) => {
  if (id === null) {
      return "/stops/view/:id"
  } else {
      return `/stops/view/${id}`
  }
}
const routeToUpdateStop = (id:any | null) => {
  if (id === null) {
      return "/stops/update/:id"
  } else {
      return `/stops/update/${id}`
  }
}
const routeToUpdateUserRole = (id:any | null) => {
  if (id === null) {
      return "/user-role/update/:id"
  } else {
      return `/user-role/update/${id}`
  }
}
const routeToViewUserRole = (id:any | null) => {
  if (id === null) {
      return "/user-role/view/:id"
  } else {
      return `/user-role/view/${id}`
  }
}

const routeToUpdateUser = (id:any | null) => {
  if (id === null) {
      return "/users/update/:id"
  } else {
      return `/users/update/${id}`
  }
}
const routeToViewUser = (id:any | null) => {
  if (id === null) {
      return "/user/view/:id"
  } else {
      return `/user/view/${id}`
  }
}
const routeToUpdateTrip = (id:any | null) => {
  if (id === null) {
      return "/trip/update/:id"
  } else {
      return `/trip/update/${id}`
  }
}
const routeToViewTrip = (id:any | null) => {
  if (id === null) {
      return "/trip/view/:id"
  } else {
      return `/trip/view/${id}`
  }
}

const routeToUpdateDriver = (id:any | null) => {
  if (id === null) {
      return "/driver/update/:id"
  } else {
      return `/driver/update/${id}`
  }
}
const routeToViewDriver = (id:any | null) => {
  if (id === null) {
      return "/driver/view/:id"
  } else {
      return `/driver/view/${id}`
  }
}

const routeToUpdateStaff = (id:any | null) => {
  if (id === null) {
      return "/staff/update/:id"
  } else {
      return `/staff/update/${id}`
  }
}
const routeToViewStaff = (id:any | null) => {
  if (id === null) {
      return "/staff/view/:id"
  } else {
      return `/staff/view/${id}`
  }
}

const routeToUpdateCleaner = (id:any | null) => {
  if (id === null) {
      return "/cleaner/update/:id"
  } else {
      return `/cleaner/update/${id}`
  }
}
const routeToViewCleaner = (id:any | null) => {
  if (id === null) {
      return "/cleaner/view/:id"
  } else {
      return `/cleaner/view/${id}`
  }
}

export const routerPath = {
  rootPath: "/",
  dashboard:"/",
  login:"/account/login",
  register:"/account/register",
  profile:"/account/profile",

  users:"/users",
  addUser:"/users/add",
  update_user:routeToUpdateUser,
  view_user:routeToViewUser,

  user_role:"/user-role",
  add_user_role:"/user-role/add",
  update_user_role:routeToUpdateUserRole,
  view_user_role:routeToViewUserRole,

  servicesList:"/services",
  addServiceStop:routeToAddServiceStop,
  updateServiceStop:routeToUpdateServiceStop,
  serviceDetail:routeToServiceDetail,
  updateService:routeToUpdateService,
  addService:"/services/add",

  stopList:"/stops",
  addStop:"/stops/add",
  view_stop:routeToViewStop,
  update_stop:routeToUpdateStop,

  route:"/route",
  view_route:routeToViewRoute,
  update_route:routeToUpdateRoute,
  add_route:"/route/add",

  buses:"/buses",
  add_bus:"/buses/add",
  busDetail:routeToBusDetail,
  updateBus:routeToUpdateBus,

  driver:"/driver",
  add_driver:"/driver/add",
  driverDetail:routeToViewDriver,
  updateDriver:routeToUpdateDriver,

  staff:"/staff",

  cleaner:"/cleaner",
  add_cleaner:"/cleaner/add",
  cleanerDetail:routeToViewCleaner,
  updateCleaner:routeToUpdateCleaner,

  trip:"/trip",
  addTrip:"/trip/add",
  tripDetail:routeToViewTrip,
  updateTrip:routeToUpdateTrip,

  compliance:"/compliance",


  construction:"/construction"
};

const AppRoute: FC = () => {

  // add accessToken when you implement login api for now we add !accesToken
  return accessToken ? (
    <Routes>
      <Route path={routerPath.rootPath} element={rootView} />
      <Route path={routerPath.servicesList} element={<ServicesList/>}/>
      <Route path={routerPath.addService} element={<AddService/>}/>
      <Route path={routerPath.addServiceStop(null)} element={<AddServiceStop/>}/>
      <Route path={routerPath.updateServiceStop(null)} element={<UpdateServiceStop/>}/>

      <Route path={routerPath.serviceDetail(null)} element={<ServiceDetail/>}/>
      <Route path={routerPath.updateService(null)} element={<UpdateService/>}/>
      
      <Route path={routerPath.stopList} element={<StopsList/>}/>
      <Route path={routerPath.addStop} element={<AddStop/>}/>
      <Route path={routerPath.view_stop(null)} element={<ViewStop/>}/>
      <Route path={routerPath.update_stop(null)} element={<UpdateStop/>}/>



      <Route path={routerPath.route} element={<RouteList/>}/>
      <Route path={routerPath.add_route} element={<AddRoute/>}/>
      <Route path={routerPath.view_route(null)} element={<ViewRoute/>}/>
      <Route path={routerPath.update_route(null)} element={<UpdateRoute/>}/>


      <Route path={routerPath.buses} element={<Buses/>}/>
      <Route path={routerPath.add_bus} element={<AddBus/>}/>
      <Route path={routerPath.busDetail(null)} element={<BusDetails/>}/>
      <Route path={routerPath.updateBus(null)} element={<UpdateBus/>}/>

      <Route path={routerPath.trip} element={<TripList/>}/>
      <Route path={routerPath.addTrip} element={<AddTrip/>}/>
      <Route path={routerPath.updateTrip(null)} element={<UpdateTrip/>}/>
      <Route path={routerPath.tripDetail(null)} element={<ViewTrip/>}/>



      <Route path={routerPath.compliance} element={<ComplianceList/>}/>


      <Route path={routerPath.driver} element={<DriverList/>}/>
      <Route path={routerPath.add_driver} element={<AddDriver/>}/>
      <Route path={routerPath.updateDriver(null)} element={<UpdateDriver/>}/>
      <Route path={routerPath.driverDetail(null)} element={<ViewDriver/>}/>



      <Route path={routerPath.staff} element={<StaffList/>}/>

      <Route path={routerPath.cleaner} element={<CleanerList/>}/>
      <Route path={routerPath.add_cleaner} element={<AddCleaner/>}/>
      <Route path={routerPath.cleanerDetail(null)} element={<ViewCleaner/>}/>
      <Route path={routerPath.updateCleaner(null)} element={<UpdateCleaner/>}/>


      <Route path={routerPath.profile} element={<Profile/>}/>

      <Route path={routerPath.users} element={<UserList/>}/>
      <Route path={routerPath.view_user(null)} element={<ViewUser/>}/>
      <Route path={routerPath.update_user(null)} element={<UpdateUser/>}/>


      <Route path={routerPath.user_role} element={<UserRoleList/>}/>
      <Route path={routerPath.add_user_role} element={<AddUserRole/>}/>
      <Route path={routerPath.update_user_role(null)} element={<UpdateUserRole/>}/>
      <Route path={routerPath.view_user_role(null)} element={<UserRoleDetails/>}/>


      <Route path={routerPath.addUser} element={<AddUser/>}/>

      <Route path={"*"} element={<Page404/>} />
      <Route path={routerPath.construction} element={<Construction/>} />

    

      <Route path={routerPath.login} element={<Navigate to={routerPath.rootPath} />} />
      {/* <Route path={routerPath.login} element={<Login/>} /> */} 
      //testing     


    </Routes>
  ) : (
    <Routes>
      <Route path={routerPath.login} element={rootView} />
      {/* <Route path={routerPath.register} element={<Register/>}/> */}
      
      <Route path={"*"} element={<Navigate to={routerPath.login} />} />
    </Routes>
  );
};

export default AppRoute;
