import React, { useEffect } from "react";
import { Wrapper } from "../StopsList/StyledStopList";

import { changeHeading } from "../../../features/topbarHeadingSlice";

import { AppDispatch, RootState } from "../../../app/Store";
import { useDispatch, useSelector } from "react-redux";
import { getAllRoutes, resetRouteState } from "../../../features/routeSlice";
import RouteTable from "../../Tables/Route/RouteTable";

const RouteList: React.FC = () => {
  document.title = "Routes | KSM - Admin Dashboard.";

  const dispatch: AppDispatch = useDispatch();
  const { allRoutes } = useSelector((state: RootState) => state.route);

 

  useEffect(() => {
    dispatch(
      changeHeading({
        heading: "Routes",
        isBackBtn: false,
        breadcrumbItems: [
          { title: "Ksm", link: "#" },
          { title: "Routes", link: "#" },
        ],
      })
    );

    dispatch(getAllRoutes());
    dispatch(resetRouteState());
  }, []);
  const Dtcolumns = [
    {
      label: "Route Id",
      field: "routeId",
      sort: "asc",
      width: 150,
    },
    {
      label: "Origin",
      field: "origin",
      sort: "asc",
      width: 150,
    },
    {
      label: "Status",
      field: "status",
      sort: "asc",
      width: 150,
    },
    {
      label: "Destination",
      field: "destination",
      sort: "asc",
      width: 150,
    },
    
  ];

  return <Wrapper>
    <RouteTable columns={Dtcolumns} rows={allRoutes}/>

  </Wrapper>;
};

export default RouteList;
