// Import necessary React components
import React, { useState } from "react";
import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import { useNavigate, useParams } from "react-router-dom";

// Import styles if needed
import "../datatables.scss";
import { routerPath } from "../../../routes/routes";
import { AppDispatch } from "../../../app/Store";
import { useDispatch } from "react-redux";
import { transformTimeWithAMPM } from "../../../utility/Utility";
import { nanoid } from "@reduxjs/toolkit";
import { PiEyeBold, PiNotePencilBold } from "react-icons/pi";
import { FaRegTrashAlt } from "react-icons/fa";
import { deleteStopHandler } from "../../../features/stopSlice";
import ActionModal from "../../../components/Modal/ActionModal/ActionModal";

// Import necessary interfaces
interface RowData {
  id:any;
  stop_name: string;
  stop_time: string;
  next_stop_audio: string;
  current_stop_audio:string;
}

interface ColumnData {
  label: any;
  field: string;
  sort?: string;
  width?: number;
}

interface TableProp {
  columns: ColumnData[];
  rows: RowData[] | [];
}
interface tempStateData {
  stopName: string;
  index: number | null;
}

// Define the StopTable component
const MasterStopTable: React.FC<TableProp> = ({ columns, rows }) => {
  // Initialize the navigate function from react-router-dom
  const params = useParams();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  

  // Add a new column for the "View" button and audio player
  const newColumns = [
    ...columns,
    {
      label: "Stop Time",
      field: "stop_time",
      sort: "asc",
      width: 150,
    },
    {
      label: "Current Announcement",
      field: "current_stop_audio",
      width: 150,
    },
    {
        label: "Next Announcement",
        field: "next_stop_audio",
        width: 150,
      },
    {
      label: <div className="text-center">Actions</div>,
      field: "actions",
      width: 200,
    },
  ];



  const handleDeleteModal = (id: any) => {
    setOpenDeleteModel(true);
    setSelectedId(id);
    console.log(id);
  };
  const confirmDelete = () => {
    setOpenDeleteModel(false);
    dispatch(deleteStopHandler(selectedId));
  };
 

  // Configure data for MDBDataTable with a custom "View" button and audio player
  const data = {
    columns: newColumns,
    rows: rows
      ? rows.map((rowData, index) => ({
          ...rowData,
          stop_time: (
            <div>
              {rowData.stop_time !== null
                ? transformTimeWithAMPM(rowData?.stop_time)
                : "null"}
            </div>
          ),
          current_stop_audio: (
            <div>
              <audio key={nanoid(4)} controls>
                <source src={rowData.current_stop_audio} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            </div>
          ),
          next_stop_audio: (
            <div>
              <audio key={nanoid(4)} controls>
                <source src={rowData.next_stop_audio} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            </div>
          ),
          actions: (
            <div className="d-flex flex-column gap-1 justify-content-center align-items-center">
              <Button
                type="button"
                color="primary"
                className="waves-effect waves-light"
                onClick={() => navigate(routerPath.view_stop(rowData.id))}
              >
            <PiEyeBold style={{ fontSize: "18px" }}/>
                
              </Button>
              <Button
                type="button"
                color="info"
                className="waves-effect waves-light"
                onClick={() => navigate(routerPath.update_stop(rowData.id))}
              >
            <PiNotePencilBold style={{ fontSize: "18px" }}/>
                
              </Button>
              <Button
                type="button"
                color="danger"
                className="waves-effect waves-light"
                onClick={() => handleDeleteModal(rowData.id)}
              >
            <FaRegTrashAlt style={{ fontSize: "18px" }}/>
                
              </Button>
            </div>
          ),
        }))
      : [],
  };

  // Render the component structure
  return (
    <React.Fragment>
      <ActionModal
        openModal={openDeleteModel}
        setOpenModal={setOpenDeleteModel}
        confirm={confirmDelete}
        title={"Delete"}
        content={"Stop"}
      />

      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="d-flex justify-content-between">
                <span>Master Stops List</span>
                <Button
                  type="button"
                  color="primary"
                  className="waves-effect waves-light"
                  onClick={() => navigate(routerPath.addStop)}
                >
                  Add Stop
                </Button>{" "}
              </CardTitle>
              <MDBDataTable responsive bordered noBottomColumns data={data} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

// Export the StopTable component
export default MasterStopTable;
