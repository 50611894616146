// Import necessary React and Redux dependencies
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/Store";
import { changeHeading } from "../../../features/topbarHeadingSlice";
import { addNewUser, getAllUsersRoles } from "../../../features/userSlice";
import { useNavigate } from "react-router-dom";
import { routerPath } from "../../../routes/routes";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

interface SelectProp {
  role_name: string;
}
// Define the AddUser component
const AddUser: React.FC = () => {
  document.title = "Add User | Ksm Admin Dashboard.";

  // Initialize useDispatch and useNavigate hooks
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { allUserRole, isSuccess } = useSelector(
    (state: RootState) => state.user
  );
  console.log(allUserRole);

  // useEffect to dispatch the changeHeading action when the component mounts
  useEffect(() => {
    dispatch(
      changeHeading({
        heading: "Add User",
        isBackBtn: true,
        breadcrumbItems: [
          { title: "Ksm", link: "#" },
          { title: "Users", link: "#" },
          { title: "Add User", link: "#" },
        ],
      })
    );
    dispatch(getAllUsersRoles());
  }, []);
  useEffect(() => {
    if (isSuccess === true) {
      navigate(routerPath.users);
    }
  }, [isSuccess]);
  // Function to handle form submission
  const handleSubmit = (event: any, errors: any, values: any) => {
    // 'values' object contains the form field values
    if (errors.length === 0) {
      // Dispatch the addNewUser action with the form values
      dispatch(addNewUser(values));
      console.log(values);

      // Example: Navigate to a different page after successful form submission
      // navigate(routerPath.users);
    }
  };

  // Return the JSX structure for the AddUser component
  return (
    <>
      <Card>
        <CardBody>
          <h4 className="card-title">Add User</h4>
          <AvForm className="needs-validation" onSubmit={handleSubmit}>
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="validationCustom05">Username</Label>
                  <AvField
                    name="username"
                    placeholder="Username"
                    type="text"
                    errorMessage=" Please provide a valid username."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom05"
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="validationCustom04">Email</Label>
                  <AvField
                    name="email"
                    placeholder="Email"
                    type="email"
                    errorMessage="Please provide a valid Email."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom04"
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="validationCustom01">First name</Label>
                  <AvField
                    name="firstname"
                    placeholder="First name"
                    type="text"
                    errorMessage="Enter First Name"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom01"
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="validationCustom02">Last name</Label>
                  <AvField
                    name="lastname"
                    placeholder="Last name"
                    type="text"
                    errorMessage="Enter Last name"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom02"
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="id">Employee Id</Label>
                  <AvField
                    name="employeeId"
                    placeholder="Employee Id"
                    type="text"
                    errorMessage=" Please enter employee id."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="id"
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="validationCustom03">Designation</Label>
                  <AvField
                    name="designation"
                    placeholder="designation"
                    type="text"
                    errorMessage=" Please provide a valid designation."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom03"
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="validationCusto3">Supervisor</Label>
                  <AvField
                    name="supervisor"
                    placeholder="supervisor"
                    type="text"
                    errorMessage=" Please provide a valid supervisor."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCusto3"
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="userrole">Role</Label>
                  <AvField
                    name="role"
                    type="select"
                    errorMessage="Please select user role."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="userrole"
                  >
                    <option value="">Select User Role</option>
                    {allUserRole.map((useRoleObj: SelectProp) => (
                      <option
                        key={useRoleObj.role_name}
                        value={useRoleObj.role_name}
                      >
                        {useRoleObj.role_name}
                      </option>
                    ))}
                  </AvField>
                </div>
              </Col>

              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="password">Password</Label>
                  <AvField
                    name="password"
                    placeholder="Password"
                    type="password"
                    errorMessage=" Please enter password."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="password"
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="password1">Confirm Password</Label>
                  <AvField
                    name="confirm_password"
                    placeholder="Confirm Password"
                    type="password"
                    errorMessage=" Please enter confirm password."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="password1"
                  />
                </div>
              </Col>
            </Row>

            <div className="d-flex justify-content-end">
              <Button color="primary" type="submit">
                Submit
              </Button>
            </div>
          </AvForm>
        </CardBody>
      </Card>
    </>
  );
};

// Export the AddUser component
export default AddUser;
