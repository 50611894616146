import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../app/Store";
import { changeHeading } from "../../../../features/topbarHeadingSlice";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { routerPath } from "../../../../routes/routes";
import { audioUploader, secondAudioUploader } from "../../../../features/uploaderSlice";
import { addNewStop, getStopDetail } from "../../../../features/stopSlice";
import { nanoid } from "@reduxjs/toolkit";


interface data {
  stop_name:any, stop_time:any, current_stop_audio:any, next_stop_audio:any
}
const ViewStop: React.FC = () => {
  document.title = "View Stop | KSM - Admin Dashboard.";
  const params= useParams();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const {stop}=useSelector((state:RootState)=>state.stops);
  const [formData, setFormData] = useState<data>({
    stop_name: "",
    stop_time: "",
    current_stop_audio: "",
    next_stop_audio: "",
  });
  console.log(stop)
  useEffect(() => {
    dispatch(
      changeHeading({
        heading: "View Stop",
        isBackBtn: true,
        breadcrumbItems: [
          { title: "Ksm", link: "#" },
          { title: "Stop", link: "#" },
          { title: `Stop ${params.id}`, link: "#" },
        ],
      })
    );
    dispatch(getStopDetail(params.id));
  }, []);
  useEffect(()=>{
    if (Object.keys(stop).length !== 0) {
      // The object is empty
      const {   stop_name, stop_time, current_stop_audio, next_stop_audio} = stop;
    setFormData({
      stop_name, stop_time, current_stop_audio, next_stop_audio
    });
    }
    
  },[stop])
 

  

  

  return (
    <div className="mb-2">
      <Card>
        <CardBody>
          <h4 className="card-title">Add Stop</h4>
          <AvForm className="needs-validation" >
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="routeid">Stop Name</Label>
                  <AvField
                   disabled
                    name="stopName"
                    placeholder="Stop Name"
                    type="text"
                    errorMessage="Please enter stop name."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="routeid"
                    value={formData.stop_name}
                  />
                </div>
              </Col>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="time">Stop Time</Label>
                  <AvField
                   disabled
                    name="stopTime"
                    placeholder="Stop Time"
                    type="time"
                    errorMessage="Please provide Stop Time."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="time"
                    value={formData.stop_time}

                  />
                </div>
              </Col>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="currentAudio">Current Announcement</Label>
                  <div>
              <audio key={nanoid(4)} controls>
                <source src={formData.current_stop_audio} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            </div>
                </div>
              </Col>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="nextAudio">Next Announcement</Label>
                  <div>
              <audio key={nanoid(4)} controls>
                <source src={formData.next_stop_audio} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            </div>
                </div>
              </Col>
            </Row>

            
          </AvForm>
        </CardBody>
      </Card>
      <br />
      <br />
    </div>
  );
};

export default ViewStop;
