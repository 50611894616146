import React from 'react';
import { Button, Table } from 'reactstrap'; // Assuming you're using Reactstrap

interface Stop {
  index: number;
  stop_name: string;
  stop_time: string;
  current_stop_audio: string;
  next_stop_audio: string;
}

interface Props {
  selectedStops: Stop[];
  handleRemoveStop: (index: number) => void;
}

const SimpleStopsTable: React.FC<Props> = ({ selectedStops, handleRemoveStop }) => {
  return (
    <div>
      {selectedStops.length > 0 && (
        <div>
          <h5 style={{textAlign:"left"}}>Selected Stops:</h5>
          <Table>
            <thead>
              <tr>
                <th>#</th>
                <th>Stop Name</th>
                <th>Stop Time</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {selectedStops.map((stop, index) => (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{stop.stop_name}</td>
                  <td>{stop.stop_time}</td>
                  <td>
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() => handleRemoveStop(index)}
                    >
                      Remove
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
};

export default SimpleStopsTable;
