import React, { FC } from "react";
import Modal from "../Modal";
import {
  ModalAction,
  ModalImg,
  ModalMessege,
  ModalWrapper,
} from "./StyleDeleteModal";
import warning from "../../../assets/warningSign.png";
import { Button } from "reactstrap";

interface ModalProp {
  openModal: boolean;
  setOpenModal: any;
  confirm: any;
  title: string;
  content: string;
}

const ActionModal: FC<ModalProp> = ({
  openModal,
  setOpenModal,
  confirm,
  title,
  content,
}) => {
  return (
    <>
      <Modal
        isOpen={openModal}
        name={"Create delete Modal"}
        title={`${title} ${content}`}
        toggleModal={() => setOpenModal(false)}
      >
        <ModalWrapper>
          <ModalImg>
            <img src={warning} alt="warning" width={"120px"} height={"120px"} />
          </ModalImg>
          <ModalMessege>
            Are you sure, You want to {title} this {content} ?
          </ModalMessege>
          <ModalAction>
            <Button
              type="button"
              color="primary"
              className="waves-effect waves-light"
              onClick={() => setOpenModal(false)}
            >
              No
            </Button>
            <Button
              type="button"
              color="danger"
              className="waves-effect waves-light"
              onClick={confirm}
            >
              Yes
            </Button>
          </ModalAction>
        </ModalWrapper>
      </Modal>
    </>
  );
};

export default ActionModal;
