import React, { useState } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap";
import "../datatables.scss";
import { useNavigate } from "react-router-dom";
import { routerPath } from "../../../routes/routes";
import { FaRegTrashAlt } from "react-icons/fa";
import { PiEyeBold, PiNotePencilBold } from "react-icons/pi";
import { useDispatch } from "react-redux";
import { deleteUserRole } from "../../../features/userSlice";
import { AppDispatch } from "../../../app/Store";
import ActionModal from "../../../components/Modal/ActionModal/ActionModal";

interface RowData {
  id: any;
  role_name: string;
 
}

interface ColumnData {
  label: any;
  field: string;
  sort?: string;
  width?: number;
}

interface TableProp {
  columns: ColumnData[];
  rows: RowData[];
}

const UserRoleTable: React.FC<TableProp> = ({ columns, rows }) => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  const handleDeleteModal = (id: any) => {
    setOpenDeleteModel(true);
    setSelectedId(id);
    console.log(id);
  };
  const confirmDelete = () => {
    setOpenDeleteModel(false);
    dispatch(deleteUserRole(selectedId));
  };

  const newColumns = [
    ...columns,
    {
      label: <div className="text-center">Actions</div>,
      field: "actions",
      width: 150,
    },
  ];

  const data = {
    columns: newColumns, // Use the existing columns without the checkbox column
    rows: rows.map((rowData) => ({
      ...rowData,
      actions: (
        <div className="d-flex justify-content-center gap-3 align-items-center ">
          <Button
            type="button"
            color="primary"
            className="waves-effect waves-light"
            onClick={() => navigate(routerPath.view_user_role(rowData.id))}
          >
            <PiEyeBold style={{ fontSize: "18px" }}/>
            
          </Button>
          <Button
            type="button"
            color="info"
            className="waves-effect waves-light"
            onClick={() =>navigate(routerPath.update_user_role(rowData.id))}
          >
            <PiNotePencilBold style={{ fontSize: "18px" }}/>
            
          </Button>

          <Button
            type="button"
            color="danger"
            className="waves-effect waves-light"
            onClick={() => handleDeleteModal(rowData.id)}
          >
            <FaRegTrashAlt style={{ fontSize: "18px" }}/>
            
          </Button>
        </div>
      ),
    })),
  };

  return (
    <React.Fragment>
      <ActionModal
        openModal={openDeleteModel}
        setOpenModal={setOpenDeleteModel}
        confirm={confirmDelete}
        title={"Delete"}
        content={"Role"}
      />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="d-flex justify-content-end">
                <Button
                  type="button"
                  color="primary"
                  className="waves-effect waves-light"
                  onClick={() => navigate(routerPath.add_user_role)}
                >
                  Add User Role
                </Button>{" "}
              </CardTitle>
              <MDBDataTable responsive bordered noBottomColumns data={data} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UserRoleTable;
