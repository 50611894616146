import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/Store";
import { changeHeading } from "../../../features/topbarHeadingSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { routerPath } from "../../../routes/routes";
import { audioUploader, setAudioUrl } from "../../../features/uploaderSlice";
import { addNewStop, editStop } from "../../../features/serviceSlice";
import { nanoid } from "@reduxjs/toolkit";

interface stopData {
  stopName: string;
  time: string;
  audio: string;
  index: any;
}

const UpdateServiceStop: React.FC = () => {
  document.title = "Update Stop | KSM - Admin Dashboard.";

  const params = useParams();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [stopName, setStopName] = useState("");
  const [stopTime, setStopTime] = useState("");
  // Access the rows data from location state if it exists, otherwise use an empty array
  const data: stopData = location.state;
  console.log(data);

  const { isSuccess } = useSelector((state: RootState) => state.services);
  const  audioUrl:any  = useSelector((state: RootState) => state.uploader.audioUrl);
  console.log(audioUrl)

  useEffect(() => {
    dispatch(
      changeHeading({
        heading: "Update Stop",
        isBackBtn: true,
        breadcrumbItems: [
          { title: "Ksm", link: "#" },
          { title: "Services", link: "#" },
          { title: `${params.id}`, link: "#" },
          { title: "Update Stop", link: "#" },
        ],
      })
    );
    setStopName(data.stopName);
    setStopTime(data.time);
    dispatch(
      setAudioUrl({
        audioUrl: data.audio,
      })
    );
  }, []);

  useEffect(() => {
    if (isSuccess === true) {
      navigate(routerPath.serviceDetail(params.id));
    }
  }, [isSuccess]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFiles = Array.from(e.target.files);
      // Automatically trigger file upload when files are selected
      handleFileUpload(selectedFiles);
    }
  };

  const handleFileUpload = async (files: File[]) => {
    dispatch(
      audioUploader({
        files: files,
      })
    );
  };

  const handleSubmit = (event: any, errors: any, values: any) => {
    if (errors.length === 0) {
      // Structure data for specific fields
      const formattedData = {
        service_id: params.id,
        stopName: values.stopName,
        stopTime: values.stopTime,
        audio: audioUrl,
        index: data?.index
      };
      console.log(formattedData);
      dispatch(editStop(formattedData))
    }
  };

  return (
    <div className="mb-2">
      <Card>
        <CardBody>
          <h4 className="card-title">Update Stop</h4>
          <AvForm className="needs-validation" onSubmit={handleSubmit}>
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="routeid">Stop Name</Label>
                  <AvField
                    name="stopName"
                    placeholder="Stop Name"
                    value={stopName}
                    type="text"
                    errorMessage="Please enter stop name."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="routeid"
                  />
                </div>
              </Col>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="time">Stop Time</Label>
                  <AvField
                    name="stopTime"
                    placeholder="Stop Time"
                    type="time"
                    value={stopTime}
                    errorMessage="Please provide Stop Time."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="time"
                  />
                </div>
              </Col>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="audio">Upload Another Announcement</Label>
                  <AvField
                    name="audio"
                    placeholder="Upload Announcement"
                    type="file"
                    accept="audio/*"
                    errorMessage="Please upload audio."
                    className="form-control"
                    validate={{ required: { value: false } }}
                    id="audio"
                    onChange={handleFileChange} // Handle file selection
                  />
                </div>
              </Col>
              <Col md="12">
                <div className="mb-3">
                <audio key={nanoid(4)} controls>
                <source src={audioUrl} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
                </div>
              </Col>
            </Row>

            <div className="d-flex justify-content-end">
              <Button color="primary" type="submit">
                Submit
              </Button>
            </div>
          </AvForm>
        </CardBody>
      </Card>
      <br />
      <br />
    </div>
  );
};

export default UpdateServiceStop;
