import styled from "styled-components";
// import bg from "../../assets/ksmbgnew.jpg";

export const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 80vh;
`;

export const ListSection = styled.div`
  margin-top: 20px;
  padding: 15px;
  background-color: #ffffff;

  border-radius: 10px;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  Button {
    border-radius: 10px;
  }
`;

export const SectionTitle = styled.div`
  color: #000000;
  font-size: 18px;
  font-weight: 500;
`;

export const ListWrapper = styled.div`
  box-sizing: border-box;
  margin: 30px 0 0 0;
`;
export const List = styled.div`
  display: flex;
  /* gap: 70px; */
  color: #ffffff;
  justify-content: space-evenly;
  align-items: stretch;
  margin-bottom: 10px;
`;

export const Item = styled.div``;
export const UserImg = styled.img`
  width: 200px;
  height: 200px;
  object-fit: fill;
  border-radius: 5px;
  margin-bottom: 10px;
`;
export const Value = styled.div`
  color: #000000;
  margin-bottom: 20px;
  font-weight: bold;
  /* border-bottom: 1px solid #000000; */
`;
