import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface initialStateProp {
  nightMode: boolean;
}

const initialState: initialStateProp = {
  nightMode: false,
};

interface toggleMode {
  nightMode: boolean;
}

const nightModeSlice = createSlice({
  name: "night mode slice",
  initialState: initialState,
  reducers: {
    toggleNightMode: (state, action: PayloadAction<toggleMode>) => {
      return {
        ...state,
        nightMode: action.payload.nightMode,
      };
    },
  },
});

export const { toggleNightMode } = nightModeSlice.actions;
export default nightModeSlice.reducer;
