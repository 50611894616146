import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/Store";
import { changeHeading } from "../../../features/topbarHeadingSlice";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { routerPath } from "../../../routes/routes";
import { addNewRoute } from "../../../features/routeSlice";
import { getUserData } from "../../../features/DashboardSlice";
import { ImageUploader } from "../../../features/uploaderSlice";
import { addNewdriver } from "../../../features/driverSlice";

interface SelectProp {
  username: string;
  id:any;
}

const AddDriver: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { isSuccess } = useSelector((state: RootState) => state.drivers);
  const { usersData } = useSelector((state: RootState) => state.dashBoard);
  const [selectedUser, setSelectedUser] = useState<any>({});
  const {imageUrl}=useSelector((state:RootState)=>state.uploader);

  console.log(usersData);

  useEffect(() => {
    dispatch(
      changeHeading({
        heading: "Add Driver",
        isBackBtn: true,
        breadcrumbItems: [
          { title: "Ksm", link: "#" },
          { title: "Driver", link: "#" },
          { title: "Add Driver", link: "#" },
        ],
      })
    );
    dispatch(getUserData());
  }, []);

  useEffect(() => {
    if (isSuccess === true) {
      navigate(routerPath.driver);
    }
  }, [isSuccess]);

  const handleUserSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedUsername = event.target.value;
    const user = usersData.find((user: SelectProp) => user.id === selectedUsername);
    setSelectedUser(user);

  };
console.log(selectedUser)
  

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFiles = Array.from(e.target.files);
      // Automatically trigger file upload when files are selected
      handleFileUpload(selectedFiles);
    }
  };

  const handleFileUpload = async (files: File[]) => {
    dispatch(
      ImageUploader({
        files: files,
      })
    );
  };
  const handleSubmit = (event: any, errors: any, values: any) => {
    if (errors.length === 0) {
      const formattedData = {
        ...values,
        photo_url: imageUrl,
      };
    console.log(formattedData);
    dispatch(addNewdriver(formattedData));
    }
  };

  return (
    <>
      <Card>
        <CardBody>
          <h4 className="card-title">Add Driver</h4>
          <AvForm className="needs-validation" onSubmit={handleSubmit}>
            <Row>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="Username">Username</Label>
                  <AvField
                    name="user_id"
                    type="select"
                    errorMessage="Please select username."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="Username"
                    onChange={handleUserSelect}
                  >
                    <option value="">Select Username</option>
                    {usersData.map((userNameObj: SelectProp) => (
                      <option
                        key={userNameObj.username}
                        value={userNameObj.id}
                      >
                        {userNameObj.username}
                      </option>
                    ))}
                  </AvField>
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="routeid">Employee ID</Label>
                  <AvField
                    name="employee_id"
                    placeholder="Employee ID"
                    type="text"
                    errorMessage="Please enter Employee ID."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="routeid"
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="origin">Driver Name</Label>
                  <AvField
                    name="driver_name"
                    placeholder="driver name"
                    type="text"
                    errorMessage="Please provide a Driver Name."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="origin"
                  />
                </div>
              </Col>

              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="destination">ID type</Label>
                  <AvField
                    name="id_type"
                    placeholder="ID type"
                    type="text"
                    errorMessage="Please provide a valid ID type."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="destination"
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="destination2">ID number</Label>
                  <AvField
                    name="id_number"
                    placeholder="ID number"
                    type="text"
                    errorMessage="Please provide a valid ID number."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="destination2"
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="destination1">ID issued by</Label>
                  <AvField
                    name="id_issued_by"
                    placeholder="ID issued by"
                    type="text"
                    errorMessage="Please provide ID issued by."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="destination1"
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="IDissuedate">ID issue date</Label>
                  <AvField
                    name="id_issue_date"
                    placeholder="ID issue date"
                    type="date"
                    errorMessage="Please provide a ID issue date."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="IDissuedate"
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="IDexpirydate">ID expiry date</Label>
                  <AvField
                    name="id_expiry_date"
                    placeholder="ID expiry date"
                    type="date"
                    errorMessage="Please provide a ID expiry date."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="IDexpirydate"
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="PANNumber">PAN Number</Label>
                  <AvField
                    name="pan_number"
                    placeholder="PAN Number"
                    type="text"
                    errorMessage="Please provide a PAN Number."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="PANNumber"
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="PANNumber1">License Number</Label>
                  <AvField
                    name="license_number"
                    placeholder="License Number"
                    type="text"
                    errorMessage="Please provide a License Number."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="PANNumber1"
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="IDexpirydate1">License Expiry Date</Label>
                  <AvField
                    name="license_expiry_date"
                    placeholder="License Expiry Date"
                    type="date"
                    errorMessage="Please provide a License Expiry Date."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="IDexpirydate1"
                  />
                </div>
              </Col>
              
              </Row>
              <Row>
                <Col md="4">
                  <div className="mb-3">
                    <Label htmlFor="EmailAddress">Email Address</Label>
                    <AvField
                      name="email_address"
                      placeholder="Email Address"
                      type="text"
                      errorMessage="Please provide a Email Address."
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="EmailAddress"
                      value={selectedUser?.email}
                    />
                  </div>
                </Col>
                <Col md="4">
                  <div className="mb-3">
                    <Label htmlFor="ContactNumber">Contact Number</Label>
                    <AvField
                      name="contact_number"
                      placeholder="Contact Number"
                      type="number"
                      errorMessage="Please provide a Contact Number."
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="ContactNumber"
                    />
                  </div>
                </Col>
                <Col md="4">
                  <div className="mb-3">
                    <Label htmlFor="Address">Address</Label>
                    <AvField
                      name="address"
                      placeholder="Address"
                      type="textarea"
                      errorMessage="Please provide a Address."
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="Address"
                    />
                  </div>
                </Col>
                <Col md="4">
                  <div className="mb-3">
                    <Label htmlFor="city">City</Label>
                    <AvField
                      name="city"
                      placeholder="City"
                      type="text"
                      errorMessage="Please provide a City."
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="City"
                    />
                  </div>
                </Col>
                <Col md="4">
                  <div className="mb-3">
                    <Label htmlFor="State">State</Label>
                    <AvField
                      name="state"
                      placeholder="state"
                      type="text"
                      errorMessage="Please provide a State."
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="State"
                    />
                  </div>
                </Col>
                <Col md="4">
                  <div className="mb-3">
                    <Label htmlFor="Country">Country</Label>
                    <AvField
                      name="country"
                      placeholder="Country"
                      type="text"
                      errorMessage="Please provide a Country."
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="Country"
                    />
                  </div>
                </Col>
                <Col md="4">
                  <div className="mb-3">
                    <Label htmlFor="ZIP Code">ZIP Code</Label>
                    <AvField
                      name="zip_code"
                      placeholder="ZIP Code"
                      type="number"
                      errorMessage="Please provide a ZIP Code."
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="ZIPCode"
                    />
                  </div>
                </Col>
                <Col md="4">
                  <div className="mb-3">
                    <Label htmlFor="status1">Status</Label>
                    <AvField
                      name="status"
                      type="select"
                      errorMessage="Please provide a valid status."
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="status1"
                    >
                      <option value="">Select Status</option>
                      <option value="0">Active</option>
                      <option value="1">On-hold</option>
                      <option value="2">Terminated</option>
                    </AvField>
                  </div>
                </Col>
              </Row>
              <Row>
              <Col md="4">
                  <div className="mb-3">
                    <Label htmlFor="EmergencyContactName">Emergency Contact Name</Label>
                    <AvField
                      name="emergency_contact_name"
                      placeholder="Emergency Contact Name"
                      type="text"
                      errorMessage="Please provide a Emergency Contact Name."
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="EmergencyContactName"
                    />
                  </div>
                </Col>
                <Col md="4">
                  <div className="mb-3">
                    <Label htmlFor="EmergencyContactNumber">Emergency Contact Number</Label>
                    <AvField
                      name="emergency_contact_number"
                      placeholder="Emergency Contact Number"
                      type="number"
                      errorMessage="Please provide a Emergency Contact Number."
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="EmergencyContactNumber"
                    />
                  </div>
                </Col>
                <Col md="4">
                  <div className="mb-3">
                    <Label htmlFor="EmergencyContactRelation">Emergency Contact Relation</Label>
                    <AvField
                      name="emergency_contact_relation"
                      placeholder="Emergency Contact Relation"
                      type="text"
                      errorMessage="Please provide a Emergency Contact Relation."
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="EmergencyContactRelation"
                    />
                  </div>
                </Col>
                <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="currentPhoto">Photo</Label>
                  <AvField
                    name="photo_url"
                    placeholder="Upload Photo"
                    type="file"
                    accept="image/*"
                    errorMessage="Please upload photo."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="currentPhoto"
                    onChange={handleFileChange} // Handle file selection
                  />
                </div>
              </Col>
              </Row>
              {imageUrl!==null&& <div className="d-flex justify-content-around align-items-center">
                <img src={imageUrl} key={imageUrl} alt="driverImg" width={"100px"}/>
                </div>}

            <div className="d-flex justify-content-end">
              <Button color="primary" type="submit">
                Submit
              </Button>
            </div>
          </AvForm>
        </CardBody>
      </Card>
    </>
  );
};

// Export the AddRoute component
export default AddDriver;
