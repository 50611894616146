// Import necessary React and Redux dependencies
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../app/Store";
import { changeHeading } from "../../../../features/topbarHeadingSlice";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { addNewBus } from "../../../../features/busSlice";
import { routerPath } from "../../../../routes/routes";
import { nanoid } from "@reduxjs/toolkit";

interface addBusPayload {
  bus_number: string;
  bus_name: string;
  bus_make: string;
  bus_model: string;
  fitness_start_date: string;
  fitness_end_date: string;
  mother_permit_start_date: string;
  mother_permit_expiry_date: string;
  mother_permit_id_number: string;
  mother_permit_authorization_start_date: string;
  mother_permit_authorization_end_date: string;
  mother_permit_authorization_id_number: string;

  mother_road_tax_start_date: string;
  mother_road_tax_expiry_date: string;
  mother_road_tax_id_number: string;
  mother_road_tax_authorization_start_date: string;
  mother_road_tax_authorization_end_date: string;
  mother_road_tax_authorization_id_number: string;

  all_india_tourist_permit_start_date: string;
  all_india_tourist_permit_expiry_date: string;
  all_india_tourist_permit_id_number: string;
  all_india_tourist_permit_authorization_start_date: string;
  all_india_tourist_permit_authorization_end_date: string;
  all_india_tourist_permit_authorization_id_number: string;

  insurance_provider: string;
  insurance_policy_number: string;
  insurance_start_date: string;
  insurance_end_date: string;
  insurance_coverage_type: string;
  emission_policy_number: string;
  emission_start_date: string;
  emission_end_date: string;
}

// Define the AddUser component
const AddBus: React.FC = () => {
  // Initialize useDispatch and useNavigate hooks
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { isSuccess } = useSelector((state: RootState) => state.buses);

  // useEffect to dispatch the changeHeading action when the component mounts
  useEffect(() => {
    dispatch(
      changeHeading({
        heading: "Add Bus",
        isBackBtn: true,
        breadcrumbItems: [
          { title: "Ksm", link: "#" },
          { title: "Buses", link: "#" },
          { title: "Add Bus", link: "#" },
        ],
      })
    );
  }, []);

  useEffect(() => {
    if (isSuccess === true) {
      navigate(routerPath.buses);
    }
  }, [isSuccess]);

  // Function to handle form submission
  const handleSubmit = (event: any, errors: any, values: addBusPayload) => {
    // 'values' object contains the form field values
    console.log(values)
    if (errors.length === 0) {
      // Structure data for specific fields
      const formattedData = {
        bus_number: values.bus_number,
        bus_name: values.bus_name,
        bus_make: values.bus_make,
        bus_model: values.bus_model,
        fitness_start_date: values.fitness_start_date,
        fitness_end_date: values.fitness_end_date,
        mother_permit_start_date: values.mother_permit_start_date,
        mother_permit_expiry_date: values.mother_permit_expiry_date,
        mother_permit_id_number: values.mother_permit_id_number,
        mother_permit_authorization_start_date:
          values.mother_permit_authorization_start_date,
        mother_permit_authorization_end_date:
          values.mother_permit_authorization_end_date,
        mother_permit_authorization_id_number:
          values.mother_permit_authorization_id_number,
        mother_road_tax_start_date: values.mother_road_tax_start_date,
        mother_road_tax_expiry_date: values.mother_road_tax_expiry_date,
        mother_road_tax_id_number: values.mother_road_tax_id_number,
        mother_road_tax_authorization_start_date:
          values.mother_road_tax_authorization_start_date,
        mother_road_tax_authorization_end_date:
          values.mother_road_tax_authorization_end_date,
        mother_road_tax_authorization_id_number:
          values.mother_road_tax_authorization_id_number,
        all_india_tourist_permit_start_date:
          values.all_india_tourist_permit_start_date,
        all_india_tourist_permit_expiry_date:
          values.all_india_tourist_permit_expiry_date,
        all_india_tourist_permit_id_number:
          values.all_india_tourist_permit_id_number,
        all_india_tourist_permit_authorization_start_date:
          values.all_india_tourist_permit_authorization_start_date,
        all_india_tourist_permit_authorization_end_date:
          values.all_india_tourist_permit_authorization_end_date,
        all_india_tourist_permit_authorization_id_number:
          values.all_india_tourist_permit_authorization_id_number,
        insurance_provider: values.insurance_provider,
        insurance_policy_number: values.insurance_policy_number,
        insurance_start_date: values.insurance_start_date,
        insurance_end_date: values.insurance_end_date,
        insurance_coverage_type: values.insurance_coverage_type,
        emission_policy_number: values.emission_policy_number,
        emission_start_date: values.emission_start_date,
        emission_end_date: values.emission_end_date,
      };

      // Dispatch the addNewUser action with the formatted data
      dispatch(addNewBus(formattedData));
      console.log(formattedData);

      // Navigate to the desired route
      // navigate(routerPath.buses);
    }
  };

  // Return the JSX structure for the AddUser component
  return (
    <div>
      <Card>
        <CardBody>
          <h4 className="card-title">Add Bus</h4>
          <AvForm className="needs-validation" onSubmit={handleSubmit}>
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="busName">Bus Name</Label>
                  <AvField
                    name="bus_name"
                    placeholder="Bus name"
                    type="text"
                    errorMessage="Enter Bus Name."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="busName"
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="busNumber">Bus Number</Label>
                  <AvField
                    name="bus_number"
                    placeholder="Bus Number"
                    type="text"
                    errorMessage="Please provide a Bus Number."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="busNumber"
                  />
                </div>
              </Col>

              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="bus_make">Bus Make</Label>
                  <AvField
                    name="bus_make"
                    placeholder="Bus Make"
                    type="text"
                    errorMessage="Please provide a bus maker."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="busMake"
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="bus_model">Bus Model</Label>
                  <AvField
                    name="bus_model"
                    placeholder="Bus Model"
                    type="text"
                    errorMessage="Please provide bus Model."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="busModel"
                  />
                </div>
              </Col>
              
              </Row>
            <br />
            <h6 style={{ fontWeight: "bolder" }}>Fitness Details :</h6>
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="fitness_start_date1">Fitness start date</Label>
                  <AvField
                    name="fitness_start_date"
                    placeholder="fitness start date."
                    type="date"
                    errorMessage="Enter fitness start date."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="fitness_start_date"
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="fitness_end_date1">
                  Fitness end date
                  </Label>
                  <AvField
                    name="fitness_end_date"
                    placeholder="fitness end date."
                    type="date"
                    errorMessage="Enter fitness end date."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="fitness_end_date1"
                  />
                </div>
              </Col>
            </Row>
            <br />
            <h6 style={{ fontWeight: "bolder" }}>Permit Record:</h6>

            <Row>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="mother_permit_start_date1">Mother permit start date</Label>
                  <AvField
                    name="mother_permit_start_date"
                    placeholder="mother permit start date."
                    type="date"
                    errorMessage="Enter mother permit start date."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="mother_permit_start_date1"
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="mother_permit_expiry_date1">Mother permit expiry date</Label>
                  <AvField
                    name="mother_permit_expiry_date"
                    placeholder="mother permit expiry date."
                    type="date"
                    errorMessage="Enter mother permit expiry date."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="mother_permit_expiry_date1"
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="mother_permit_id_number1">Mother permit id number</Label>
                  <AvField
                    name="mother_permit_id_number"
                    placeholder="mother permit id number."
                    type="text"
                    errorMessage="Enter mother permit id number."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="mother_permit_id_number1"
                  />
                </div>
              </Col>

              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="mother_permit_authorization_start_date1">Mother permit authorization start date</Label>
                  <AvField
                    name="mother_permit_authorization_start_date"
                    placeholder="mother permit authorization start date."
                    type="date"
                    errorMessage="Enter mother permit authorization start date."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="mother_permit_authorization_start_date1"
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="mother_permit_authorization_end_date1">Mother permit authorization end date</Label>
                  <AvField
                    name="mother_permit_authorization_end_date"
                    placeholder="mother permit authorization end date."
                    type="date"
                    errorMessage="Enter mother permit authorization end date."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="mother_permit_authorization_end_date1"
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="mother_permit_authorization_id_number1">Mother permit authorization id number</Label>
                  <AvField
                    name="mother_permit_authorization_id_number"
                    placeholder="mother permit authorization id number."
                    type="text"
                    errorMessage="Enter mother permit authorization id number."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="mother_permit_authorization_id_number1"
                  />
                </div>
              </Col>

              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="all_india_tourist_permit_start_date1">All india tourist permit start date</Label>
                  <AvField
                    name="all_india_tourist_permit_start_date"
                    placeholder="all india tourist permit start date."
                    type="date"
                    errorMessage="Enter mother permit start date."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="all_india_tourist_permit_start_date1"
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="all_india_tourist_permit_expiry_date1">All india tourist permit expiry date</Label>
                  <AvField
                    name="all_india_tourist_permit_expiry_date"
                    placeholder="all india tourist permit expiry date."
                    type="date"
                    errorMessage="Enter all india tourist permit expiry date."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="all_india_tourist_permit_expiry_date1"
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="all_india_tourist_permit_id_number1">All india tourist permit id number</Label>
                  <AvField
                    name="all_india_tourist_permit_id_number"
                    placeholder="all india tourist permit id number."
                    type="text"
                    errorMessage="Enter all india tourist permit id number."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="all_india_tourist_permit_id_number1"
                  />
                </div>
              </Col>

              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="all_india_tourist_permit_authorization_start_date1">all india tourist permit authorization start date</Label>
                  <AvField
                    name="all_india_tourist_permit_authorization_start_date"
                    placeholder="all india tourist permit authorization start date."
                    type="date"
                    errorMessage="Enter all india tourist permit authorization start date."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="all_india_tourist_permit_authorization_start_date1"
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="all_india_tourist_permit_authorization_end_date1">All india tourist permit authorization end date</Label>
                  <AvField
                    name="all_india_tourist_permit_authorization_end_date"
                    placeholder="all india tourist permit authorization end date."
                    type="date"
                    errorMessage="Enter all india tourist permit authorization end date."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="all_india_tourist_permit_authorization_end_date1"
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="all_india_tourist_permit_authorization_id_number1">All india tourist permit authorization id number</Label>
                  <AvField
                    name="all_india_tourist_permit_authorization_id_number"
                    placeholder="all india tourist permit authorization id number."
                    type="text"
                    errorMessage="Enter all india tourist permit authorization id number."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="all_india_tourist_permit_authorization_id_number1"
                  />
                </div>
              </Col>
            </Row>
            <br />
            <h6 style={{ fontWeight: "bolder" }}>Tax Record :</h6>

            <Row>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="mother_road_tax_start_date1">Mother road tax start date</Label>
                  <AvField
                    name="mother_road_tax_start_date"
                    placeholder="Mother road tax start date."
                    type="date"
                    errorMessage="Enter mother road tax start date."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="mother_road_tax_start_date1"
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="mother_road_tax_expiry_date1">Mother road tax expiry date</Label>
                  <AvField
                    name="mother_road_tax_expiry_date"
                    placeholder="Mother road tax expiry date."
                    type="date"
                    errorMessage="Enter mother road tax expiry date."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="mother_road_tax_expiry_date1"
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="mother_road_tax_id_number1">Mother road tax id number</Label>
                  <AvField
                    name="mother_road_tax_id_number"
                    placeholder="mother road tax id number."
                    type="text"
                    errorMessage="Enter mother road tax id number."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="mother_road_tax_id_number1"
                  />
                </div>
              </Col>

              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="mother_road_tax_authorization_start_date1">Mother road tax authorization start date</Label>
                  <AvField
                    name="mother_road_tax_authorization_start_date"
                    placeholder="Mother road tax authorization start date."
                    type="date"
                    errorMessage="Enter mother road tax authorization start date."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="mother_road_tax_authorization_start_date1"
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="mother_road_tax_authorization_end_date1">Mother road tax authorization end date</Label>
                  <AvField
                    name="mother_road_tax_authorization_end_date"
                    placeholder="Mother road tax authorization end date."
                    type="date"
                    errorMessage="Enter mother road tax authorization end date."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="mother_road_tax_authorization_end_date1"
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="mother_road_tax_authorization_id_number1">Mother road tax id authorization number</Label>
                  <AvField
                    name="mother_road_tax_authorization_id_number"
                    placeholder="mother road tax authorization id number."
                    type="text"
                    errorMessage="Enter mother road tax authorization id number."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="mother_road_tax_authorization_id_number1"
                  />
                </div>
              </Col>
              
            </Row>
            <br />
            <h6 style={{ fontWeight: "bolder" }}>Insurance Details :</h6>

            <Row>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="insurance_provider1">Insurance provider</Label>
                  <AvField
                    name="insurance_provider"
                    placeholder="insurance provider"
                    type="text"
                    errorMessage="Enter insurance_provider."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="insurance_provider1"
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="insurance_policy_number1">Insurance policy number</Label>
                  <AvField
                    name="insurance_policy_number"
                    placeholder="insurance policy number"
                    type="text"
                    errorMessage="Enter insurance policy number."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="insurance_policy_number1"
                  />
                </div>
              </Col>

              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="insurance_start_date1">Insurance start date</Label>
                  <AvField
                    name="insurance_start_date"
                    placeholder="insurance start date"
                    type="date"
                    errorMessage="Enter insurance start date."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="insurance_start_date1"
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="insurance_end_date1">Insurance end date</Label>
                  <AvField
                    name="insurance_end_date"
                    placeholder="insurance end date"
                    type="date"
                    errorMessage="Enter insurance end date."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="insurance_end_date1"
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="insurance_coverage_type1">Insurance coverage type</Label>
                  <AvField
                    name="insurance_coverage_type"
                    placeholder="insurance coverage type"
                    type="text"
                    errorMessage="Enter insurance coverage type."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="insurance_coverage_type1"
                  />
                </div>
              </Col>
            </Row>
            <br />
            <h6 style={{ fontWeight: "bolder" }}>Emission Details :</h6>

            <Row>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="emission_policy_number1">emission policy number</Label>
                  <AvField
                    name="emission_policy_number"
                    placeholder="insurance provider"
                    type="text"
                    errorMessage="Enter emission policy number."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="emission_policy_number1"
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="emission_start_date1">Emission start date</Label>
                  <AvField
                    name="emission_start_date"
                    placeholder="emission start date"
                    type="date"
                    errorMessage="Enter emission start date."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="emission_start_date1"
                  />
                </div>
              </Col>

              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="emission_end_date1">Emission end date</Label>
                  <AvField
                    name="emission_end_date"
                    placeholder="emission end date"
                    type="date"
                    errorMessage="Enter emission end date."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="emission_end_date1"
                  />
                </div>
              </Col>
              
            </Row>

            <div className="d-flex justify-content-end">
              <Button color="primary" type="submit">
                Submit
              </Button>
            </div>
          </AvForm>
        </CardBody>
      </Card>
      <br />
    </div>
  );
};

// Export the AddUser component
export default AddBus;
