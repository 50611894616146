import styled from "styled-components";
import bg from "../../../assets/ksmbgnew.jpg";

export const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
`;

export const ListSection = styled.div`
  padding: 15px;
  /* background-color: rgba(44, 87, 115, 255); */
  background-image: url(${bg});

  border-radius: 5px;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  Button {
    border-radius: 10px;
  }
`;

export const SectionTitle = styled.div`
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
`;
export const FormWrapper = styled.div`
  padding: 20px;
`;
