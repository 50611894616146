import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as UIConstants from "../../constants/UIConstants";
import {
  StyledAppWrapper,
  LeftMenuSection,
  ContentWrapper,
  Content,
  LeftMenuWrapper,
  AuthContent,
} from "./StyledWrapper";
import TopBar from "../../components/AppTopbar/AppTopbar";
import { RootState } from "../../app/Store";
import { useSelector, useDispatch } from "react-redux";
// import Loader from "../../components/Loader/Loader";
// import NetworkErrorNotifier from "../NetworkNotification/NetworkNotification";
import HumBurger from "./Humberer";
import LeftMenuMobile from "../LeftMenu/LeftMenuMobile";
import LeftMenuDesktop from "../LeftMenu/LeftmenuDesktop";
import useDimension from "../../customHook/useDimention";
import Footer from "../../components/Footer/Footer";
import { toggleLeftMenu } from "../../features/leftMenuSlice";
import {
  Back,
  Heading,
  HeadingContent,
} from "../../components/AppTopbar/StyledAppTopBar";
import Breadcrumb from "../../components/Breadcum/Breadcum";

interface AppWrapper {
  children: any;
}

const AppWrapper: FC<AppWrapper> = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const dimention = useDimension();
  const open: boolean = useSelector(
    (state: RootState) => state.leftMenuSlice.open
  );
  const { heading, isBackBtn } = useSelector(
    (state: RootState) => state.headingSlice
  );

  //   const isLoading = useSelector(
  //     (state: RootState) => state.loaderslice.isLoading
  //   );

  let pathName = location.pathname;

  const handleLeftmenu = () => {
    dispatch(
      toggleLeftMenu({
        open: false,
      })
    );
  };

  return (
    <StyledAppWrapper>
      {/* <Loader isLoading={isLoading} /> */}
      {/* <NetworkErrorNotifier /> */}

      {pathName !== "/account/login" &&
        pathName !== "/account/register" &&
        pathName !== "/account/twoFa" &&
        pathName !== "/account/forgot-password" &&
        pathName !== "/account/reset-password" && (
          <>
            {dimention.width <= UIConstants.mobileWidth ? (
              <LeftMenuWrapper open={open}>
                <HumBurger />
                <LeftMenuMobile open={open} />
              </LeftMenuWrapper>
            ) : (
              <LeftMenuSection>
                <LeftMenuDesktop />
              </LeftMenuSection>
            )}
          </>
        )}
      <ContentWrapper onClick={handleLeftmenu}>
        {pathName !== "/account/login" &&
          pathName !== "/account/register" &&
          pathName !== "/account/twoFa" &&
          pathName !== "/account/forgot-password" &&
          pathName !== "/account/reset-password" && <TopBar />}

        {pathName !== "/account/login" &&
        pathName !== "/account/register" &&
        pathName !== "/account/twoFa" &&
        pathName !== "/account/forgot-password" &&
        pathName !== "/account/reset-password" ? (
          <Content>
            {/* <Heading>
              <HeadingContent>
                {isBackBtn && <Back onClick={() => navigate(-1)} />}
                {heading}
              </HeadingContent>
            </Heading> */}
            <Breadcrumb/>
            {children}
            {pathName !== "/account/login" &&
              pathName !== "/account/register" &&
              pathName !== "/account/twoFa" &&
              pathName !== "/account/forgot-password" &&
              pathName !== "/account/reset-password" && <Footer />}
          </Content>
        ) : (
          <AuthContent>
            {children}
            {pathName !== "/account/login" &&
              pathName !== "/account/register" &&
              pathName !== "/account/twoFa" &&
              pathName !== "/account/forgot-password" &&
              pathName !== "/account/reset-password" && <Footer />}
          </AuthContent>
        )}
      </ContentWrapper>
    </StyledAppWrapper>
  );
};

export default AppWrapper;
