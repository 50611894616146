import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as endpoints from "../networkUtility/endpoint";
import { toggleLoader } from "./loaderSlice";
import { toast } from "react-toastify";
import * as localStorageActionType from "../localStorage/ActionTypes";
import { getLocalStorage } from "../localStorage/GetLocalStorage";
import { handle500Status } from "../utility/Utility";

interface initialStateProp {
  allRoutes: any;
  route: any;
  isSuccess: boolean;
}

const initialState: initialStateProp = {
  allRoutes: [],
  route: {},
  isSuccess: false,
};

interface allRoutesProp {
  allRoutes: any;
}
interface routeProp {
  route: any;
}

interface addRoutePayload {
  routeid: string;
  origin: string;
  destination: string;
  status: any;
}
interface busRouteProp {
  isSuccess: boolean;
}
export const getAllRoutes = createAsyncThunk(
  "get all routes", // Adjusted action name for clarity
  async (payload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(endpoints.allRoutes, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });

      dispatch(toggleLoader({ isLoading: false }));

      // Check if the response status is 200
      if (response.status === 200) {
        // toast.success("Route Data Fetch Successfully!.");
        const data = await response.json();
        // console.log(data);
        dispatch(
          setAllRoutes({
            allRoutes: data,
          })
        );
        // Check the statusCode from the API response
      } else if (response.status === 500) {
        handle500Status();
      } else {
        // Handle non-200 status codes here
        toast.error("Sorry, Something went wrong!.");
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error) {
      // Handle fetch or JSON parsing errors here
      console.error(error);
      toast.error("An error occurred. Please try again."); // You can customize the error message
    } finally {
      console.log("Finally");
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const addNewRoute = createAsyncThunk(
  "add new route",
  async (payload: addRoutePayload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const formData = new FormData();
      formData.append("routeid", payload.routeid);
      formData.append("origin", payload.origin);
      formData.append("destination", payload.destination);
      formData.append("status", payload.status);

      const response = await fetch(endpoints.addRoute, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
        body: formData,
      });

      const data = await response.json();
      if (response.status === 200) {
        toast.success("Route Added Successfully!.");
        // console.log(data);
        dispatch(
          setRouteState({
            isSuccess: true,
          })
        );
        dispatch(getAllRoutes());
      } else {
        toast.success("Sorry!,Something went wrong!.");
      }

      if (data.code === 500) {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error adding new route:", error);
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const updateRoute = createAsyncThunk(
  "update route",
  async (payload: addRoutePayload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const formData = new FormData();
      formData.append("routeid", payload.routeid);
      formData.append("origin", payload.origin);
      formData.append("destination", payload.destination);
      formData.append("status", payload.status);

      const response = await fetch(`${endpoints.editRoute}${payload.routeid}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
        body: formData,
      });

      const data = await response.json();
      if (response.status === 200) {
        toast.success("Route updated Successfully!.");
        // console.log(data);
        dispatch(
          setRouteState({
            isSuccess: true,
          })
        );
        dispatch(getAllRoutes());
      } else {
        toast.success("Sorry!,Something went wrong!.");
      }

      if (data.code === 500) {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error adding new route:", error);
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const getBusDetail = createAsyncThunk(
  "get bus details",
  async (payload: any, { dispatch }) => {
    dispatch(toggleLoader({ isLoading: true }));
    await fetch(`${endpoints.busDetail}${payload}`, {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${getLocalStorage(
          localStorageActionType.GET_ACCESS_TOKEN
        )}`,
      },
    })
      .then((response) => {
        dispatch(toggleLoader({ isLoading: false }));
        return response.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(toggleLoader({ isLoading: false }));
      });
  }
);

export const deleteRouteHandler = createAsyncThunk(
  "delete route from db",
  async (payload: any, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(`${endpoints.deleteRoute}${payload}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });
      const data = await response.json();
      if (response.status === 200) {
        toast.success(data.message);
        dispatch(getAllRoutes());
        // console.log(data);
      }
    } catch (error) {
      console.error(error);
      toast.error("Sorry!, Something went wrong.");
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const getRouteDetail = createAsyncThunk(
  "get route details",
  async (payload: any, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(`${endpoints.viewRoute}${payload}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });

      const data = await response.json();
      if (response.status === 200) {
        dispatch(
          setRouteDetails({
            route: data[0],
          })
        );
        toast.success("Route Details Fetch successfully!.");
        console.log(data);
      }

      if (data.code === 404) {
        toast.error(data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

const routeSlice = createSlice({
  name: "route slice",
  initialState: initialState,
  reducers: {
    setAllRoutes: (state, action: PayloadAction<allRoutesProp>) => {
      return {
        ...state,
        allRoutes: action.payload.allRoutes,
      };
    },
    setRouteDetails: (state, action: PayloadAction<routeProp>) => {
      return {
        ...state,
        route: action.payload.route,
      };
    },
    setRouteState: (state, action: PayloadAction<busRouteProp>) => {
      return {
        ...state,
        isSuccess: action.payload.isSuccess,
      };
    },
    resetRouteState: (state) => {
      return {
        ...state,
        isSuccess: false,
      };
    },
  },
});

export const { setAllRoutes, setRouteState, resetRouteState, setRouteDetails } =
  routeSlice.actions;
export default routeSlice.reducer;
