import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as endpoints from "../networkUtility/endpoint";
import { toggleLoader } from "./loaderSlice";
import { toast } from "react-toastify";
import * as localStorageActionType from "../localStorage/ActionTypes";
import { getLocalStorage } from "../localStorage/GetLocalStorage";
import { handle500Status } from "../utility/Utility";

interface initialStateProp {
  allCompliance: any;
  compliance: any;
  isSuccess: boolean;
}

const initialState: initialStateProp = {
  allCompliance: [],
  compliance: {},
  isSuccess: false,
};

interface allComplianceProp {
  allCompliance: any;
}
interface complianceProp {
  compliance: any;
}

interface addcompliancePayload {
  complianceid: string;
  origin: string;
  destination: string;
  status: any;
}
interface buscomplianceProp {
  isSuccess: boolean;
}
export const getAllCompliance = createAsyncThunk(
  "get all compliances", // Adjusted action name for clarity
  async (payload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(endpoints.allCompliance, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });

      dispatch(toggleLoader({ isLoading: false }));

      // Check if the response status is 200
      if (response.status === 200) {
        // toast.success("compliance Data Fetch Successfully!.");
        const data = await response.json();
        // console.log(data);
        dispatch(
          setAllCompliance({
            allCompliance: data,
          })
        );
        // Check the statusCode from the API response
      } else if (response.status === 500) {
        handle500Status();
      } else {
        // Handle non-200 status codes here
        toast.error("Sorry, Something went wrong!.");
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error) {
      // Handle fetch or JSON parsing errors here
      console.error(error);
      toast.error("An error occurred. Please try again."); // You can customize the error message
    } finally {
      console.log("Finally");
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

// export const addNewcompliance = createAsyncThunk(
//   "add new compliance",
//   async (payload: addcompliancePayload, { dispatch }) => {
//     try {
//       dispatch(toggleLoader({ isLoading: true }));

//       const formData = new FormData();
//       formData.append("complianceid", payload.complianceid);
//       formData.append("origin", payload.origin);
//       formData.append("destination", payload.destination);
//       formData.append("status", payload.status);

//       const response = await fetch(endpoints.addcompliance, {
//         method: "POST",
//         headers: {
//           Authorization: `Bearer ${getLocalStorage(
//             localStorageActionType.GET_ACCESS_TOKEN
//           )}`,
//         },
//         body: formData,
//       });

//       const data = await response.json();
//       if (response.status === 200) {
//         toast.success("compliance Added Successfully!.");
//         // console.log(data);
//         dispatch(
//           setcomplianceState({
//             isSuccess: true,
//           })
//         );
//         dispatch(getAllCompliance());
//       } else {
//         toast.success("Sorry!,Something went wrong!.");
//       }

//       if (data.code === 500) {
//         toast.error(data.message);
//       }
//     } catch (error) {
//       console.error("Error adding new compliance:", error);
//     } finally {
//       dispatch(toggleLoader({ isLoading: false }));
//     }
//   }
// );

// export const updatecompliance = createAsyncThunk(
//   "update compliance",
//   async (payload: addcompliancePayload, { dispatch }) => {
//     try {
//       dispatch(toggleLoader({ isLoading: true }));

//       const formData = new FormData();
//       formData.append("complianceid", payload.complianceid);
//       formData.append("origin", payload.origin);
//       formData.append("destination", payload.destination);
//       formData.append("status", payload.status);

//       const response = await fetch(`${endpoints.editcompliance}${payload.complianceid}`, {
//         method: "POST",
//         headers: {
//           Authorization: `Bearer ${getLocalStorage(
//             localStorageActionType.GET_ACCESS_TOKEN
//           )}`,
//         },
//         body: formData,
//       });

//       const data = await response.json();
//       if (response.status === 200) {
//         toast.success("compliance updated Successfully!.");
//         // console.log(data);
//         dispatch(
//           setcomplianceState({
//             isSuccess: true,
//           })
//         );
//         dispatch(getAllCompliance());
//       } else {
//         toast.success("Sorry!,Something went wrong!.");
//       }

//       if (data.code === 500) {
//         toast.error(data.message);
//       }
//     } catch (error) {
//       console.error("Error adding new compliance:", error);
//     } finally {
//       dispatch(toggleLoader({ isLoading: false }));
//     }
//   }
// );

// export const getBusDetail = createAsyncThunk(
//   "get bus details",
//   async (payload: any, { dispatch }) => {
//     dispatch(toggleLoader({ isLoading: true }));
//     await fetch(`${endpoints.busDetail}${payload}`, {
//       method: "GET",
//       headers: {
//         "Content-type": "application/json; charset=UTF-8",
//         Authorization: `Bearer ${getLocalStorage(
//           localStorageActionType.GET_ACCESS_TOKEN
//         )}`,
//       },
//     })
//       .then((response) => {
//         dispatch(toggleLoader({ isLoading: false }));
//         return response.json();
//       })
//       .then((data) => {
//         console.log(data);
//       })
//       .catch((err) => {
//         console.log(err);
//       })
//       .finally(() => {
//         dispatch(toggleLoader({ isLoading: false }));
//       });
//   }
// );

// export const deletecomplianceHandler = createAsyncThunk(
//   "delete compliance from db",
//   async (payload: any, { dispatch }) => {
//     try {
//       dispatch(toggleLoader({ isLoading: true }));

//       const response = await fetch(`${endpoints.deletecompliance}${payload}`, {
//         method: "GET",
//         headers: {
//           "Content-type": "application/json; charset=UTF-8",
//           Authorization: `Bearer ${getLocalStorage(
//             localStorageActionType.GET_ACCESS_TOKEN
//           )}`,
//         },
//       });
//       const data = await response.json();
//       if (response.status === 200) {
//         toast.success(data.message);
//         dispatch(getAllCompliance());
//         // console.log(data);
//       }
//     } catch (error) {
//       console.error(error);
//       toast.error("Sorry!, Something went wrong.");
//     } finally {
//       dispatch(toggleLoader({ isLoading: false }));
//     }
//   }
// );

// export const getcomplianceDetail = createAsyncThunk(
//   "get compliance details",
//   async (payload: any, { dispatch }) => {
//     try {
//       dispatch(toggleLoader({ isLoading: true }));

//       const response = await fetch(`${endpoints.viewcompliance}${payload}`, {
//         method: "GET",
//         headers: {
//           "Content-type": "application/json; charset=UTF-8",
//           Authorization: `Bearer ${getLocalStorage(
//             localStorageActionType.GET_ACCESS_TOKEN
//           )}`,
//         },
//       });

//       const data = await response.json();
//       if (response.status === 200) {
//         dispatch(
//           setcomplianceDetails({
//             compliance: data[0],
//           })
//         );
//         toast.success("compliance Details Fetch successfully!.");
//         console.log(data);
//       }

//       if (data.code === 404) {
//         toast.error(data.message);
//       }
//     } catch (error) {
//       console.error(error);
//     } finally {
//       dispatch(toggleLoader({ isLoading: false }));
//     }
//   }
// );

const complianceSlice = createSlice({
  name: "compliance slice",
  initialState: initialState,
  reducers: {
    setAllCompliance: (state, action: PayloadAction<allComplianceProp>) => {
      return {
        ...state,
        allCompliance: action.payload.allCompliance,
      };
    },
    setcomplianceDetails: (state, action: PayloadAction<complianceProp>) => {
      return {
        ...state,
        compliance: action.payload.compliance,
      };
    },
    setcomplianceState: (state, action: PayloadAction<buscomplianceProp>) => {
      return {
        ...state,
        isSuccess: action.payload.isSuccess,
      };
    },
    resetcomplianceState: (state) => {
      return {
        ...state,
        isSuccess: false,
      };
    },
  },
});

export const {
  setAllCompliance,
  setcomplianceState,
  resetcomplianceState,
  setcomplianceDetails,
} = complianceSlice.actions;
export default complianceSlice.reducer;
