import React, { useEffect } from "react";
import { AppDispatch, RootState } from "../../../app/Store";
import { useDispatch, useSelector } from "react-redux";
import { changeHeading } from "../../../features/topbarHeadingSlice";
import { getAllUsers, resetUserState } from "../../../features/userSlice";
import { ActionWrapper, Wrapper } from "./StyledUserList";

import { useNavigate } from "react-router-dom";
import { routerPath } from "../../../routes/routes";
import UserTable from "../../Tables/User/UserTable";
import { Button } from "reactstrap";

const UserList: React.FC = () => {
  document.title = "Users | KSM - Admin Dashboard.";

  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { allUsers } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    dispatch(
      changeHeading({
        heading: "Users",
        isBackBtn: false,
        breadcrumbItems: [
          { title: "Ksm", link: "#" },
          { title: "Users", link: "#" },
        ],
      })
    );
    dispatch(getAllUsers());
    dispatch(resetUserState());

  }, []);

  // const columns = ['id', 'username', 'email'];

  const Dtcolumns = [
    {
      label: "User Id",
      field: "id",
      sort: "asc",
      width: 150,
    },
    {
      label: "Username",
      field: "username",
      sort: "asc",
      width: 150,
    },
    {
      label: "Email",
      field: "email",
      sort: "asc",
      width: 150,
    },
    // {
    //   label: "Role Id",
    //   field: "role_id",
    //   sort: "asc",
    //   width: 150,
    // },
  ];

  const navigateToaddUser = () => {
    navigate(routerPath.addUser);
  };

  return (
    <Wrapper>
      <UserTable columns={Dtcolumns} rows={allUsers} />
    </Wrapper>
  );
};

export default UserList;
