import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface initialStateProp {
  open: boolean;
}

const initialState: initialStateProp = {
  open: false,
};

interface toggleMode {
  open: boolean;
}

const leftMenuSlice = createSlice({
  name: "left menu slice",
  initialState: initialState,
  reducers: {
    toggleLeftMenu: (state, action: PayloadAction<toggleMode>) => {
      return {
        ...state,
        open: action.payload.open,
      };
    },
  },
});

export const { toggleLeftMenu } = leftMenuSlice.actions;
export default leftMenuSlice.reducer;
