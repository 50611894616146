import React, { FC } from "react";
import Modal from "../Modal";
import {
  ModalAction,
  ModalImg,
  ModalMessege,
  ModalWrapper,
} from "../ActionModal/StyleDeleteModal";
import warning from "../../../assets/warningSign.png";
import { useNavigate } from "react-router-dom";
import { routerPath } from "../../../routes/routes";
import { Button } from "reactstrap";

interface ModalProp {
  openModal: boolean;
  setOpenModal: any;
  confirm: any;
  title: string;
  content: string;
  id: any;
}

const WarningModal: FC<ModalProp> = ({
  openModal,
  setOpenModal,
  confirm,
  title,
  content,
  id,
}) => {
  const navigate = useNavigate();
  const redirectToView = (id: any) => {
    navigate(routerPath.serviceDetail(id));
  };
  return (
    <>
      <Modal
        isOpen={openModal}
        name={"Create Warning Modal"}
        title={title}
        toggleModal={() => setOpenModal(false)}
      >
        <ModalWrapper>
          <ModalImg>
            <img src={warning} alt="warning" width={"150px"} height={"150px"} />
          </ModalImg>
          <ModalMessege>
            <div>Sorry!, You cannot Delete this {title}, </div>
            <div>
              because this {title} is belongs to {content}.
            </div>
          </ModalMessege>
          <ModalAction>
          <Button
              type="button"
              color="primary"
              className="waves-effect waves-light"
              onClick={() => redirectToView(id)}
            >
              View
            </Button>
            <Button
              type="button"
              color="danger"
              className="waves-effect waves-light"
              onClick={confirm}
            >
              Cancel
            </Button>
            
          </ModalAction>
        </ModalWrapper>
      </Modal>
    </>
  );
};

export default WarningModal;
