import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap";

import "../datatables.scss";
import { useNavigate } from "react-router-dom";
import { routerPath } from "../../../routes/routes";
import { FaRegTrashAlt } from "react-icons/fa";
import { PiEyeBold,PiNotePencilBold } from "react-icons/pi";
import ActionModal from "../../../components/Modal/ActionModal/ActionModal";
import { useDispatch } from "react-redux";
import { deleteUser } from "../../../features/userSlice";

interface RowData {
  id: any;
  username: string;
  email: string;
  user_role:string;
}

interface ColumnData {
  label: string;
  field: string;
  sort?: string;
  width?: number;
}

interface TableProp {
  columns: ColumnData[];
  rows: RowData[];
}

const UserTable: React.FC<TableProp> = ({ columns, rows }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [selectedId, setSelectedId] = useState("");


  const newColumns = [
    ...columns,
    {
      label: 'Actions',
      field: "actions",
      width: 100,
    },
  ];

  const handleDeleteModal = (id: any) => {
    setOpenDeleteModel(true);
    setSelectedId(id);
    console.log(id);
  };
  const confirmDelete = () => {
    setOpenDeleteModel(false);
    dispatch(deleteUser(selectedId));
  };

  // Define the function to handle "View" button click
  const handleViewClick = (id: any) => {
    // Navigate to the bus detail page with the provided bus_id
    navigate(routerPath.view_user(id));
  };

  const data = {
    columns: newColumns,
    rows: rows.map((rowData) => ({
      ...rowData,
      actions: (
        // <div className="d-flex justify-content-around align-items-center " >
            <div className="d-flex flex-wrap gap-1 justify-content-center align-items-center">

          <Button
            type="button"
            color="primary"
            className="waves-effect waves-light"
            onClick={() => handleViewClick(rowData.id)}
            title="View"
          >
            <PiEyeBold style={{ fontSize: "18px" }}/>
          </Button>
          <Button
            type="button"
            color="info"
            className="waves-effect waves-light"
            onClick={() =>navigate(routerPath.update_user(rowData.id))}
            title="Update"

          >
            <PiNotePencilBold style={{ fontSize: "18px" }}/>
          </Button>
          <Button
            type="button"
            color="danger"
            className="waves-effect waves-light"
            onClick={() => handleDeleteModal(rowData.id)}
            title="Delete"

          >
            <FaRegTrashAlt style={{ fontSize: "18px" }}/>
          </Button>
        
        </div>
      ),
    })),
  };


  return (
    <React.Fragment>
      <ActionModal
        openModal={openDeleteModel}
        setOpenModal={setOpenDeleteModel}
        confirm={confirmDelete}
        title={"Delete"}
        content={"User"}
      />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="d-flex justify-content-end">
                <Button
                  type="button"
                  color="primary"
                  className="waves-effect waves-light"
                  onClick={()=>navigate(routerPath.addUser)}
                >
                  Add User
                </Button>{" "}
              </CardTitle>
              <MDBDataTable responsive bordered noBottomColumns data={data} />
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="h4">Stripped example </CardTitle>
              <p className="card-title-desc">
                mdbreact DataTables has most features enabled by default, so all
                you need to do to use it with your tables is to call the
                construction function:{" "}
                <code>&lt;MDBDataTable striped /&gt;</code>.
              </p>

              <MDBDataTable responsive striped bordered data={data} />
            </CardBody>
          </Card>
        </Col>
      </Row> */}
    </React.Fragment>
  );
};

export default UserTable;
