import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as endpoints from "../networkUtility/endpoint";
import { toggleLoader } from "./loaderSlice";
import { toast } from "react-toastify";
import { setLocalStorage } from "../localStorage/SetLocalStorage";
import * as localStorageActionType from "../localStorage/ActionTypes";
import { AppDispatch } from "../app/Store";

interface initialStateProp {
  isLoggedin: boolean;
}

const initialState: initialStateProp = {
  isLoggedin: false,
};

interface SigninAccount {
  email: string;
  password: string;
}

interface Login {
  isLoggedin: boolean;
}

const authSlice = createSlice({
  name: "authentication slice",
  initialState: initialState,
  reducers: {
    toggleLogin: (state, action: PayloadAction<Login>) => {
      state.isLoggedin = action.payload.isLoggedin;
    },
  },
});

export const createLogin = createAsyncThunk<
  void,
  SigninAccount,
  { dispatch: AppDispatch }
>("auth/createLogin", async (payload: SigninAccount, { dispatch }) => {
  try {
    dispatch(toggleLoader({ isLoading: true }));

    const response = await fetch(endpoints.login, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    console.log(response.status);
    const data = await response.json();
    if (response.status === 200) {
      console.log(data);
      toast.success(data.message);
      let tokenObj = {
        accessToken: data.token,
        refreshToken: data.token,
      };
      setLocalStorage(
        localStorageActionType.SET_ACCESS_REFRESH_TOKEN,
        tokenObj
      );
      setLocalStorage(localStorageActionType.SET_IS_LOGGED_IN, true);
      dispatch(
        toggleLogin({
          isLoggedin: true,
        })
      );
    } else if (response.status === 401) {
      console.log(data);
      toast.error(data.error);
    } else {
      toast.error("Sorry, something went wrong!");
    }
  } catch (err) {
    console.error(err);
    toast.error("Sorry, something went wrong!");
  } finally {
    dispatch(toggleLoader({ isLoading: false }));
    window.location.reload();
  }
});

export const { toggleLogin } = authSlice.actions;
export default authSlice.reducer;
