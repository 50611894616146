import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeHeading } from '../../../features/topbarHeadingSlice'
import DriverTable from '../../Tables/EmployeeTables/DriverTable'
import { resetUserState } from '../../../features/userSlice'
import { getAllDriver, resetdriverState } from '../../../features/driverSlice'
import { RootState } from '../../../app/Store'


const data = [
    {id:1,username:"usr123",employee_id:"emp012",driver_name:"john doe",license_number:'2563654125',license_expiry_date:'2024-04-12',license_status:"compliant",status:"Active"},
    {id:2,username:"usr123",employee_id:"emp012",driver_name:"john doe",license_number:'2563654125',license_expiry_date:'2024-04-12',license_status:"compliant",status:"Active"},
    {id:2,username:"usr123",employee_id:"emp012",driver_name:"john doe",license_number:'2563654125',license_expiry_date:'2024-04-12',license_status:"compliant",status:"Active"}

]
const DriverList = () => {
  document.title = "Drivers list| KSM - Admin Dashboard.";
  const {allDriver}=useSelector((state:RootState)=>state.drivers);


    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(
          changeHeading({
            heading: "Drivers list",
            isBackBtn: false,
            breadcrumbItems: [
              { title: "Ksm", link: "#" },
              { title: "Drivers list", link: "#" },
            ],
          })
        );
        dispatch(getAllDriver());
        dispatch(resetdriverState());
      }, []);

// Username
// Employee ID
// Driver Name
// License Number
// License Expiry Date
// License Status (Compliant if expiry date is not past else non-compliant)
// Status (Active or On-hold or Terminated)

    const columnData=[
          {
            label: 'Id',
            field: 'id',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Username',
            field: 'username',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Employee ID',
            field: 'employee_id',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Driver Name',
            field: 'driver_name',
            sort: 'asc',
            width: 150
          },
          {
            label: 'License Number',
            field: 'license_number',
            sort: 'asc',
            width: 150
          },
          {
            label: 'License Expiry Date',
            field: 'license_expiry_date',
            sort: 'asc',
            width: 150
          },
          
          {
            label: 'Status',
            field: 'status',
            sort: 'asc',
            width: 150
          },
          
    ]
  return (
    <div>
      <DriverTable columns={columnData} rows={allDriver}/>

    </div>
  )
}

export default DriverList