import { configureStore } from "@reduxjs/toolkit";
import nightModeSlice from "../features/nightModeSlice";
import topbarHeadingSlice from "../features/topbarHeadingSlice";
import leftMenuSlice from "../features/leftMenuSlice";
import loaderSlice from "../features/loaderSlice";
import authSlice from "../features/authSlice";
import busSlice from "../features/busSlice";
import routeSlice from "../features/routeSlice";
import serviceSlice from "../features/serviceSlice";
import userSlice from "../features/userSlice";
import DashboardSlice from "../features/DashboardSlice";
import uploaderSlice from "../features/uploaderSlice";
import stopSlice from "../features/stopSlice";
import complianceSlice from "../features/complianceSlice";
import tripSlice from "../features/tripSlice";
import driverSlice from "../features/driverSlice";
import cleanerSlice from "../features/cleanerSlice";
import permissionSlice from "../features/permissionSlice";

const store = configureStore({
  reducer: {
    loader: loaderSlice,
    nightModeSlice: nightModeSlice,
    headingSlice: topbarHeadingSlice,
    leftMenuSlice: leftMenuSlice,
    auth: authSlice,
    buses: busSlice,
    route: routeSlice,
    services: serviceSlice,
    user: userSlice,
    dashBoard: DashboardSlice,
    uploader: uploaderSlice,
    stops: stopSlice,
    compliance: complianceSlice,
    trips: tripSlice,
    drivers: driverSlice,
    cleaner: cleanerSlice,
    permission: permissionSlice,
  },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
