import React, { ReactFragment, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../app/Store';
import { changeHeading } from '../../features/topbarHeadingSlice';
import constructionImg from '../../assets/under-construction.png'

const Construction: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();

  document.title = "Page Under Construction ";
  useEffect(() => {
    dispatch(
      changeHeading({
        heading: "Under Construction",
        isBackBtn: true,
  breadcrumbItems:[]

      })
    );
  }, []);

  return (
    <>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md="8" lg="6" xl="5">
              <Card className="overflow-hidden">
                <CardBody className="pt-0">

                  <div className="ex-page-content text-center">
                     <img className='m-2' src={constructionImg} alt="constructImg" width={'150px'} />
                    <h3 className="">Page is Under Construction.</h3>
                    <br />

                    <Link className="btn btn-info mb-4 waves-effect waves-light" to="/"><i className="mdi mdi-home"></i> Back to Dashboard</Link>
                  </div>

                </CardBody>
              </Card>
              <div className="mt-2 text-center">
                <p>©2021 KSM</p> 
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Construction
