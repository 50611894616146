import React from "react";
import "./App.css";
import AppRoute from "./routes/routes";
import AppWrapper from "./containers/AppWrapper/AppWrapper";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./components/Loader/Loader";
import { useSelector } from "react-redux";
import { RootState } from "./app/Store";
import './assets/scss/theme.scss';


function App() {
  const isLoading = useSelector((state: RootState) => state.loader.isLoading);
  return (
    <div className="App">
      <Loader isLoading={isLoading} />
      <AppWrapper>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover={false}
          theme="light"
        />
        <AppRoute />
      </AppWrapper>
    </div>
  );
}

export default App;
