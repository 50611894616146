import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeHeading } from '../../../features/topbarHeadingSlice'
import CleanerTable from '../../Tables/EmployeeTables/CleanerTable'
import { getAllCleaner, resetcleanerState } from '../../../features/cleanerSlice'
import { RootState } from '../../../app/Store'


const data = [
    {id:1,username:"john doe",employee_id:'2563654125',phone_number:'xyz',date_of_joining:"2024-04-12",cleaner_name:"john"},
    {id:2,username:"john doe",employee_id:'2563654125',phone_number:'xyz',date_of_joining:"2024-04-12",cleaner_name:"john"},
    {id:2,username:"john doe",employee_id:'2563654125',phone_number:'xyz',date_of_joining:"2024-04-12",cleaner_name:"john"},

]
const CleanerList = () => {
  document.title = "Cleaner list| KSM - Admin Dashboard.";
  const {allCleaner}=useSelector((state:RootState)=>state.cleaner);


    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(
          changeHeading({
            heading: "Cleaner list",
            isBackBtn: false,
            breadcrumbItems: [
              { title: "Ksm", link: "#" },
              { title: "Cleaner list", link: "#" },
            ],
          })
        );
        dispatch(getAllCleaner());
        dispatch(resetcleanerState());
      }, []);
    const columnData=[
          {
            label: 'Id',
            field: 'id',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Username',
            field: 'username',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Employee ID',
            field: 'employee_id',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Cleaner Name',
            field: 'cleaner_name',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Contact Number',
            field: 'contact_number',
            sort: 'asc',
            width: 150
          },
          
          
    ]
  return (
    <div>
      <CleanerTable columns={columnData} rows={allCleaner}/>
    </div>
  )
}

export default CleanerList