import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { changeHeading } from '../../../features/topbarHeadingSlice'
import StaffTable from '../../Tables/EmployeeTables/StaffTable'


const data = [
    {id:1,name:"john doe",mobile_no:'2563654125',address:'xyz'},
    {id:2,name:"john doe",mobile_no:'2563654125',address:'xyz'},
    {id:2,name:"john doe",mobile_no:'2563654125',address:'xyz'},

]
const StaffList = () => {
  document.title = "Staff list| KSM - Admin Dashboard.";

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(
          changeHeading({
            heading: "Staff list",
            isBackBtn: false,
            breadcrumbItems: [
              { title: "Ksm", link: "#" },
              { title: "Staff list", link: "#" },
            ],
          })
        );
      }, []);
    const columnData=[
          {
            label: 'Id',
            field: 'id',
            sort: 'asc',
            width: 150
          },
          {
            label: 'name',
            field: 'name',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Mobile',
            field: 'mobile',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Address',
            field: 'address',
            sort: 'asc',
            width: 150
          },
          
    ]
  return (
    <div>
      <StaffTable columns={columnData} rows={data}/>

    </div>
  )
}

export default StaffList