import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/Store";
import { changeHeading } from "../../../features/topbarHeadingSlice";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

import { Wrapper } from "./StyledAddService";

import { useNavigate } from "react-router-dom";
import { routerPath } from "../../../routes/routes";

import { addNewService } from "../../../features/serviceSlice";
import { getBusData, getRouteData } from "../../../features/DashboardSlice";
import { getAllStops } from "../../../features/stopSlice";
import SimpleStopsTable from "../../Tables/SimpleStopsTable/SimpleStopsTable";

interface SelectProp {
  value: string;
  label: string;
}

const AddService = () => {
  const { busNumberData, routeIdData } = useSelector(
    (state: RootState) => state.dashBoard
  );
  console.log(busNumberData, routeIdData);
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedStops, setSelectedStops] = useState<any[]>([]);
  const { allStops } = useSelector((state: RootState) => state.stops);
  const { isSuccess } = useSelector((state: RootState) => state.services);





  useEffect(() => {
    dispatch(
      changeHeading({
        heading: "Add Services",
        isBackBtn: true,
        breadcrumbItems: [
          { title: "Ksm", link: "#" },
          { title: "Services", link: "#" },
          { title: "Add Service", link: "#" },
        ],
      })
    );

    dispatch(getBusData());
    dispatch(getRouteData());
    dispatch(getAllStops());

  }, []);

  useEffect(() => {
    if (isSuccess === true) {
      navigate(routerPath.servicesList);
    }
  }, [isSuccess]);

  const handleStopSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedStop = event.target.value;
    const stopObj = allStops.find(
      (stop: any) => stop.stop_name === selectedStop
    );
    if (stopObj) {
      setSelectedStops((prevSelectedStops) => [...prevSelectedStops, stopObj]);
    }
  };

  const handleSubmit = (event: any, errors: any, values: any) => {
    if (errors.length === 0) {
      const formattedData = {
        ...values,
        announcementPoints: selectedStops,
        // id: params.id,
      };
      // Dispatch the addNewUser action with the form values
      console.log(formattedData);
      dispatch(addNewService(formattedData));
    }
  };

  const handleRemoveStop = (indexToRemove: number) => {
    setSelectedStops((prevSelectedStops:any) =>
      prevSelectedStops.filter((_:any, index:any) => index !== indexToRemove)
    );
  };



  return (
    <Wrapper>
      <Card>
        <CardBody>
          <h4 className="card-title">Add Service</h4>
          <AvForm className="needs-validation" onSubmit={handleSubmit}>
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="busNumber">Bus Number</Label>
                  <AvField
                    name="busNumber"
                    type="select"
                    errorMessage="Please select bus number."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="busNumber"
                  >
                    <option value="">Select Bus Number</option>
                    {busNumberData.map((busNumberObj: SelectProp) => (
                      <option
                        key={busNumberObj.value}
                        value={busNumberObj.value}
                      >
                        {busNumberObj.label}
                      </option>
                    ))}
                  </AvField>
                </div>
              </Col>

              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="routeId">Route Id</Label>
                  <AvField
                    name="routeId"
                    type="select"
                    errorMessage="Please select route id."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="routeId"
                  >
                    <option value="">Select Route Id</option>
                    {routeIdData.map((routrId: SelectProp) => (
                      <option
                        key={routrId.value}
                        value={routrId.value}
                      >
                        {routrId.label}
                      </option>
                    ))}
                  </AvField>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="serviceId">Service Id</Label>
                  <AvField
                    name="serviceId"
                    placeholder="Service Id"
                    type="text"
                    errorMessage="Please enter service Id."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="serviceId"
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="serviceName">Service Name</Label>
                  <AvField
                    name="serviceName"
                    placeholder="Service Name"
                    type="text"
                    errorMessage="Please enter service same."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="serviceName"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              {/* <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="startDate">Start Date</Label>
                  <AvField
                    name="startDate"
                    placeholder="Start Date"
                    type="date"
                    errorMessage="Please enter start date."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="startDate"
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="startTime">Start Time</Label>
                  <AvField
                    name="startTime"
                    placeholder="Start Time"
                    type="time"
                    errorMessage="Please enter start time."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="startTime"
                  />
                </div>
              </Col> */}
            </Row>
            <Row>
              {/* <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="createdBy">Created By</Label>
                  <AvField
                    name="createdBy"
                    placeholder="Created By"
                    type="text"
                    errorMessage="created by whom."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="createdBy"
                  />
                </div>
              </Col> */}
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="recurring">Recurring</Label>
                  <AvField
                    name="recurring"
                    type="select"
                    errorMessage="Please provide a valid recurring."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="recurring"
                  >
                    <option value="">Select Status</option>
                    <option value="Y">Yes</option>
                    <option value="N">No</option>
                  </AvField>
                </div>
              </Col>
            
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="Username">Stops</Label>
                  <AvField
                    name="stop"
                    type="select"
                    errorMessage="Please select stops."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="Username"
                    onChange={handleStopSelect}
                  >
                    <option value="">Select Stops</option>
                    {allStops.map((userNameObj: any) => (
                      <option
                        key={userNameObj.stop_name}
                        value={userNameObj.stop_name}
                      >
                        {userNameObj.stop_name}
                      </option>
                    ))}
                  </AvField>
                </div>
              </Col>
            </Row>
            {/* Display selected stops */}
            {selectedStops.length > 0 && (
              <SimpleStopsTable selectedStops={selectedStops} handleRemoveStop={handleRemoveStop}/>
            )}
            <div className="d-flex justify-content-end">
              <Button color="primary" type="submit">
                Submit
              </Button>
            </div>
          </AvForm>
        </CardBody>
      </Card>
    </Wrapper>
  );
};

export default AddService;
