// Import necessary React and Redux dependencies
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/Store";
import { changeHeading } from "../../../features/topbarHeadingSlice";
import {
  addNewUser,
  addNewUserRole,
  getUserRoleDetail,
  updateUserRole,
} from "../../../features/userSlice";
import { useNavigate, useParams } from "react-router-dom";
import { routerPath } from "../../../routes/routes";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

// Define the AddUser component
interface initialState{
  users: any;
    userrole: any;
    staffs: any;
    buses:any;
    routes:any;
    services:any;
    trips:any;
}
const UpdateUserRole: React.FC = () => {
  document.title = "Update User Role | Ksm Admin Dashboard.";

  // Initialize useDispatch and useNavigate hooks
  const dispatch: AppDispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { isSuccess } = useSelector((state: RootState) => state.user);
  const [allPermissionsUser, setAllPermissionsUser] = useState(false);
  const [allPermissionsUserRole, setAllPermissionsUserRole] = useState(false);
  const [allPermissionsStaff, setAllPermissionsStaff] = useState(false);
  const [allPermissionsBus, setAllPermissionsBus] = useState(false);
  const [allPermissionsRoute, setAllPermissionsRoute] = useState(false);
  const [allPermissionsService, setAllPermissionsService] = useState(false);
  const [allPermissionsTrip, setAllPermissionsTrip] = useState(false);

  const { userRoleDetail } = useSelector((state: RootState) => state.user);

  const [roleName, setRoleName] = useState("");
  const [status, setStatus] = useState("");
  const [permissions, setPermissions] = useState<initialState>({
    users: userRoleDetail.permissions?.users || {},
    userrole: userRoleDetail.permissions?.userrole || {},
    staffs: userRoleDetail.permissions?.staffs || {},
    buses: userRoleDetail.permissions?.buses || {},
    routes: userRoleDetail.permissions?.routes || {},
    services: userRoleDetail.permissions?.services || {},
    trips: userRoleDetail.permissions?.trips || {},
  });
// useEffect to dispatch the changeHeading action when the component mounts
useEffect(() => {
  dispatch(
    changeHeading({
      heading: "Update Role",
      isBackBtn: true,
      breadcrumbItems: [
        { title: "Ksm", link: "#" },
        { title: "Users", link: "#" },
        { title: `${params.id}`, link: "#" },
      ],
    })
  );
  dispatch(getUserRoleDetail(params.id));
}, []);
  useEffect(() => {
    if (Object.keys(userRoleDetail).length!==0) {
      setRoleName(userRoleDetail.role_name);
      setStatus('active');
      setPermissions(userRoleDetail.permissions);
    

    }
  }, [userRoleDetail,params.id]);
  console.log(permissions);

  useEffect(()=>{
    const userCheckboxes = document.querySelectorAll<HTMLInputElement>(
      `input[name="user_permissions"]`
    );
    userCheckboxes.forEach((checkbox: HTMLInputElement) => {
      if (checkbox.value === "add_user") {
        checkbox.checked = permissions.users?.add;
      } else if (checkbox.value === "edit_user") {
        checkbox.checked = permissions.users?.edit;
      } else if (checkbox.value === "delete_user") {
        checkbox.checked = permissions.users?.delete;
      } else if (checkbox.value === "view_user") {
        checkbox.checked = permissions.users?.view;
      } else if (checkbox.value === "view_user_list") {
        checkbox.checked = permissions.users?.view_list;
      }
    });
    const userRoleCheckboxes = document.querySelectorAll<HTMLInputElement>(
      `input[name="userrole_permissions"]`
    );
    userRoleCheckboxes.forEach((checkbox: HTMLInputElement) => {
      // console.log(checkbox.value);
      if (checkbox.value === "add_user_role") {
        checkbox.checked = permissions.userrole?.add;
      } else if (checkbox.value === "edit_user_role") {
        checkbox.checked = permissions.userrole?.edit;
      } else if (checkbox.value === "delete_user_role") {
        checkbox.checked = permissions.userrole?.delete;
      } else if (checkbox.value === "view_user_role") {
        checkbox.checked = permissions.userrole?.view;
      } else if (checkbox.value === "view_user_role_list") {
        checkbox.checked = permissions.userrole?.view_list;
      }
    });

    const staffCheckboxes = document.querySelectorAll<HTMLInputElement>(
      `input[name="staff_permissions"]`
    );
    staffCheckboxes.forEach((checkbox: HTMLInputElement) => {
      console.log(checkbox.value);
      if (checkbox.value === "add_staffs") {
        checkbox.checked = permissions?.staffs.add;
      } else if (checkbox.value === "edit_staffs") {
        checkbox.checked = permissions?.staffs.edit;
      } else if (checkbox.value === "delete_staffs") {
        checkbox.checked = permissions?.staffs.delete;
      } else if (checkbox.value === "view_staffs") {
        checkbox.checked = permissions?.staffs.view;
      } else if (checkbox.value === "view_staffs_list") {
        checkbox.checked = permissions?.staffs.view_list;
      }
    });

    const busCheckboxes = document.querySelectorAll<HTMLInputElement>(
      `input[name="bus_permissions"]`
    );
    busCheckboxes.forEach((checkbox: HTMLInputElement) => {
      console.log(checkbox.value);
      if (checkbox.value === "add_bus") {
        checkbox.checked = permissions?.buses.add;
      } else if (checkbox.value === "edit_bus") {
        checkbox.checked = permissions?.buses.edit;
      } else if (checkbox.value === "delete_bus") {
        checkbox.checked = permissions?.buses.delete;
      } else if (checkbox.value === "view_bus") {
        checkbox.checked = permissions?.buses.view;
      } else if (checkbox.value === "view_bus_list") {
        checkbox.checked = permissions?.buses.view_list;
      }
    });
    
    const routeCheckboxes = document.querySelectorAll<HTMLInputElement>(
      `input[name="route_permissions"]`
    );
    routeCheckboxes.forEach((checkbox: HTMLInputElement) => {
      console.log(checkbox.value);
      if (checkbox.value === "add_route") {
        checkbox.checked = permissions?.routes.add;
      } else if (checkbox.value === "edit_route") {
        checkbox.checked = permissions?.routes.edit;
      } else if (checkbox.value === "delete_route") {
        checkbox.checked = permissions?.routes.delete;
      } else if (checkbox.value === "view_route") {
        checkbox.checked = permissions?.routes.view;
      } else if (checkbox.value === "view_route_list") {
        checkbox.checked = permissions?.routes.view_list;
      }
    });
    const serviceCheckboxes = document.querySelectorAll<HTMLInputElement>(
      `input[name="service_permissions"]`
    );
    serviceCheckboxes.forEach((checkbox: HTMLInputElement) => {
      console.log(checkbox.value);
      if (checkbox.value === "add_service") {
        checkbox.checked = permissions?.services.add;
      } else if (checkbox.value === "edit_service") {
        checkbox.checked = permissions?.services.edit;
      } else if (checkbox.value === "delete_service") {
        checkbox.checked = permissions?.services.delete;
      } else if (checkbox.value === "view_service") {
        checkbox.checked = permissions?.services.view;
      } else if (checkbox.value === "view_service_list") {
        checkbox.checked = permissions?.services.view_list;
      }
    });
    const tripCheckboxes = document.querySelectorAll<HTMLInputElement>(
      `input[name="trip_permissions"]`
    );
    tripCheckboxes.forEach((checkbox: HTMLInputElement) => {
      console.log(checkbox.value);
      if (checkbox.value === "add_trip") {
        checkbox.checked = permissions?.trips.add;
      } else if (checkbox.value === "edit_trip") {
        checkbox.checked = permissions?.trips.edit;
      } else if (checkbox.value === "delete_trip") {
        checkbox.checked = permissions?.trips.delete;
      } else if (checkbox.value === "view_trip") {
        checkbox.checked = permissions?.trips.view;
      } else if (checkbox.value === "view_trip_list") {
        checkbox.checked = permissions?.trips.view_list;
      }
    });
    

  },[permissions])
  

  // Function to handle changing the "All Permissions" checkbox
  const handleUserAllPermissionsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setAllPermissionsUser(isChecked);
    const checkboxGroupName = event.target.name;
    const checkboxes = document.querySelectorAll<HTMLInputElement>(
      `input[name="${checkboxGroupName}"]`
    );
    checkboxes.forEach((checkbox: HTMLInputElement) => {
      checkbox.checked = isChecked;
    });
  };
  const handleUserRoleAllPermissionsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setAllPermissionsUserRole(isChecked);
    const checkboxGroupName = event.target.name;
    const checkboxes = document.querySelectorAll<HTMLInputElement>(
      `input[name="${checkboxGroupName}"]`
    );
    checkboxes.forEach((checkbox: HTMLInputElement) => {
      checkbox.checked = isChecked;
    });
  };
  const handleStaffAllPermissionsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setAllPermissionsStaff(isChecked);
    const checkboxGroupName = event.target.name;
    const checkboxes = document.querySelectorAll<HTMLInputElement>(
      `input[name="${checkboxGroupName}"]`
    );
    checkboxes.forEach((checkbox: HTMLInputElement) => {
      checkbox.checked = isChecked;
    });
  };
  const handleBusAllPermissionsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setAllPermissionsBus(isChecked);
    const checkboxGroupName = event.target.name;
    const checkboxes = document.querySelectorAll<HTMLInputElement>(
      `input[name="${checkboxGroupName}"]`
    );
    checkboxes.forEach((checkbox: HTMLInputElement) => {
      checkbox.checked = isChecked;
    });
  };
  const handleRouteAllPermissionsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setAllPermissionsRoute(isChecked);
    const checkboxGroupName = event.target.name;
    const checkboxes = document.querySelectorAll<HTMLInputElement>(
      `input[name="${checkboxGroupName}"]`
    );
    checkboxes.forEach((checkbox: HTMLInputElement) => {
      checkbox.checked = isChecked;
    });
  };
  const handleServiceAllPermissionsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setAllPermissionsService(isChecked);
    const checkboxGroupName = event.target.name;
    const checkboxes = document.querySelectorAll<HTMLInputElement>(
      `input[name="${checkboxGroupName}"]`
    );
    checkboxes.forEach((checkbox: HTMLInputElement) => {
      checkbox.checked = isChecked;
    });
  };
  const handleTripAllPermissionsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setAllPermissionsTrip(isChecked);
    const checkboxGroupName = event.target.name;
    const checkboxes = document.querySelectorAll<HTMLInputElement>(
      `input[name="${checkboxGroupName}"]`
    );
    checkboxes.forEach((checkbox: HTMLInputElement) => {
      checkbox.checked = isChecked;
    });
  };
  

  useEffect(() => {
    if (isSuccess === true) {
      navigate(routerPath.user_role);
    }
  }, [isSuccess]);

  // Function to handle form submission
  const handleSubmit = (event: any, errors: any, values: any) => {
    if (errors.length === 0) {
      // Extract the selected permissions from the checkboxes
      const selectedPermissions = {
        users: {
          view_list: event.target.querySelector(
            'input[name="user_permissions"][value="view_user_list"]'
          ).checked,
          view: event.target.querySelector(
            'input[name="user_permissions"][value="view_user"]'
          ).checked,
          add: event.target.querySelector(
            'input[name="user_permissions"][value="add_user"]'
          ).checked,
          edit: event.target.querySelector(
            'input[name="user_permissions"][value="edit_user"]'
          ).checked,
          delete: event.target.querySelector(
            'input[name="user_permissions"][value="delete_user"]'
          ).checked,
        },
        userrole: {
          view_list: event.target.querySelector(
            'input[name="userrole_permissions"][value="view_user_role_list"]'
          ).checked,
          view: event.target.querySelector(
            'input[name="userrole_permissions"][value="view_user_role"]'
          ).checked,
          add: event.target.querySelector(
            'input[name="userrole_permissions"][value="add_user_role"]'
          ).checked,
          edit: event.target.querySelector(
            'input[name="userrole_permissions"][value="edit_user_role"]'
          ).checked,
          delete: event.target.querySelector(
            'input[name="userrole_permissions"][value="delete_user_role"]'
          ).checked,
        },
        staffs: {
          view_list: event.target.querySelector(
            'input[name="staff_permissions"][value="view_staffs_list"]'
          ).checked,
          view: event.target.querySelector(
            'input[name="staff_permissions"][value="view_staffs"]'
          ).checked,
          add: event.target.querySelector(
            'input[name="staff_permissions"][value="add_staffs"]'
          ).checked,
          edit: event.target.querySelector(
            'input[name="staff_permissions"][value="edit_staffs"]'
          ).checked,
          delete: event.target.querySelector(
            'input[name="staff_permissions"][value="delete_staffs"]'
          ).checked,
        },
        buses: {
          view_list: event.target.querySelector(
            'input[name="bus_permissions"][value="view_bus_list"]'
          ).checked,
          view: event.target.querySelector(
            'input[name="bus_permissions"][value="view_bus"]'
          ).checked,
          add: event.target.querySelector(
            'input[name="bus_permissions"][value="add_bus"]'
          ).checked,
          edit: event.target.querySelector(
            'input[name="bus_permissions"][value="edit_bus"]'
          ).checked,
          delete: event.target.querySelector(
            'input[name="bus_permissions"][value="delete_bus"]'
          ).checked,
        },
        routes: {
          view_list: event.target.querySelector(
            'input[name="route_permissions"][value="view_route_list"]'
          ).checked,
          view: event.target.querySelector(
            'input[name="route_permissions"][value="view_route"]'
          ).checked,
          add: event.target.querySelector(
            'input[name="route_permissions"][value="add_route"]'
          ).checked,
          edit: event.target.querySelector(
            'input[name="route_permissions"][value="edit_route"]'
          ).checked,
          delete: event.target.querySelector(
            'input[name="route_permissions"][value="delete_route"]'
          ).checked,
        },
        services: {
          view_list: event.target.querySelector(
            'input[name="service_permissions"][value="view_service_list"]'
          ).checked,
          view: event.target.querySelector(
            'input[name="service_permissions"][value="view_service"]'
          ).checked,
          add: event.target.querySelector(
            'input[name="service_permissions"][value="add_service"]'
          ).checked,
          edit: event.target.querySelector(
            'input[name="service_permissions"][value="edit_service"]'
          ).checked,
          delete: event.target.querySelector(
            'input[name="service_permissions"][value="delete_service"]'
          ).checked,
        },
        trips: {
          view_list: event.target.querySelector(
            'input[name="trip_permissions"][value="view_trip_list"]'
          ).checked,
          view: event.target.querySelector(
            'input[name="trip_permissions"][value="view_trip"]'
          ).checked,
          add: event.target.querySelector(
            'input[name="trip_permissions"][value="add_trip"]'
          ).checked,
          edit: event.target.querySelector(
            'input[name="trip_permissions"][value="edit_trip"]'
          ).checked,
          delete: event.target.querySelector(
            'input[name="trip_permissions"][value="delete_trip"]'
          ).checked,
        },
      };

      // Add the permissions to the values object
      values.permissions = selectedPermissions;
      values['id']=params.id;

      // Dispatch the addNewUser action with the updated form values
      dispatch(updateUserRole(values));
      console.log(values);

      // Example: Navigate to a different page after successful form submission
      // navigate(routerPath.users);
    }
  };

  // Return the JSX structure for the AddUser component


  const user = (
    <div>
      <div className="divider">
        <span>users</span>
      </div>
      <div
        className="d-flex align-items-center justify-content-between flex-wrap row"
        style={{ margin: "30px 20px 30px 20px" }}
      >
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input"
            id="allPermissionsCheckbox"
            name="user_permissions" // Change the group name accordingly
            onChange={handleUserAllPermissionsChange} // Attach onChange event handler
            checked={allPermissionsUser} // Controlled component
            style={{ transform: "scale(1.5)" }}
          />
          <label className="form-check-label" htmlFor="allPermissionsCheckbox">
            All Permissions
          </label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="alluCheckbox"
            name="user_permissions"
            value="view_user_list"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label"
            style={{ marginLeft: "5px" }}
            htmlFor="alluCheckbox"
          >
            View user list
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="vuCheckbox"
            name="user_permissions"
            value="view_user"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="vuCheckbox"
          >
            View user
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="aduCheckbox"
            name="user_permissions"
            value="add_user"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="aduCheckbox"
          >
            Add user
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="eduCheckbox"
            name="user_permissions"
            value="edit_user"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="eduCheckbox"
          >
            Edit user
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="deluCheckbox"
            name="user_permissions"
            value="delete_user"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="deluCheckbox"
          >
            Delete user
          </Label>
        </div>
      </div>
    </div>
  );

  const userRole = (
    <div>
      <div className="divider">
        <span>users roles</span>
      </div>
      <div
        className="d-flex align-items-center justify-content-between flex-wrap row"
        style={{ margin: "30px 20px 30px 20px" }}
      >
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input"
            id="allPermissionsCheckbox1"
            name="userrole_permissions" // Change the group name accordingly
            onChange={handleUserRoleAllPermissionsChange} // Attach onChange event handler
            checked={allPermissionsUserRole} // Controlled component
            style={{ transform: "scale(1.5)" }}
          />
          <label className="form-check-label" htmlFor="allPermissionsCheckbox1">
            All Permissions
          </label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="vurlCheckbox"
            name="userrole_permissions"
            value="view_user_role_list"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label"
            style={{ marginLeft: "5px" }}
            htmlFor="vurlCheckbox"
          >
            View user role list
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="vurCheckbox"
            name="userrole_permissions"
            value="view_user_role"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="vurCheckbox"
          >
            View user role
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="addurCheckbox"
            name="userrole_permissions"
            value="add_user_role"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="addurCheckbox"
          >
            Add user role
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="edurCheckbox"
            name="userrole_permissions"
            value="edit_user_role"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="edurCheckbox"
          >
            Edit user role
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="delurCheckbox"
            name="userrole_permissions"
            value="delete_user_role"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="delurCheckbox"
          >
            Delete user role
          </Label>
        </div>
      </div>
    </div>
  );
  const staffs = (
    <div>
      <div className="divider">
        <span>staffs</span>
      </div>
      <div
        className="d-flex align-items-center justify-content-between flex-wrap row"
        style={{ margin: "30px 20px 30px 20px" }}
      >
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input"
            id="allPermissionsCheckbox2"
            name="staff_permissions" // Change the group name accordingly
            onChange={handleStaffAllPermissionsChange} // Attach onChange event handler
            checked={allPermissionsStaff} // Controlled component
            style={{ transform: "scale(1.5)" }}
          />
          <label className="form-check-label" htmlFor="allPermissionsCheckbox2">
            All Permissions
          </label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="viewstafflCheckbox"
            name="staff_permissions"
            value="view_staffs_list"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label"
            style={{ marginLeft: "5px" }}
            htmlFor="viewstafflCheckbox"
          >
            View staff list
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="viewstaffCheckbox"
            name="staff_permissions"
            value="view_staffs"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="viewstaffCheckbox"
          >
            View staffs
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="addstaffCheckbox"
            name="staff_permissions"
            value="add_staffs"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="addstaffCheckbox"
          >
            Add staffs
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="editstaffCheckbox"
            name="staff_permissions"
            value="edit_staffs"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="editstaffCheckbox"
          >
            Edit staffs
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="delstaffCheckbox"
            name="staff_permissions"
            value="delete_staffs"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="delstaffCheckbox"
          >
            Delete staffs
          </Label>
        </div>
      </div>
    </div>
  );

  const bus = (
    <div>
      <div className="divider">
        <span>Bus</span>
      </div>
      <div
        className="d-flex align-items-center justify-content-between flex-wrap row"
        style={{ margin: "30px 20px 30px 20px" }}
      >
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input"
            id="allPermissionsCheckbox2"
            name="bus_permissions" // Change the group name accordingly
            onChange={handleBusAllPermissionsChange} // Attach onChange event handler
            checked={allPermissionsBus} // Controlled component
            style={{ transform: "scale(1.5)" }}
          />
          <label className="form-check-label" htmlFor="allPermissionsCheckbox2">
            All Permissions
          </label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="viewbuslCheckbox"
            name="bus_permissions"
            value="view_bus_list"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label"
            style={{ marginLeft: "5px" }}
            htmlFor="viewbuslCheckbox"
          >
            View bus list
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="viewbusCheckbox"
            name="bus_permissions"
            value="view_bus"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="viewbusCheckbox"
          >
            View bus
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="addbusCheckbox"
            name="bus_permissions"
            value="add_bus"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="addbusCheckbox"
          >
            Add bus
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="editbusCheckbox"
            name="bus_permissions"
            value="edit_bus"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="editbusCheckbox"
          >
            Edit bus
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="delbusCheckbox"
            name="bus_permissions"
            value="delete_bus"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="delbusCheckbox"
          >
            Delete bus
          </Label>
        </div>
      </div>
    </div>
  );

  const route  = (
    <div>
      <div className="divider">
        <span>Route</span>
      </div>
      <div
        className="d-flex align-items-center justify-content-between flex-wrap row"
        style={{ margin: "30px 20px 30px 20px" }}
      >
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input"
            id="allPermissionsCheckbox2"
            name="route_permissions" // Change the group name accordingly
            onChange={handleRouteAllPermissionsChange} // Attach onChange event handler
            checked={allPermissionsRoute} // Controlled component
            style={{ transform: "scale(1.5)" }}
          />
          <label className="form-check-label" htmlFor="allPermissionsCheckbox2">
            All Permissions
          </label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="viewroutelCheckbox"
            name="route_permissions"
            value="view_route_list"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label"
            style={{ marginLeft: "5px" }}
            htmlFor="viewroutelCheckbox"
          >
            View route list
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="viewrouteCheckbox"
            name="route_permissions"
            value="view_route"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="viewrouteCheckbox"
          >
            View route
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="addrouteCheckbox"
            name="route_permissions"
            value="add_route"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="addrouteCheckbox"
          >
            Add route
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="editrouteCheckbox"
            name="route_permissions"
            value="edit_route"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="editrouteCheckbox"
          >
            Edit route
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="delrouteCheckbox"
            name="route_permissions"
            value="delete_route"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="delrouteCheckbox"
          >
            Delete route
          </Label>
        </div>
      </div>
    </div>
  );

  const service  = (
    <div>
      <div className="divider">
        <span>Service</span>
      </div>
      <div
        className="d-flex align-items-center justify-content-between flex-wrap row"
        style={{ margin: "30px 20px 30px 20px" }}
      >
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input"
            id="allPermissionsCheckbox2"
            name="service_permissions" // Change the group name accordingly
            onChange={handleServiceAllPermissionsChange} // Attach onChange event handler
            checked={allPermissionsService} // Controlled component
            style={{ transform: "scale(1.5)" }}
          />
          <label className="form-check-label" htmlFor="allPermissionsCheckbox2">
            All Permissions
          </label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="viewservicelCheckbox"
            name="service_permissions"
            value="view_service_list"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label"
            style={{ marginLeft: "5px" }}
            htmlFor="viewservicelCheckbox"
          >
            View service list
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="viewserviceCheckbox"
            name="service_permissions"
            value="view_service"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="viewserviceCheckbox"
          >
            View service
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="addserviceCheckbox"
            name="service_permissions"
            value="add_service"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="addserviceCheckbox"
          >
            Add service
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="editserviceCheckbox"
            name="service_permissions"
            value="edit_service"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="editserviceCheckbox"
          >
            Edit service
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="delserviceCheckbox"
            name="service_permissions"
            value="delete_service"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="delserviceCheckbox"
          >
            Delete service
          </Label>
        </div>
      </div>
    </div>
  );
  const trip  = (
    <div>
      <div className="divider">
        <span>Trip</span>
      </div>
      <div
        className="d-flex align-items-center justify-content-between flex-wrap row"
        style={{ margin: "30px 20px 30px 20px" }}
      >
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input"
            id="allPermissionsCheckbox2"
            name="trip_permissions" // Change the group name accordingly
            onChange={handleTripAllPermissionsChange} // Attach onChange event handler
            checked={allPermissionsTrip} // Controlled component
            style={{ transform: "scale(1.5)" }}
          />
          <label className="form-check-label" htmlFor="allPermissionsCheckbox2">
            All Permissions
          </label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="viewtriplCheckbox"
            name="trip_permissions"
            value="view_trip_list"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label"
            style={{ marginLeft: "5px" }}
            htmlFor="viewtriplCheckbox"
          >
            View trip list
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="viewtripCheckbox"
            name="trip_permissions"
            value="view_trip"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="viewtripCheckbox"
          >
            View trip
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="addtripCheckbox"
            name="trip_permissions"
            value="add_trip"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="addtripCheckbox"
          >
            Add trip
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="edittripCheckbox"
            name="trip_permissions"
            value="edit_trip"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="edittripCheckbox"
          >
            Edit trip
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="deltripCheckbox"
            name="trip_permissions"
            value="delete_trip"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="deltripCheckbox"
          >
            Delete trip
          </Label>
        </div>
      </div>
    </div>
  );
  return (
    <>
      <Card>
        <CardBody>
          <h4 className="card-title">Update User Role</h4>
          <AvForm className="needs-validation" onSubmit={handleSubmit}>
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="validationCustom03">User Role Name</Label>
                  <AvField
                    name="role_name"
                    placeholder="User role name"
                    type="text"
                    errorMessage=" Enter User Role Name."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom03"
                    value={roleName}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="status1">Status</Label>
                  <AvField
                    name="status"
                    type="select"
                    errorMessage="Please provide a valid destination."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="status1"
                    value={status}
                  >
                    <option value="">Select Status</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </AvField>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <Label>Permissions(Check the applicable permissions).</Label>
                  {user}
                  {userRole}
                  {staffs}
                  {bus}
                  {route}
                  {service}
                  {trip}
                </div>
              </Col>
            </Row>
            <div className="d-flex justify-content-end">
              <Button color="primary" type="submit">
                Submit
              </Button>
            </div>
          </AvForm>
        </CardBody>
      </Card>
      <br />
    </>
  );
};

// Export the AddUser component


export default UpdateUserRole;
