import styled from "styled-components";

export const Wrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;
  /* border-top: 1px solid #000000; */
  padding-top: 10px;
`;
export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Text = styled.div`
  text-align: center;
  color: #000000;
`;
