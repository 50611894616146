import React, { useEffect } from "react";
import { changeHeading } from "../../features/topbarHeadingSlice";
import { useDispatch, useSelector } from "react-redux";
import { getLoggedInUser } from "../../features/userSlice";
import { AppDispatch, RootState } from "../../app/Store";
import {
 
  Wrapper,ListSection , SectionTitle, TitleWrapper,Item, List, ListWrapper, Value, UserImg
} from "./SyledProfile";
import ProfileImageSrc from "../../assets/profileavtar.jpg";


const Profile: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { loggedInUser } = useSelector((state: RootState) => state.user);
  console.log(loggedInUser);

  useEffect(() => {
    dispatch(
      changeHeading({
        heading: "Profile",
        isBackBtn: true,
  breadcrumbItems:[]

      })
    );
    dispatch(getLoggedInUser());
  }, []);
  return (
    
    <Wrapper>
      <ListSection className="shadow">
      <TitleWrapper>
          <SectionTitle>User Profile</SectionTitle>
          </TitleWrapper>
          <ListWrapper>
            <List>
            <Item>
                <UserImg src={ProfileImageSrc} alt="" key={""}/>
              </Item>
            </List>
            <List>
              
              <Item>
                <Value>Employee ID</Value>
                <Value>Username</Value>
                <Value>Email</Value>
              </Item>
              <Item>
                <Value>{loggedInUser?.empid}</Value>
                <Value>{loggedInUser?.username}</Value>
                <Value>{loggedInUser?.email}</Value>
                
              </Item>
            </List>
          </ListWrapper>
      </ListSection>
    </Wrapper>
  );
};

export default Profile;
