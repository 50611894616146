import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as endpoints from "../networkUtility/endpoint";
import { toggleLoader } from "./loaderSlice";
import { toast } from "react-toastify";
import * as localStorageActionType from "../localStorage/ActionTypes";
import { getLocalStorage } from "../localStorage/GetLocalStorage";
import { handle500Status } from "../utility/Utility";

interface initialStateProp {
  allTrips: any;
  trip: any;
  isSuccess: boolean;
}

const initialState: initialStateProp = {
  allTrips: [],
  trip: {},
  isSuccess: false,
};

interface allTripsProp {
  allTrips: any;
}
interface tripProp {
  trip: any;
}

interface bustripProp {
  isSuccess: boolean;
}

interface addtripPayload {
  bus_number: string;
  route_number: string;
  service_id: string;
  start_date: string;
  end_date: string;
}
interface updatetripPayload {
  id: any;
  bus_number: string;
  route_number: string;
  service_id: string;
  start_date: string;
  end_date: string;
}
export const getAllTrips = createAsyncThunk(
  "get all trips", // Adjusted action name for clarity
  async (payload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(endpoints.allTrips, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });

      dispatch(toggleLoader({ isLoading: false }));

      // Check if the response status is 200
      if (response.status === 200) {
        // toast.success("trip Data Fetch Successfully!.");
        const data = await response.json();
        // console.log(data);
        dispatch(
          setAllTrips({
            allTrips: data,
          })
        );
        // Check the statusCode from the API response
      } else if (response.status === 500) {
        // handle500Status();
        toast.error("internal server error.");
      } else {
        // Handle non-200 status codes here
        toast.error("Sorry, Something went wrong!.");
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error) {
      // Handle fetch or JSON parsing errors here
      console.error(error);
      toast.error("An error occurred. Please try again."); // You can customize the error message
    } finally {
      console.log("Finally");
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const addNewtrip = createAsyncThunk(
  "add new trip",
  async (payload: addtripPayload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(endpoints.addTrip, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      if (response.status === 201) {
        toast.success(data.messsage);
        // console.log(data);
        dispatch(
          settripState({
            isSuccess: true,
          })
        );
        dispatch(getAllTrips());
      } else {
        toast.error(data.error);
      }

      if (data.code === 500) {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error adding new route:", error);
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const deletetripHandler = createAsyncThunk(
  "delete trip from db",
  async (payload: any, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(`${endpoints.deleteTrip}${payload}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });
      const data = await response.json();
      if (response.status === 200) {
        toast.success(data.message);
        dispatch(getAllTrips());
        // console.log(data);
      } else {
        toast.success(data.error);
      }
    } catch (error) {
      console.error(error);
      toast.error("Sorry!, Something went wrong.");
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const gettripDetail = createAsyncThunk(
  "get trip details",
  async (payload: any, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(`${endpoints.viewTrip}${payload}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });

      const data = await response.json();
      if (response.status === 200) {
        dispatch(
          settripDetails({
            trip: data,
          })
        );
        toast.success("trip data fetch successfully.");
        console.log(data);
      } else if (data.code === 404) {
        toast.error(data.message);
      } else {
        toast.error(data.error);
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const updatetrip = createAsyncThunk(
  "update trip",
  async (payload: updatetripPayload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const bodyObj = {
        bus_number: payload.bus_number,
        route_number: payload.route_number,
        service_id: payload.service_id,
        start_date: payload.start_date,
        end_date: payload.end_date,
      };

      const response = await fetch(`${endpoints.editTrip}${payload.id}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
        body: JSON.stringify(bodyObj),
      });

      const data = await response.json();
      if (response.status === 200) {
        toast.success(data.message);
        // console.log(data);
        dispatch(
          settripState({
            isSuccess: true,
          })
        );
        dispatch(getAllTrips());
      } else {
        toast.error(data.error);
      }

      if (data.code === 500) {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error adding new route:", error);
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

const tripSlice = createSlice({
  name: "trip slice",
  initialState: initialState,
  reducers: {
    setAllTrips: (state, action: PayloadAction<allTripsProp>) => {
      return {
        ...state,
        allTrips: action.payload.allTrips,
      };
    },
    settripDetails: (state, action: PayloadAction<tripProp>) => {
      return {
        ...state,
        trip: action.payload.trip,
      };
    },
    settripState: (state, action: PayloadAction<bustripProp>) => {
      return {
        ...state,
        isSuccess: action.payload.isSuccess,
      };
    },
    resettripState: (state) => {
      return {
        ...state,
        isSuccess: false,
      };
    },
  },
});

export const { setAllTrips, settripState, resettripState, settripDetails } =
  tripSlice.actions;
export default tripSlice.reducer;
