import styled from "styled-components";

export const ModalWrapper = styled.div`
  // width:100%;
  padding: 20px 50px 10px 50px;
`;
export const ModalImg = styled.div``;
export const ModalMessege = styled.h3`
  color: #000000;
`;
export const ModalAction = styled.h2`
  display: flex;
  justify-content: flex-end;
  gap: 5px;
`;
