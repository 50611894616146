// import React, { useEffect, useState } from "react";
// import { MDBDataTable } from "mdbreact";
// import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap";

// import "../datatables.scss";
// import { useNavigate } from "react-router-dom";

// import { useDispatch } from "react-redux";
// import { AppDispatch } from "../../../app/Store";

// interface RowData {
//   id: string;
//   compliance_status:string;
//   bus_number: string;
//   fitness_end_date: string;
//   mother_permit_expiry_date: string;
//   mother_permit_authorization_end_date: string;
//   mother_road_tax_expiry_date: string;
//   mother_road_tax_authorization_end_date: string;
//   all_india_tourist_permit_expiry_date: string;
//   insurance_end_date: string;
//   emission_end_date: string;
// }

// interface ColumnData {
//   label: string;
//   field: string;
//   sort?: string;
//   width?: number;
// }

// interface TableProp {
//   columns: ColumnData[];
//   rows: RowData[];
// }

// const ComplianceTable: React.FC<TableProp> = ({ columns, rows }) => {
//   const navigate = useNavigate();
//   const dispatch: AppDispatch = useDispatch();

//   const data = {
//     columns: columns,
//     rows: rows.map(row => ({
//       ...row,
//     }))
//   };

//   return (
//     <React.Fragment>
//       <Row>
//         <Col className="col-12">
//           <Card>
//             <CardBody>
//               <CardTitle className="d-flex justify-content-end"></CardTitle>
//               {/* Pass custom CSS class to the MDBDataTable component */}
//               <MDBDataTable
//                 responsive
//                 bordered
//                 noBottomColumns
//                 data={data}

//               />
//             </CardBody>
//           </Card>
//         </Col>
//       </Row>
//     </React.Fragment>
//   );
// };

// export default ComplianceTable;

import React, { useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/Store";

interface RowData {
  id: string;
  compliance_status: string;
  bus_number: string;
  fitness_end_date: string;
  mother_permit_expiry_date: string;
  mother_permit_authorization_end_date: string;
  mother_road_tax_expiry_date: string;
  mother_road_tax_authorization_end_date: string;
  all_india_tourist_permit_expiry_date: string;
  insurance_end_date: string;
  emission_end_date: string;
}

interface TableProp {
  rows: RowData[];
  columns: any;
}

const ComplianceTable: React.FC<TableProp> = ({ columns, rows }) => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  // Define a function to determine cell class based on compliance_status
  const getCellClass = (compliance_status: string) => {
    return compliance_status.toLowerCase() !== "compliant" ? "red-bg" : "green-bg";
  };

  const getRowClass = (compliance_status: string) => {
    return compliance_status.toLowerCase() === "compliant" ? "green-bg" : "red-bg";
  };
  
  const getCellBackgroundColor = (compliance_status: string) => {
    return compliance_status.toLowerCase() !== "compliant" ? "#ff9c9c" : "#a0dea1";
  };

  const data = {
    columns: [
      // { label: "ID", field: "id" },
      { label: "Bus Number", field: "bus_number" },
      { label: "Compliance Status", field: "compliance_status"},
      // { label: "Bus Number", field: "bus_number" },
      { label: "Fitness End Date", field: "fitness_end_date" },
      {
        label: "Mother Permit Expiry Date",
        field: "mother_permit_expiry_date",
      },
      {
        label: "Mother Permit Authorization End Date",
        field: "mother_permit_authorization_end_date",
      },
      {
        label: "Mother Road Tax Expiry Date",
        field: "mother_road_tax_expiry_date",
      },
      {
        label: "Mother Road Tax Authorization End Date",
        field: "mother_road_tax_authorization_end_date",
      },
      {
        label: "All India Tourist Permit Expiry Date",
        field: "all_india_tourist_permit_expiry_date",
      },
      { label: "Insurance End Date", field: "insurance_end_date" },
      { label: "Emission End Date", field: "emission_end_date" },
      // Add more columns as needed
    ],
    rows: [...rows].sort((a, b) => {

      if (a.compliance_status.toLowerCase() === "non-compliant" && b.compliance_status.toLowerCase() !== "non-compliant") {
        return -1;
      } else if (a.compliance_status.toLowerCase() !== "non-compliant" && b.compliance_status.toLowerCase() === "non-compliant") {
        return 1;
      } else {
        return 0;
      }

    }).map((row) => ({
      ...row,
      // compliance_status: {
      //   value: row.compliance_status,
      //   attributes: {
      //     style: {
      //       backgroundColor: getCellBackgroundColor(row.compliance_status),
      //     },
      //   },
      // },
      
      // className: row.compliance_status.toString().toLowerCase().includes("compliant") ? "green-bg" : "red-bg",
      compliance_status: (
        <div className={getRowClass(row.compliance_status)}>
          <span>{row.compliance_status.toUpperCase()}</span>
        </div>
      ),
    })),
  };

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="d-flex justify-content-end"></CardTitle>
              <div className="table-container">
                <MDBDataTable
                  responsive
                  bordered
                  noBottomColumns
                  data={data}
                  // className="custom-table"
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ComplianceTable;
