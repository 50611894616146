import styled from "styled-components";
import Box from "@mui/material/Box";
import ClearIcon from "@mui/icons-material/Clear";
import bg from "../../assets/ksmbgnew.jpg";

import * as UIConstants from "../../constants/UIConstants";

export const ModalHeadre = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  font-weight: 500;
`;

export const HeaderTitle = styled.div`
  text-transform: capitalize;
  font-size: 20px;
  color: #000000;
`;

export const CloseIcon = styled(ClearIcon)`
  height: 30px;
  width: 38px;
  cursor: pointer;
  color: #000000;
`;

export const StyledBox = styled(Box)`
  position: absolute;
  text-align: center;

  background-color: #ffffff;
  border-radius: 10px;
  border: none;
  ${UIConstants.customView} {
    margin: 10px;
    left: 0%;
    right: 0%;
  }
`;
