import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as endpoints from "../networkUtility/endpoint";
import { toggleLoader } from "./loaderSlice";
import { toast } from "react-toastify";
import * as localStorageActionType from "../localStorage/ActionTypes";
import { getLocalStorage } from "../localStorage/GetLocalStorage";
import { handle500Status } from "../utility/Utility";

interface initialStateProp {
  permissions: any;
}

const initialState: initialStateProp = {
  permissions: [],
};

interface permissionsProp {
  permissions: any;
}

export const getPermissions = createAsyncThunk(
  "get all permissions",
  async (payload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(endpoints.allPermisions, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });

      dispatch(toggleLoader({ isLoading: false }));

      if (response.status === 200) {
        // toast.success("All Buses Fetch successfully!.");
        const data = await response.json();
        dispatch(setPermissions({ permissions: data }));
        console.log(data);
      } else if (response.status === 500) {
        // handle500Status();
      } else {
        // Handle non-200 status codes here
        // toast.error("Sorry, Something went wrong!.");

        console.error(`Error: ${response.status}`);
      }
    } catch (error) {
      // Handle fetch or JSON parsing errors here
      console.error(error);
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

const permissionsSlice = createSlice({
  name: "Dashboard slice",
  initialState: initialState,
  reducers: {
    setPermissions: (state, action: PayloadAction<permissionsProp>) => {
      return {
        ...state,
        permissions: action.payload.permissions,
      };
    },
  },
});

export const { setPermissions } = permissionsSlice.actions;
export default permissionsSlice.reducer;
