import React, { useEffect, useState } from "react";

import { Wrapper } from "../../Schedules/StopsList/StyledStopList";

import { useDispatch, useSelector } from "react-redux";
import { changeHeading } from "../../../features/topbarHeadingSlice";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../../app/Store";
import { getAllServices, resetServiceState } from "../../../features/serviceSlice";
import ServiceTable from "../../Tables/Service/ServiceTable";

const ServicesList: React.FC = () => {
  document.title = "Services | KSM - Admin Dashboard.";

  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { allServices } = useSelector((state: RootState) => state.services);

  useEffect(() => {
    dispatch(
      changeHeading({
        heading: "Services",
        isBackBtn: false,
        breadcrumbItems: [
          { title: "Ksm", link: "#" },
          { title: "Services", link: "#" },
        ],
      })
    );
    dispatch(getAllServices());
    dispatch(resetServiceState());

  }, []);

  const Dtcolumns = [
    {
      label: "Id",
      field: "id",
      sort: "asc",
      width: 150,
    },
    {
      label: "Service Id",
      field: "service_id",
      sort: "asc",
      width: 150,
    },
    {
      label: "Service Name",
      field: "service_name",
      sort: "asc",
      width: 150,
    },
    {
      label: "Route Number",
      field: "route_number",
      sort: "asc",
      width: 150,
    },
    {
      label: "Service Status",
      field: "service_status",
      sort: "asc",
      width: 150,
    },
    
  ];

  return <Wrapper>
    <ServiceTable columns={Dtcolumns} rows={allServices}/>
    
  </Wrapper>;
};

export default ServicesList;
