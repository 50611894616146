// Import necessary React and library components
import React, { useState } from "react";
import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import { useNavigate } from "react-router-dom";

// Import styles if needed
import "../datatables.scss";
import { routerPath } from "../../../routes/routes";
import ActionModal from "../../../components/Modal/ActionModal/ActionModal";
import { AppDispatch } from "../../../app/Store";
import { useDispatch } from "react-redux";
import { deleteBusHandler } from "../../../features/busSlice";
import { FaRegTrashAlt } from "react-icons/fa";
import { PiEyeBold, PiNotePencilBold } from "react-icons/pi";

// Import necessary interfaces
interface RowData {
  id: any;
  bus_name: string;
  bus_number: string;
  bus_make: string;
  bus_model: string;
}

interface ColumnData {
  label: any;
  field: string;
  sort?: string;
  width?: number;
}

interface TableProp {
  columns: ColumnData[];
  rows: RowData[];
}

// Define the BusTable component
const BusTable: React.FC<TableProp> = ({ columns, rows }) => {
  // Initialize the navigate function from react-router-dom
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  // Add a new column for the "View" button
  const newColumns = [
    ...columns,
    {
      label: <div className="text-center">Actions</div>,
      field: "actions",
      width: 100,
    },
  ];

  // Configure data for MDBDataTable with a custom "View" button
  const data = {
    columns: newColumns,
    rows: rows.map((rowData) => ({
      ...rowData,
      actions: (
        // <div className="d-flex justify-content-around align-items-center ">
        <div className="d-flex flex-wrap gap-1 justify-content-center align-items-center">
          <Button
            type="button"
            color="primary"
            className="waves-effect waves-light"
            onClick={() => handleViewClick(rowData.id)}
          >
            <PiEyeBold style={{ fontSize: "18px" }} />
          </Button>
          <Button
            type="button"
            color="info"
            className="waves-effect waves-light"
            onClick={() => navigate(routerPath.updateBus(rowData.id))}
            title="Update"

          >
            <PiNotePencilBold style={{ fontSize: "18px" }}/>
          </Button>
          <Button
            type="button"
            color="danger"
            className="waves-effect waves-light"
            onClick={() => handleDeleteModal(rowData.id)}
          >
            <FaRegTrashAlt style={{ fontSize: "18px" }} />
          </Button>
        </div>
      ),
    })),
  };

  // Define the function to handle "View" button click
  const handleViewClick = (busId: any) => {
    // Navigate to the bus detail page with the provided bus_id
    navigate(routerPath.busDetail(busId));
  };

  // Render the component structure

  const handleDeleteModal = (id: any) => {
    setOpenDeleteModel(true);
    setSelectedId(id);
    console.log(id);
  };
  const confirmDelete = () => {
    setOpenDeleteModel(false);
    dispatch(deleteBusHandler(selectedId));
  };
  return (
    <React.Fragment>
      <ActionModal
        openModal={openDeleteModel}
        setOpenModal={setOpenDeleteModel}
        confirm={confirmDelete}
        title={"Delete"}
        content={"bus"}
      />

      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="d-flex justify-content-end">
                <Button
                  type="button"
                  color="primary"
                  className="waves-effect waves-light"
                  onClick={() => navigate(routerPath.add_bus)}
                >
                  Add Bus
                </Button>{" "}
              </CardTitle>
              <MDBDataTable responsive bordered noBottomColumns data={data} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

// Export the BusTable component
export default BusTable;
