import React, { FC, useState } from "react";
import Modal from "../Modal";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import SimpleStopsTable from "../../../containers/Tables/SimpleStopsTable/SimpleStopsTable";
import { ModalMessege, ModalWrapper } from "../ActionModal/StyleDeleteModal";

interface Stop {
    index: number;
    stop_name: string;
    stop_time: string;
    current_stop_audio: string;
    next_stop_audio: string;
}

interface ModalProp {
  openModal: boolean;
  setOpenModal: any;
  confirm: any;
  stops: Stop[];
  index: any;

}

const AddServceStopModal: FC<ModalProp> = ({
  openModal,
  setOpenModal,
  confirm,
  stops,
  index
}) => {
  const [selectedStop, setSelectedStop] = useState<Stop | null>(null);

  const handleStopSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedStopName = event.target.value;
    const stopObj = stops.find((stop: Stop) => stop.stop_name === selectedStopName);
    if (stopObj) {
      setSelectedStop(stopObj);
    }
  };

  const handleSubmit = (event: any, errors: any, values: any) => {
    if (errors.length === 0 && selectedStop) {
    //   const formattedData = {
    //     ...values,
    //     announcementPoint: selectedStop,
    //   };
    const bodyObj={
    current_stop_audio:selectedStop.current_stop_audio,
    next_stop_audio:selectedStop.next_stop_audio,
    stop_name:selectedStop.stop_name,
    stop_time:selectedStop.stop_time
    }
    //   console.log(bodyObj);
      confirm(bodyObj);
      setSelectedStop(null)
    }
  };

  return (
    <>
      <Modal
        isOpen={openModal}
        name={"Create add service stop modal"}
        title={`Add Stop`}
        toggleModal={() => setOpenModal(false)}
      >
         <ModalWrapper style={{textAlign:"left"}}>
          <ModalMessege>
            Select stop from list and add to the postion {index + 1}.
           </ModalMessege>
           <Card>
        <CardBody>
          <AvForm className="needs-validation" onSubmit={handleSubmit}>
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <AvField
                    name="stop"
                    type="select"
                    errorMessage="Please select stop."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    onChange={handleStopSelect}
                  >
                    <option value="">Select Stops</option>
                    {stops.map((stop: Stop) => (
                      <option key={stop.stop_name} value={stop.stop_name}>
                        {stop.stop_name}
                      </option>
                    ))}
                  </AvField>
                </div>
              </Col>
            </Row>
            {selectedStop && (
              <SimpleStopsTable
                selectedStops={[selectedStop]}
                handleRemoveStop={() => setSelectedStop(null)}
              />
            )}
            <div className="d-flex justify-content-end gap-2">
              <Button
                type="button"
                color="primary"
                className="waves-effect waves-light"
                onClick={() => setOpenModal(false)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="danger"
                className="waves-effect waves-light"
              >
                Add
              </Button>
            </div>
          </AvForm>
        </CardBody>
        </Card>
        </ModalWrapper>
      </Modal>
    </>
  );
};

export default AddServceStopModal;

