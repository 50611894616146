import React, { useEffect, useState } from "react";
import { Wrapper } from "../StopsList/StyledStopList";

import { useDispatch, useSelector } from "react-redux";
import { changeHeading } from "../../../features/topbarHeadingSlice";

import {  getAllBuses, resetBusState } from "../../../features/busSlice";
import { AppDispatch, RootState } from "../../../app/Store";
import BusTable from "../../Tables/Bus/BusTable";

const Buses: React.FC = () => {
  document.title = "Buses | KSM - Admin Dashboard.";
  const {allBuses} = useSelector((state:RootState)=>state.buses);


  const dispatch: AppDispatch = useDispatch();
 

  useEffect(() => {
    dispatch(
      changeHeading({
        heading: "Buses",
        isBackBtn: false,
        breadcrumbItems: [
          { title: "Ksm", link: "#" },
          { title: "Buses", link: "#" },
        ],
      })
    );
    dispatch(resetBusState());

    dispatch(getAllBuses());
  }, []);

  const Dtcolumns = [
    {
      label: "Id",
      field: "id",
      sort: "asc",
      width: 150,
    },
    {
      label: "Bus Name",
      field: "bus_name",
      sort: "asc",
      width: 150,
    },
    {
      label: "Bus Number",
      field: "bus_number",
      sort: "asc",
      width: 150,
    },
    {
      label: "Bus Make",
      field: "bus_make",
      sort: "asc",
      width: 150,
    },
    {
      label: "Bus Model",
      field: "bus_model",
      sort: "asc",
      width: 150,
    },
  ];

  return <Wrapper>
    <BusTable columns={Dtcolumns} rows={allBuses}/>
  </Wrapper>;
};

export default Buses;
