import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap";

import "../datatables.scss";
import { useNavigate } from "react-router-dom";
import { routerPath } from "../../../routes/routes";
import { FaRegTrashAlt } from "react-icons/fa";
import { PiEyeBold, PiNotePencilBold } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/Store";
import ActionModal from "../../../components/Modal/ActionModal/ActionModal";
import { deletecleanerHandler } from "../../../features/cleanerSlice";
import { getAllUsers } from "../../../features/userSlice";

interface RowData {
  id:any;
  user_id:any;

username:string;
employee_id:string;
cleaner_name:string;
phone_number:string;
date_of_joining:string;
}

interface ColumnData {
  label: string;
  field: string;
  sort?: string;
  width?: number;
}

interface TableProp {
  columns: ColumnData[];
  rows: RowData[];
}

const CleanerTable: React.FC<TableProp> = ({ columns, rows }) => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const {allUsers}=useSelector((state:RootState)=>state.user);
  console.log(allUsers)

  useEffect(()=>{
  dispatch(getAllUsers());
  },[])
  const getUserName=(id:any)=>{
    return allUsers.map((user:any)=>{
      if(user.id===id){
        // console.log(user.username);
        return user.username;
      }
    })
  }

  const newColumns = [
    ...columns,
    {
      label: 'Actions',
      field: "actions",
      width: 100,
    },
  ];

 
  const handleDeleteModal = (id: any) => {
    setOpenDeleteModel(true);
    setSelectedId(id);
    console.log(id);
  };
  const confirmDelete = () => {
    setOpenDeleteModel(false);
    dispatch(deletecleanerHandler(selectedId));
  };

  // Define the function to handle "View" button click
  const handleViewClick = (id: any) => {
    // Navigate to the bus detail page with the provided bus_id
    navigate(routerPath.cleanerDetail(id));
  };

  const data = {
    columns: newColumns,
    rows: rows.map((rowData) => ({
      ...rowData,
      username:getUserName(rowData.user_id),
      actions: (
        // <div className="d-flex justify-content-around align-items-center " >
            <div className="d-flex flex-wrap gap-1 justify-content-center align-items-center">

          <Button
            type="button"
            color="primary"
            className="waves-effect waves-light"
            onClick={() => handleViewClick(rowData.id)}
            title="View"
          >
            <PiEyeBold style={{ fontSize: "18px" }}/>
          </Button>
          <Button
            type="button"
            color="info"
            className="waves-effect waves-light"
            onClick={() =>navigate(routerPath.updateCleaner(rowData.id))}
            title="Update"

          >
            <PiNotePencilBold style={{ fontSize: "18px" }}/>
          </Button>
          <Button
            type="button"
            color="danger"
            className="waves-effect waves-light"
            onClick={() => handleDeleteModal(rowData.id)}
            title="Delete"

          >
            <FaRegTrashAlt style={{ fontSize: "18px" }}/>
          </Button>
        
        </div>
      ),
    })),
  };

  return (
    <React.Fragment>
      <ActionModal
        openModal={openDeleteModel}
        setOpenModal={setOpenDeleteModel}
        confirm={confirmDelete}
        title={"Delete"}
        content={"Cleaner"}
      />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="d-flex justify-content-end">
              <Button
                  type="button"
                  color="primary"
                  className="waves-effect waves-light"
                  onClick={() => navigate(routerPath.add_cleaner)}
                  // onClick={() => navigate(routerPath.construction)}

                >
                  Add Cleaner
                </Button>{" "}
              </CardTitle>
              <MDBDataTable responsive bordered noBottomColumns data={data} />
            </CardBody>
          </Card>
        </Col>
      </Row>

      
    </React.Fragment>
  );
};

export default CleanerTable;
