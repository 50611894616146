import React, { Component } from "react";
import C3Chart from "react-c3js";
import "c3/c3.css";

class DonutChart extends Component {
  render() {
    const data = {
      columns: [
        ["Download Sales", 100, 0],
        ["In-Store Sales", 100, 0],
        ["Mail-Order Sales", 0, 100],
      ],
      type: "donut",
    };

    const donut = {
      title: "In-Store Sales 30",
      width: 30,
      label: { show: false }, // Labels are not shown
    };

    const color = {
      pattern: ["#116DAA", "#2092DE", "#2C5773"],
    };

    const size = {
      height: 300,
    };

    return (
      <React.Fragment>
        <C3Chart
          data={data}
          donut={donut}
          color={color}
          size={size}
          dir="ltr"
        />
      </React.Fragment>
    );
  }
}

export default DonutChart;
