import { FC, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import HeaderLogoUrl from "../../assets/ksmlogoyellow.png";
import { routerPath } from "../../routes/routes";
import {
  StyledWrapper,
  NavLogo,
  LogoSection,
  NavMenuItem,
  NavIcon,
  NavLabel,
  NavItems,
  Option,
  SubmenuContainer,
  LabelAndArrow,
  LeftMenuSection,
  StyledMenu,
  DotSingleIcon,
  DownIcon,
  ScheduleBus,
  BusRoute,
  BusStop,
  Bus,
  UserIcon,
  LeftIcon,
  MasterIcon,
  TripIcon,
  EmployeeIcon,
  BusAlert,
} from "./StyledLeftmenu";
import { DashboardIcon1 } from "./StyledLeftmenu";

interface SubmenuOption {
  submenuLabel: string;
  isActive: boolean;
  routePath: string;
  activeRoutePaths: string[];
  iconName: string;
}

interface NavResponse {
  label: string;
  isActive: boolean;
  id: string;
  position: number;
  isVisible: boolean;
  routePath: string;
  isSubmenuExists: boolean;
  isSubmenuExpanded: boolean;
  iconName: string;
  submenu?: SubmenuOption[];
  activeRoutePaths: string[];
}

let leftMenuResponse: NavResponse[] = [
  {
    label: "Dashboard",
    isActive: true,
    id: "dashboard_1",
    iconName: "DashboardIcon",
    position: 1,
    isVisible: true,
    routePath: routerPath.dashboard,
    isSubmenuExists: false,
    isSubmenuExpanded: false,
    activeRoutePaths: [routerPath.dashboard, routerPath.rootPath],
    submenu: [
     
    ],
  },
  {
    label: "Trip",
    isActive: false,
    id: "trip_2",
    iconName: "TripIcon",
    position: 2,
    isVisible: true,
    routePath: routerPath.trip,
    isSubmenuExists: false,
    isSubmenuExpanded: false,
    activeRoutePaths: [routerPath.trip],
    submenu: [
     
    ],
  },
  {
    label: "Compliance",
    isActive: false,
    id: "ompliance_2",
    iconName: "BusAlertIcon",
    position: 4,
    isVisible: true,
    routePath: routerPath.compliance,
    isSubmenuExists: false,
    isSubmenuExpanded: false,
    activeRoutePaths: [routerPath.compliance],
    submenu: [
     
    ],
  },
  {
    label: "Master List",
    isActive: false,
    id: "schedules_3",
    iconName: "MasterIcon",
    position: 4,
    isVisible: true,
    routePath: routerPath.servicesList,
    isSubmenuExists: true,
    isSubmenuExpanded: false,
    activeRoutePaths: [routerPath.servicesList,routerPath.buses,routerPath.route,routerPath.stopList],
    submenu: [
      {
        submenuLabel: "Services",
        isActive: true,
        routePath: routerPath.servicesList,
        activeRoutePaths: [routerPath.servicesList],
        iconName: "",
      },

      {
        submenuLabel: "Buses",
        isActive: false,
        routePath: routerPath.buses,
        activeRoutePaths: [routerPath.buses],
        iconName: "",
      },

      {
        submenuLabel: "Routes",
        isActive: false,
        routePath: routerPath.route,
        activeRoutePaths: [routerPath.route],
        iconName: "",
      },
      {
        submenuLabel: "Stops",
        isActive: false,
        routePath: routerPath.stopList,
        activeRoutePaths: [routerPath.stopList],
        iconName: "",
      },
    ],
  },
 
  {
    label: "Users",
    isActive: false,
    id: "users_7",
    iconName: "UserIcon",
    position: 5,
    isVisible: true,
    routePath: routerPath.users,
    isSubmenuExists: true,
    isSubmenuExpanded: false,
    activeRoutePaths: [routerPath.users, routerPath.user_role],
    submenu: [
      {
        submenuLabel: "User",
        isActive: true,
        routePath: routerPath.users,
        activeRoutePaths: [routerPath.users],
        iconName: "",
      },

      {
        submenuLabel: "User Role",
        isActive: false,
        routePath: routerPath.user_role,
        activeRoutePaths: [routerPath.user_role],
        iconName: "",
      },
    ],
  },
  {
    label: "Employees",
    isActive: false,
    id: "list_8",
    iconName: "EmployeeIcon",
    position: 6,
    isVisible: true,
    routePath: routerPath.driver,
    isSubmenuExists: true,
    isSubmenuExpanded: false,
    activeRoutePaths: [routerPath.driver, routerPath.staff,routerPath.cleaner],
    submenu: [
      {
        submenuLabel: "Driver",
        isActive: true,
        routePath: routerPath.driver,
        activeRoutePaths: [routerPath.driver],
        iconName: "",
      },

      {
        submenuLabel: "Staff",
        isActive: false,
        routePath: routerPath.staff,
        activeRoutePaths: [routerPath.staff],
        iconName: "",
      },
      {
        submenuLabel: "Cleaner",
        isActive: false,
        routePath: routerPath.cleaner,
        activeRoutePaths: [routerPath.cleaner],
        iconName: "",
      },
    ],
  },
  
];

const LeftMenuDesktop: FC = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const [menuResponse, setMenuResponse] =
    useState<NavResponse[]>(leftMenuResponse);

  useEffect(() => {
    if (menuResponse.length === 0) {
      setMenuResponse(leftMenuResponse);
    }
  }, []);

  const isActivePath = (routePaths: string | string[]): boolean => {
    const currentPath = location.pathname;

    if (Array.isArray(routePaths)) {
      // Check if the current path matches any of the provided route paths in the array
      return routePaths.some((path) => currentPath === path);
    }

    // For single route path, check equality with current path
    return currentPath === routePaths;
  };

  const redirectToView = (path: string): void => {
    navigate(path);
  };

  const toggleCollapseState = (routerObj: any) => {
    let updatedMenuItems: NavResponse[] = [];
    for (let menuObj of menuResponse) {
      let updatedObj = {
        ...menuObj,
        isSubmenuExpanded:
          menuObj.id === routerObj.id ? !routerObj.isSubmenuExpanded : false,
      };
      updatedMenuItems.push(updatedObj);
    }
    setMenuResponse(updatedMenuItems);
    redirectToView(routerObj.routePath);
  };

  const submenuView = (submenuArray: SubmenuOption[] | any) => {
    return submenuArray.map((obj: SubmenuOption) => {
      return (
        <Option
          isActive={isActivePath(obj.activeRoutePaths)}
          onClick={() => {
            redirectToView(obj.routePath);
          }}
        >
          {/* {obj.iconName === "" && <DotSingleIcon />}
          {obj.iconName === "Bus" && (
            <Bus isActive={isActivePath(obj.activeRoutePaths)} />
          )}
          {obj.iconName === "BusRoute" && (
            <BusRoute isActive={isActivePath(obj.activeRoutePaths)} />
          )}
          {obj.iconName === "BusStop" && (
            <BusStop isActive={isActivePath(obj.activeRoutePaths)} />
          )} */}

          <span>{obj.submenuLabel}</span>
        </Option>
      );
    });
  };

  let menuItems = menuResponse.map((menuObj) => {
    return (
      <>
        <NavMenuItem
          onClick={() => {
            toggleCollapseState(menuObj);
          }}
          isActive={isActivePath(menuObj.activeRoutePaths)}
        >
          <NavIcon>
            {menuObj.iconName === "DashboardIcon" && (
              <DashboardIcon1
                isActive={isActivePath(menuObj.activeRoutePaths)}
              />
            )}
            {menuObj.iconName === "BusAlertIcon" && (
              <BusAlert isActive={isActivePath(menuObj.activeRoutePaths)} />
            )}
            {menuObj.iconName === "ScheduleBus" && (
              <Bus isActive={isActivePath(menuObj.activeRoutePaths)} />
            )}
            {menuObj.iconName === "BusRoute" && (
              <BusRoute isActive={isActivePath(menuObj.activeRoutePaths)} />
            )}
            {menuObj.iconName === "BusStop" && (
              <BusStop isActive={isActivePath(menuObj.activeRoutePaths)} />
            )}
            {menuObj.iconName === "UserIcon" && (
              <UserIcon isActive={isActivePath(menuObj.activeRoutePaths)} />
            )}
            {menuObj.iconName === "MasterIcon" && (
              <MasterIcon isActive={isActivePath(menuObj.activeRoutePaths)} />
            )}
             {menuObj.iconName === "TripIcon" && (
              <TripIcon isActive={isActivePath(menuObj.activeRoutePaths)} />
            )}
             {menuObj.iconName === "EmployeeIcon" && (
              <EmployeeIcon isActive={isActivePath(menuObj.activeRoutePaths)} />
            )}
          </NavIcon>
          <NavLabel>
            <LabelAndArrow
              onClick={() => {
                toggleCollapseState(menuObj.id);
              }}
            >
              <span style={{ fontSize: "14px" }}>{menuObj.label}</span>
              {menuObj.isSubmenuExists && !menuObj.isSubmenuExpanded&&<LeftIcon />}
              {menuObj.isSubmenuExists && menuObj.isSubmenuExpanded&&<DownIcon />}
            </LabelAndArrow>
          </NavLabel>
        </NavMenuItem>
        {menuObj.isSubmenuExists && menuObj.isSubmenuExpanded && (
          <SubmenuContainer isSubmenuVisible={menuObj.isSubmenuExpanded}>
            {submenuView(menuObj.submenu)}
          </SubmenuContainer>
        )}
      </>
    );
  });

  return (
    <LeftMenuSection>
      <StyledWrapper>
        <LogoSection className="shadow">
          <NavLogo src={HeaderLogoUrl} />
        </LogoSection>
        <NavItems>{menuItems}</NavItems>
      </StyledWrapper>
    </LeftMenuSection>
  );
};

export default LeftMenuDesktop;
