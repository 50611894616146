import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface initialStateProp {
  heading: string;
  isBackBtn: boolean;
  breadcrumbItems: any[];
}

const initialState: initialStateProp = {
  heading: "KSM",
  isBackBtn: false,
  breadcrumbItems: [],
};

interface HeadingProp {
  heading: string;
  isBackBtn: boolean;
  breadcrumbItems: any[];
}

const topbarHeadingSlice = createSlice({
  name: "topbar heading slice",
  initialState: initialState,
  reducers: {
    changeHeading: (state, action: PayloadAction<HeadingProp>) => {
      return {
        ...state,
        heading: action.payload.heading,
        isBackBtn: action.payload.isBackBtn,
        breadcrumbItems: action.payload.breadcrumbItems,
      };
    },
  },
});

export const { changeHeading } = topbarHeadingSlice.actions;
export default topbarHeadingSlice.reducer;
