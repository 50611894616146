import React, { useEffect, useState } from "react";
import { Wrapper } from "./StyledStopList";

import { useDispatch, useSelector } from "react-redux";
import { changeHeading } from "../../../features/topbarHeadingSlice";
import { getAllStops, resetstopState } from "../../../features/stopSlice";
import { RootState } from "../../../app/Store";
import MasterStopTable from "../../Tables/Stop/MasterStopTable";

interface RowData {
  stopName: string;
  time: string;
  nextAudioUrl: string;
  prevAudioUrl: string;
}

const data:any[] = [
];

const StopsList: React.FC = () => {
  document.title = "Stops | KSM - Admin Dashboard.";

  const dispatch = useDispatch();
  const {allStops}=useSelector((state:RootState)=>state.stops)
  console.log(allStops)

  useEffect(()=>{
    dispatch(
      changeHeading({
        heading: "Stops",
        isBackBtn: false,
        breadcrumbItems: [
          { title: "Ksm", link: "#" },
          { title: "Stops", link: "#" },
        ],
      })
    );
    dispatch(getAllStops());
    dispatch(resetstopState());
  },[]);
  

  const Dtcolumns = [
    {
      label: "Id",
      field: "id",
      sort: "asc",
      width: 150,
    },
    {
      label: "Stop Name",
      field: "stop_name",
      sort: "asc",
      width: 150,
    },
  ];

  return (
    <Wrapper>
      <MasterStopTable columns={Dtcolumns} rows={allStops} />
    </Wrapper>
  );
};

export default StopsList;
