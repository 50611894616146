import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as endpoints from "../networkUtility/endpoint";
import { toggleLoader } from "./loaderSlice";
import { toast } from "react-toastify";
import * as localStorageActionType from "../localStorage/ActionTypes";
import { getLocalStorage } from "../localStorage/GetLocalStorage";
import { handle500Status } from "../utility/Utility";

interface initialStateProp {
  allStops: any;
  stop: any;
  isSuccess: boolean;
}

const initialState: initialStateProp = {
  allStops: [],
  stop: {},
  isSuccess: false,
};

interface allStopsProp {
  allStops: any;
}
interface stopProp {
  stop: any;
}

interface busstopProp {
  isSuccess: boolean;
}

interface addStopPayload {
  stop_name: any;
  stop_time: any;
  current_stop_audio: any;
  next_stop_audio: any;
}
interface updateStopPayload {
  id: any;
  stop_name: any;
  stop_time: any;
  current_stop_audio: any;
  next_stop_audio: any;
}
export const getAllStops = createAsyncThunk(
  "get all stops", // Adjusted action name for clarity
  async (payload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(endpoints.allStops, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });

      dispatch(toggleLoader({ isLoading: false }));

      // Check if the response status is 200
      if (response.status === 200) {
        // toast.success("stop Data Fetch Successfully!.");
        const data = await response.json();
        // console.log(data);
        dispatch(
          setAllStops({
            allStops: data,
          })
        );
        // Check the statusCode from the API response
      } else if (response.status === 500) {
        handle500Status();
      } else {
        // Handle non-200 status codes here
        toast.error("Sorry, Something went wrong!.");
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error) {
      // Handle fetch or JSON parsing errors here
      console.error(error);
      toast.error("An error occurred. Please try again."); // You can customize the error message
    } finally {
      console.log("Finally");
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const addNewStop = createAsyncThunk(
  "add new stop",
  async (payload: addStopPayload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const formData = new FormData();
      formData.append("stop_name", payload.stop_name);
      formData.append("stop_time", payload.stop_time);
      formData.append("current_stop_audio", payload.current_stop_audio);
      formData.append("next_stop_audio", payload.next_stop_audio);

      const response = await fetch(endpoints.addStop, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
        body: formData,
      });

      const data = await response.json();
      if (response.status === 201) {
        toast.success(data.messsage);
        // console.log(data);
        dispatch(
          setstopState({
            isSuccess: true,
          })
        );
        dispatch(getAllStops());
      } else {
        toast.error(data.error);
      }

      if (data.code === 500) {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error adding new route:", error);
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const deleteStopHandler = createAsyncThunk(
  "delete stop from db",
  async (payload: any, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(`${endpoints.deleteStop}${payload}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });
      const data = await response.json();
      if (response.status === 200) {
        toast.success(data.message);
        dispatch(getAllStops());
        // console.log(data);
      } else {
        toast.success(data.error);
      }
    } catch (error) {
      console.error(error);
      toast.error("Sorry!, Something went wrong.");
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const getStopDetail = createAsyncThunk(
  "get stop details",
  async (payload: any, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(`${endpoints.viewStop}${payload}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });

      const data = await response.json();
      if (response.status === 200) {
        dispatch(
          setstopDetails({
            stop: data,
          })
        );
        toast.success("Stop data fetch successfully.");
        console.log(data);
      } else if (data.code === 404) {
        toast.error(data.message);
      } else {
        toast.error(data.error);
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const updateStop = createAsyncThunk(
  "update stop",
  async (payload: updateStopPayload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const formData = new FormData();
      formData.append("stop_name", payload.stop_name);
      formData.append("stop_time", payload.stop_time);
      formData.append("current_stop_audio", payload.current_stop_audio);
      formData.append("next_stop_audio", payload.next_stop_audio);

      const response = await fetch(`${endpoints.editStop}${payload.id}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
        body: formData,
      });

      const data = await response.json();
      if (response.status === 200) {
        toast.success(data.messsage);
        // console.log(data);
        dispatch(
          setstopState({
            isSuccess: true,
          })
        );
        dispatch(getAllStops());
      } else {
        toast.error(data.error);
      }

      if (data.code === 500) {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error adding new route:", error);
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

const stopSlice = createSlice({
  name: "stop slice",
  initialState: initialState,
  reducers: {
    setAllStops: (state, action: PayloadAction<allStopsProp>) => {
      return {
        ...state,
        allStops: action.payload.allStops,
      };
    },
    setstopDetails: (state, action: PayloadAction<stopProp>) => {
      return {
        ...state,
        stop: action.payload.stop,
      };
    },
    setstopState: (state, action: PayloadAction<busstopProp>) => {
      return {
        ...state,
        isSuccess: action.payload.isSuccess,
      };
    },
    resetstopState: (state) => {
      return {
        ...state,
        isSuccess: false,
      };
    },
  },
});

export const { setAllStops, setstopState, resetstopState, setstopDetails } =
  stopSlice.actions;
export default stopSlice.reducer;
