import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeHeading } from '../../features/topbarHeadingSlice'
import { getAllCompliance } from '../../features/complianceSlice'
import { RootState } from '../../app/Store'
import ComplianceTable from '../Tables/Compliance/ComplianceTable'


const data:any[] = [
 ]
const ComplianceList = () => {
  document.title = "Compliance | KSM - Admin Dashboard.";
  const {allCompliance}=useSelector((state:RootState)=>state.compliance);

  console.log(allCompliance)

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(
          changeHeading({
            heading: "Compliance",
            isBackBtn: true,
            breadcrumbItems: [
              { title: "Ksm", link: "#" },
              { title: "Compliance", link: "#" },
            ],
          })
        );
        dispatch(getAllCompliance());
      }, []);
      // bus_number
      // status (Compliant or Non-compliant)
      // fitness_end_date
      // mother_permit_expiry_date
      // mother_permit_authorization_end_date
      // mother_road_tax_expiry_date
      // mother_road_tax_authorization_end_date
      // all_india_tourist_permit_expiry_date
      // insurance_end_date
      // emission_end_date
    const columnData=[
      {
        label: 'ID',
        field: 'id',
        sort: 'asc',
        width: 150
      },
        {
            label: 'Bus Number',
            field: 'bus_number',
            sort: 'asc',
            width: 150
          },
        {
            label: 'Status',
            field: 'compliance_status',
            sort: 'desc',
            width: 150
          },
        {
            label: 'Fitness End Date',
            field: 'fitness_end_date',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Mother Permit Expiry Date',
            field: 'mother_permit_expiry_date',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Mother Permit Authorization End Date',
            field: 'mother_permit_authorization_end_date',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Mother Road Tax Expiry Date',
            field: 'mother_road_tax_expiry_date',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Mother Road Tax Authorization End Date',
            field: 'mother_road_tax_authorization_end_date',
            sort: 'asc',
            width: 150
          },
          {
            label: 'All India Tourist Permit Expiry Date',
            field: 'all_india_tourist_permit_expiry_date',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Insurance End Date',
            field: 'insurance_end_date',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Emission End Date',
            field: 'emission_end_date',
            sort: 'asc',
            width: 150
          },
    ]
  return (
    <div>
     <ComplianceTable columns={columnData} rows={allCompliance}/>
    </div>
  )
}

export default ComplianceList;