import { styled } from "styled-components";
import * as UIConstants from "../../../constants/UIConstants";
import bg from "../../../assets/ksmbgnew.jpg";

export const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
`;

export const Heading = styled.div`
  font-size: 35px;
  font-weight: 500;
  color: white;
`;

export const ListSection = styled.div`
  margin-top: 10px;
  padding: 15px;
  /* background-color: rgba(44, 87, 115, 255); */
  background-image: url(${bg});

  border-radius: 5px;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  Button {
    border-radius: px;
  }
`;

export const SectionTitle = styled.div`
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
`;

export const TableContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  overflow: auto;
  height: 80vh;
`;
export const Table = styled.table`
  width: 100%;
  color: #ffffff;
  border-collapse: separate; /* Ensures cells have independent borders */
  border-spacing: 10px;
  ${UIConstants.customView} {
    border-spacing: 30px;
  }
`;

export const TableRow = styled.tr`
  width: 100%;
`;
export const TableHeader = styled.th`
  text-align: center;
`;
export const NameHeader = styled.th`
  width: 20%;
  text-align: center;
`;
export const DriverHeader = styled.th`
  width: 35%;
  text-align: center;
`;
export const TableData = styled.td`
  text-align: center;
`;
export const TableAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;
export const Uppercase = styled.span`
  text-transform: uppercase;
`;
export const Action = styled.div`
  text-align: center;
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  ${UIConstants.customView} {
    margin-left: 20px;
  }
`;

export const NameData = styled.td`
  text-align: justify;
`;

export const NameWrapper = styled.div`
  padding-left: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

export const CircleDot = styled.div`
  width: 18px;
  height: 18px;
  border: 5px solid rgba(191, 173, 117, 255);
  border-radius: 50%;
  ${UIConstants.customView} {
    width: 13px;
    height: 13px;
  }
  &:hover {
    background-color: #ffffff;
  }
`;

export const Status = styled.div`
  text-transform: capitalize;
  width: 100%;
  padding: 10px;
  text-align: center;
  border: 1px solid rgba(191, 173, 117, 255);
  color: rgba(191, 173, 117, 255);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${UIConstants.customView} {
    /* margin-right:10px; */
  }
`;
export const Priority = styled.div`
  width: 150px;
  padding: 10px;
  text-align: center;
  border: 1px solid rgba(191, 173, 117, 255);
  color: rgba(191, 173, 117, 255);
  border-radius: 5px;
`;
export const TaskStatus = styled.div`
  text-transform: capitalize;
  width: 100px;
  padding: 10px;
  text-align: center;
  border: 1px solid rgba(191, 173, 117, 255);
  background-color: rgba(191, 173, 117, 255);
  font-weight: 500;
  color: #000000;
  border-radius: 24px;
`;
export const DriverDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const DriverName = styled.span`
  margin-left: 50px;
`;
export const ImgWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 2px;
  align-items: center;
`;
export const Img = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: contain;
`;
