import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap";

import "../datatables.scss";
import { useNavigate } from "react-router-dom";
import { routerPath } from "../../../routes/routes";
import { PiEyeBold, PiNotePencilBold } from "react-icons/pi";
import { FaRegTrashAlt } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/Store";
import ActionModal from "../../../components/Modal/ActionModal/ActionModal";
import { deletetripHandler } from "../../../features/tripSlice";

interface RowData {
  id: any;
  trip_id:string;
  service_id:string;
  trip_origin: string;
  trip_destination: string;
  start_date: string;
  end_date: string;

  start_time:string;
  end_time:string;
}

interface ColumnData {
  label: string;
  field: string;
  sort?: string;
  width?: number;
}

interface TableProp {
  columns: ColumnData[];
  rows: RowData[];
}

const TripTable: React.FC<TableProp> = ({ columns, rows }) => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [selectedId, setSelectedId] = useState("");



  const handleDeleteModal = (id: any) => {
    setOpenDeleteModel(true);
    setSelectedId(id);
    console.log(id);
  };
  const confirmDelete = () => {
    setOpenDeleteModel(false);
    dispatch(deletetripHandler(selectedId));
  };

  const newColumns = [
    ...columns,
    {
      label: "Actions",
      field: "actions",
      width: 200,
    },
  ];
  const data = {
    columns: newColumns,
    rows: rows
      ? rows.map((rowData, index) => ({
          ...rowData,
          
          actions: (
            <div className="d-flex flex-column gap-1 justify-content-center align-items-center">
              <Button
                type="button"
                color="primary"
                className="waves-effect waves-light"
                onClick={() => navigate(routerPath.tripDetail(rowData.id))}
              >
            <PiEyeBold style={{ fontSize: "18px" }}/>
                
              </Button>
              <Button
                type="button"
                color="info"
                className="waves-effect waves-light"
                onClick={() => navigate(routerPath.updateTrip(rowData.id))}
              >
            <PiNotePencilBold style={{ fontSize: "18px" }}/>
                
              </Button>
              <Button
                type="button"
                color="danger"
                className="waves-effect waves-light"
                onClick={() => handleDeleteModal(rowData.id)}
              >
            <FaRegTrashAlt style={{ fontSize: "18px" }}/>
                
              </Button>
            </div>
          ),
        }))
      : [],
  };


  return (
    <React.Fragment>
       <ActionModal
        openModal={openDeleteModel}
        setOpenModal={setOpenDeleteModel}
        confirm={confirmDelete}
        title={"Delete"}
        content={"Trip"}
      />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
            <CardTitle className="d-flex justify-content-end">
                <Button
                  type="button"
                  color="primary"
                  className="waves-effect waves-light"
                  onClick={()=>navigate(routerPath.addTrip)}
                >
                  Add Trip
                </Button>{" "}
              </CardTitle>
              <MDBDataTable responsive bordered noBottomColumns data={data} />
            </CardBody>
          </Card>
        </Col>
      </Row>

      
    </React.Fragment>
  );
};

export default TripTable;
