import { FC, useEffect } from "react";
import {
  Wrapper,
  ProfielWapper,
  DropdownContainer,
  DropdownOption,
  ProfileImage,
  MoonIcon,
  BellIcon,
  CountryImage,
  SunIcon,
  ActionBtn,
  NotificationWrapper,
  Count,
  Heading,
  Back,
  HeadingContent,
  UserName,
  ProfileIcon,
  PowerIcon,
} from "./StyledAppTopBar";
import ProfileImageSrc from "../../assets/profileavtar.jpg";
import flagImg from "../../assets/india.png";
import { AppDispatch, RootState } from "../../app/Store";
import { useDispatch, useSelector } from "react-redux";
import { toggleNightMode } from "../../features/nightModeSlice";
import { useNavigate } from "react-router-dom";
import { routerPath } from "../../routes/routes";
import { setLocalStorage } from "../../localStorage/SetLocalStorage";
import * as localStorageActionType from "../../localStorage/ActionTypes";
import { toast } from "react-toastify";
import { getLocalStorage } from "../../localStorage/GetLocalStorage";
import { getLoggedInUser } from "../../features/userSlice";
import { getBusData, getRouteData, getServiceData, getUserData } from "../../features/DashboardSlice";
import ProfileMenu from "./ProfileMenu";
const AppTopBar: FC = () => {
  const dispatch:AppDispatch = useDispatch();
  const navigate = useNavigate();
  const {loggedInUser} = useSelector((state:RootState)=>state.user);
  

  const isLoggedIn:any = getLocalStorage(localStorageActionType.GET_IS_LOGGED_IN);
  console.log(loggedInUser)
  const nightMode = useSelector(
    (state: RootState) => state.nightModeSlice.nightMode
  );
  const {heading,isBackBtn} = useSelector((state: RootState) => state.headingSlice);

  const nightModeHandler = () => {
    dispatch(toggleNightMode({ nightMode: !nightMode }));

  };
  const redirectToView = (path: string) => {
    navigate(path);
  };

 
  function toggleFullscreen(): void {
    if (
      !document.fullscreenElement &&
      !(document as any).mozFullScreenElement &&
      !(document as any).webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if ((document.documentElement as any).mozRequestFullScreen) {
        (document.documentElement as any).mozRequestFullScreen();
      } else if ((document.documentElement as any).webkitRequestFullscreen) {
        (document.documentElement as any).webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if ((document as any).mozCancelFullScreen) {
        (document as any).mozCancelFullScreen();
      } else if ((document as any).webkitExitFullscreen) {
        (document as any).webkitExitFullscreen();
      }
    }
  }
  
useEffect(()=>{
dispatch(getLoggedInUser());
dispatch(getBusData());
dispatch(getRouteData());
dispatch(getUserData());
dispatch(getServiceData());

},[]);
  return (
    <Wrapper nightMode={nightMode} className="shadow">
      <Heading>
              <HeadingContent>
                {isBackBtn && <Back onClick={() => navigate(-1)} />}
                {heading}
              </HeadingContent>
            </Heading>
      <ProfielWapper>
      <div className="d-flex">
        <div className="dropdown d-none d-lg-inline-block">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen()
                }}
                className="btn header-item noti-icon waves-effect"
                data-toggle="fullscreen"
              >
                <i className="mdi mdi-fullscreen font-size-24"></i>
              </button>
            </div>
            <ProfileMenu/>
      </div>
      </ProfielWapper>
    </Wrapper>
  );
};

export default AppTopBar;
